import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import connector from './PatientList.connect';
import {Button, IconButton, Box, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import PatientHeader from './PatientHeader';
import PatientRow from './PatientRow';
import {FixedSizeList} from 'react-window';
import {useResizeDetector} from 'react-resize-detector';
import HmoInputWithAdornment from 'components/input/HmoInputWithAdornment';
import {Search, CloseOutlined as CloseIcon, Settings} from "@material-ui/icons";
import {getSet, orderByFalsyLast, stringGet} from 'utils/Utils';
import Tag from 'components/tags/Tag';
import update from 'immutability-helper';
import * as colors from 'components/colors/Colors';
import _ from 'lodash';
import fp from 'lodash/fp';
import Sidebar from 'components/sidebar/Sidebar';
import {isManager, isCaseManager} from 'domain/User.model';
import {MANAGED_CARE_PROGRAM_TEMPLATES, PATIENT_PAGE} from 'routes/routes';
import {withRouter} from 'utils/Route.utils';
import NewPatientDialog from 'scenes/patient/NewPatientDialog';
import utils from 'utils/Utils';
import {CaseStatusEnum} from '../case/case-status.enum';
import {Skeleton} from '@material-ui/lab';
import {useIsContactPerson} from 'utils/useIsContactPerson';
import {
    useContactConfirmationOfPatientConfidentialityQuery,
} from "scenes/patient/PatientApi";

const Filters = (props) => {
    const {
        translate,
        toggleNewPatientDialog,
        programNamesFromPatients,
        selectedPrograms,
        toggleProgram,
        positiveSearch,
        resetPositiveSearch,
        stateChange,
        user
    } = props;
    return (
            <>
                <Flex item={'0 1 auto'} container justifyContent={'space-between'} alignItems={'center'} style={{
                    marginBottom: 10,
                    marginTop: 10,
                    marginLeft: 70,
                    marginRight: 10,
                }}>
                    <Flex item container alignItems={'center'}>
                        <HmoInputWithAdornment
                                value={positiveSearch}
                                placeholder={translate('global.search')}
                                onChange={stateChange}
                                name={'positiveSearch'}
                                rootStyle={{
                                    padding: 0,
                                    marginRight: 15,
                                    paddingLeft: 10,
                                    width: 420,
                                    border: '1px solid #859EC2',
                                    backgroundColor: 'unset'
                                }}
                                startAdornment={<Search/>}
                                endAdornment={
                                        !_.isEmpty(positiveSearch) &&
                                        <IconButton
                                                style={{
                                                    padding: 3,
                                                    backgroundColor: colors.silver,
                                                    fontSize: 13,
                                                    marginRight: 3
                                                }}
                                                onClick={resetPositiveSearch}>
                                            <CloseIcon style={{color: colors.concrete, fontSize: 'inherit'}}/>
                                        </IconButton>
                                }
                        />

                        {
                            programNamesFromPatients.map((item, index) => (
                                    <Tag {...{
                                        key: index,
                                        selected: selectedPrograms.includes(item),
                                        tag: item,
                                        toggleSelection: toggleProgram
                                    }}/>
                            ))
                        }
                        {
                                isManager(user) &&
                                <IconButton data-testid="manage-program-templates-btn" component={Link} to={MANAGED_CARE_PROGRAM_TEMPLATES.path}>
                                    <Settings/>
                                </IconButton>
                        }

                    </Flex>
                    {
                            isManager(user) &&
                            <Button data-testid="add-new-patient-btn" onClick={toggleNewPatientDialog} style={{
                                backgroundColor: '#245B67', textTransform: 'capitalize', padding: '0px 30px',
                                color: colors.htmlWhite, maxHeight: 37, height: 37, borderRadius: 10
                            }} size={'small'}>
                                + {translate('global.add-new-patient')}
                            </Button>
                    }
                </Flex>
            </>
    );
};

function PatientListWindow(props) {
    const {width, height, ref} = useResizeDetector();
    const {patients, selectPatient, selectedPrograms, toggleProgram, refresh} = props;
    const isContactPerson = useIsContactPerson();
    // It's important to pass undefined (not null) as the first argument to the query
    // otherwise cached results will not be shared with other calls
    useContactConfirmationOfPatientConfidentialityQuery(undefined, {
        skip: !isContactPerson
    }); // Don't need the result just want to trigger the query
    return <div ref={ref} style={{
        height: '80vh',
        marginLeft: 70,
        marginRight: 10,
    }}>
        <FixedSizeList
                height={height || 300}
                itemCount={patients.length}
                itemSize={40}
                width={width || 500}
                itemData={{rows: patients}}
        >
            {({index, style}) => (
                    <div key={index} style={style}>
                        <PatientRow  {...{
                            displayForContactPerson: isContactPerson,
                            patientOverview: patients[index],
                            selectPatient: selectPatient,
                            selectedPrograms,
                            toggleProgram,
                            refresh
                        }}/>
                    </div>
            )}
        </FixedSizeList>
    </div>;
}

export class PatientList extends Component {

    state = {
        sortParameter: '',
        reverseSort: false,
        positiveSearch: '',
        selectedPrograms: [],
        isNewPatientDialogOpen: false,
        isLoading: false
    }

    componentDidMount() {
        this.setState({isLoading: true}, () => {
            this.props.getPatientList().finally(() => this.setState({isLoading: false}));
        })
    }

    onSort = sortParameter => event => {
        this.setState({
            sortParameter,
            reverseSort: this.state.sortParameter === sortParameter ? !this.state.reverseSort : false
        });
    };

    toggleProgram = program => {
        const index = this.state.selectedPrograms.findIndex(item => item === program);
        this.setState({
            selectedPrograms: index === -1
                    ? update(this.state.selectedPrograms, {
                        $push: [program]
                    })
                    : update(this.state.selectedPrograms, {
                        $splice: [[index, 1]]
                    })
        })
    }

    freeTextFilter = patient => {
        // Split the positiveSearch string into an array of lowercase words by space and comma
        const searchWords = this.state.positiveSearch.toLowerCase().split(/[\s,]+/);

        const includesAnyWord = (fieldValue) => {
            const lowerCaseFieldValue = fieldValue.toLowerCase();
            return searchWords.some(word => lowerCaseFieldValue.includes(word));
        };

        return this.state.positiveSearch === ''
            || stringGet(patient, 'emailAddresses', '').includes(this.state.positiveSearch)
            || stringGet(patient, 'phoneNumbers', '').includes(this.state.positiveSearch)
            || includesAnyWord(stringGet(patient, 'givenName', ''))
            || includesAnyWord(stringGet(patient, 'familyName', ''));
    };

    resetPositiveSearch = () => {
        this.setState({
            positiveSearch: ''
        })
    };

    programsFilter = patient => {
        return _.isEmpty(this.state.selectedPrograms)
                ? true
                : this.state.selectedPrograms.every(programName => patient?.programs?.split(',').includes(programName))
    }

    stateChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    selectPatient = patientId => event => {
        this.props.navigate(PATIENT_PAGE.pathWithId(patientId));
    }

    toggleNewPatientDialog = () => {
        this.setState({
            isNewPatientDialogOpen: !this.state.isNewPatientDialogOpen
        })
    }

    addUnseenSum = item => {
        return {
            ...item,
            unseenSum: item.unseenDocuments + item.unseenChats + item.unseenContacts
        }
    }

    render() {
        const {t: translate, programNamesFromPatients, user, userDetails, patients} = this.props;
        const {
            onSort, toggleProgram, freeTextFilter, programsFilter, stateChange, selectPatient, toggleNewPatientDialog,
            resetPositiveSearch, addUnseenSum
        } = this;
        const {
            sortParameter, reverseSort, selectedPrograms, positiveSearch, isNewPatientDialogOpen, isLoading
        } = this.state;

        const filteredPatients = patients
                .filter(freeTextFilter)
                .filter(programsFilter)
                .map(addUnseenSum);

        const orderedPatients = sortParameter
                ? utils.orderByFalsyLast(filteredPatients, [sortParameter], [reverseSort ? 'desc' : 'asc'])
                : utils.orderByFalsyLast(filteredPatients,
                        ['unseenSum', 'date', 'description', 'familyName'],
                        ['desc', 'asc', 'asc', 'asc']
                );
        return <>
            <Sidebar/>
            <Box style={{marginLeft: 60}}>
                <Typography component='h1' variant='h2' style={{
                    color: 'gray',
                    fontWeight: 500,
                    borderBottom: '1px solid gray',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    paddingLeft: '10px'
                }}>
                    {
                        isCaseManager(user) ?
                                <>
                                    {translate('global.informal-salutation')}{user.givenName ? ',' : ''} <span
                                        style={{color: 'black'}}>{user.givenName}</span>
                                </>
                                :
                                <>
                                    {translate('global.formal-salutation')}, {`${translate(userDetails?.salutation ? translate('global.' + userDetails?.salutation) : '')} ${userDetails?.title || ''}`}
                                    <span data-testid="formal-salutation" style={{color: 'black'}}> {user.familyName}</span>
                                </>
                    }
                </Typography>
                <Typography component="h2"
                            style={{color: colors.sideBarColor, paddingLeft: '10px', marginTop: '10px'}}
                            variant="h3">{translate('global.here-are-your-patients')} (n={patients.length})</Typography>
            </Box>

            <Flex item container column>
                <Filters {...{
                    translate,
                    toggleNewPatientDialog,
                    programNamesFromPatients,
                    selectedPrograms,
                    toggleProgram,
                    positiveSearch,
                    resetPositiveSearch,
                    stateChange,
                    user
                }}/>
                <PatientHeader {...{
                    onSort, sortParameter, reverseSort
                }}/>
                {
                        _.isEmpty(patients) && !isLoading &&
                        <Flex item container center padding={20} style={{height: '50vh'}}>
                            <Typography style={{
                                fontSize: 21,
                                opacity: 0.7,
                                lineHeight: '14px',
                                textAlign: 'center',
                                whiteSpace: 'pre-line'
                            }}>
                                {translate(`global.no-patients-yet${isManager(user) ? '-doctor' : ''}`)}
                            </Typography>
                        </Flex>
                }
                {
                        isLoading && _.isEmpty(patients) &&
                        <div style={{marginLeft: '70px', marginRight: '10px', border: '1px solid rgb(210, 210, 210)'}}>
                            {Array.from({length: 20}).map((_, index) => (
                                    <div key={index} style={{borderBottom: '1px solid rgb(210, 210, 210)'}}>
                                        <Skeleton variant="rect" height={'40px'}/>
                                    </div>
                            ))}
                        </div>
                }
                {
                        !_.isEmpty(patients) &&
                        <PatientListWindow {...{
                            patients: orderedPatients,
                            selectedPrograms,
                            toggleProgram,
                            selectPatient,
                            refresh: this.props.getPatientList
                        }}/>

                }

                {
                        isNewPatientDialogOpen &&
                        <NewPatientDialog cancel={toggleNewPatientDialog}/>
                }
            </Flex>
        </>
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
        withTranslation()(
                withRouter(PatientList)
        )
);
