import React, {useCallback, useEffect, useRef, useReducer, useState, useMemo} from "react";
import "react-calendar-timeline/lib/Timeline.css";
import Timeline, {TimelineHeaders, SidebarHeader, DateHeader} from "react-calendar-timeline/lib";
import "./LaboratoryTimeline.css";
import classNames from "classnames";
import moment from "moment";
import {
    Button,
    Grid,
    MenuItem,
    Select,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions, Typography,
} from "@material-ui/core";
import {LaboratoryGroupList} from "../config";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useFetchLaboratoryByPatientQuery} from "../LaboratoryApi";
import _ from "lodash";
import {set} from "lodash/fp";
import LaboratoryFormDialog from "./LaboratoryFormDialog";
import LaboratoryContextMenu from "./LaboratoryContextMenu";
import {useUpdateLaboratoryValueMutation, useDeleteLaboratoryMutation} from "../LaboratoryApi";
import {shouldDeleteWholeLaboratoryEntry} from "../utils/shouldDeleteWholeLaboratoryEntry";

const initialState = {
    items: [],
    years: [],
};

const ActionTypes = Object.freeze({
    SetItems: "SET_ITEMS",
    SetYears: "SET_YEARS",
});

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.SetItems:
            return {...state, items: action.payload};
        case ActionTypes.SetYears:
            return {...state, years: action.payload};
        default:
            return state;
    }
};

const LaboratoryTimeline = ({readonly}) => {
    const [
        {
            items,
            years,
        },
        dispatch,
    ] = useReducer(reducer, initialState);
    const {patientId} = useParams();
    const {t} = useTranslation();
    const lang = useCallback((name, args) => t(`laboratory.timeline.${name}`, args), [t]);
    const timelineRef = useRef(null);
    const groups = useMemo(() => LaboratoryGroupList(lang), [lang]);
    const [year, setYear] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedLaboratoryData, setSelectedLaboratoryData] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [updateLaboratoryValueMutation] = useUpdateLaboratoryValueMutation();
    const [deleteLaboratoryMutation] = useDeleteLaboratoryMutation();
    const [timelineKey, setTimelineKey] = useState(0);

    // API
    const {data: laboratoryData, isSuccess: laboratoryDataLoadedSuccessfully} = useFetchLaboratoryByPatientQuery(
            {patientId},
            {skip: !patientId}
    );

    const handleOnChangeYear = (event) => {
        const year = event.target.value;
        setYear(year);
        timelineRef.current.showPeriod(new Date(Number(year), 0, 0), new Date(Number(year), 12, 31));
    };

    // HOOK
    useEffect(() => {
        if (laboratoryDataLoadedSuccessfully) {
            dispatch({
                type: ActionTypes.SetItems,
                //TODO LM: Yoooo this mapping is fragile as hell, I'm fixing it for the third time.
                // The backend should send the data in a format that is directly mappable to the timeline
                // all this fancy filtering should be gone too.
                payload: _.flatMap(laboratoryData, ({id, patientId, recordDate, ...rest}) =>
                        _.map(rest, (value, key) => ({
                            id: `${id}-${key}`,
                            group: key,
                            className: {[`${key}-item`]: true},
                            title: {
                                text: value,
                                unit: rest[`${key}Unit`],
                            },
                            start_time: moment(recordDate).startOf("day"),
                            end_time: moment(recordDate).startOf("day"),
                            originalEntity: {id, patientId, recordDate, ...rest},
                        }))
                )
                        .filter(item => item.title != null && item?.group.indexOf("Unit") === -1)
                        .filter(item => item.title.text),
            });
            const years = [...new Set(laboratoryData.map((item) => new Date(item.recordDate).getFullYear()))];
            if (years.length > 0) {
                dispatch({type: ActionTypes.SetYears, payload: years});
                handleOnChangeYear({target: {value: years[years.length - 1]}});
            }
            setTimelineKey(timelineKey + 1);
        }
    }, [laboratoryData, laboratoryDataLoadedSuccessfully]);

    const handleOnRightClick = (laboratoryId, event) => {
        if(readonly) return;
        if (event.type === 'contextmenu') {
            setSelectedLaboratoryData(items.find((item) => item.id === laboratoryId));
            setAnchorEl(event.currentTarget);
        }
    };

    const handleContextMenuClose = () => {
        setAnchorEl(null);
        setSelectedLaboratoryData(null);
    };

    const onLaboratoryValueDeleteHandler = () => {
        setDeleteModalOpen(true);
        setAnchorEl(null);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setSelectedLaboratoryData(null);
    }

    const closeEditModal = ()=>{
        setEditModalOpen(false);
        setSelectedLaboratoryData(null);
    }

    const onLaboratoryValueEditHandler = () => {
        setEditModalOpen(true);
        setAnchorEl(null);
    };

    const onDeleteItem = () => {
        const canDeleteWholeEntry = shouldDeleteWholeLaboratoryEntry(
                set(selectedLaboratoryData.group, 0, selectedLaboratoryData.originalEntity)
        );
        if (canDeleteWholeEntry) {
            deleteLaboratoryMutation(selectedLaboratoryData.originalEntity.id);
        } else {
            updateLaboratoryValueMutation({
                payload: set(selectedLaboratoryData.group, 0, selectedLaboratoryData.originalEntity),
                id: selectedLaboratoryData.originalEntity.id,
            });
        }
        closeDeleteModal();
    };

    /**
     * Renders Row headers to the left
     * @param {Object} props - The component props.
     * @param {Object} props.group - The group to render.
     * @param {string} props.group.className - The class name for the group.
     * @param {string} props.group.title - The title for the group.
     * @param {string} props.group.tip - The tip for the group.
     * @returns {JSX.Element} The rendered group.
     */
    const groupRenderer = ({group}) => {
        return (
                <div className={classNames({"group-row": true, ...group.className})}>
                    <span className="title">{group.title}</span>
                    <p className="tip">{group.tip}</p>
                </div>
        );
    };

    const itemRenderer = useCallback(({item, itemContext, getItemProps, getResizeProps}) => {
        const {left: leftResizeProps, right: rightResizeProps} = getResizeProps();
        const itemProps = getItemProps(item.props);
        return (
                <div {...itemProps} title="">
                    {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}

                    <div
                            className={classNames({"rct-item-content": true, ...item?.className})}
                            style={{maxHeight: `${itemContext.dimensions.height}`}}
                    >
                        {itemContext.title?.text} {itemContext.title.unit?.replace('percent', '%').replace("per", "/")}
                    </div>

                    {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
                </div>
        );
    }, []);

    const minItemDate = _.cloneDeep(_.sortBy(items, 'start_time')?.[0]?.start_time)?.subtract(6, 'months') || moment().subtract(1, 'years');
    const maxItemDate = _.cloneDeep(_.sortBy(items, 'end_time')?.[items?.length - 1]?.end_time)?.add(6, 'months') || moment().add(1, 'years');
    return <div>
        <Grid container style={{marginTop: "20px", marginBottom: "20px"}}>
            <Grid item xs={3}>
                {years.length > 0 &&
                        <Select displayEmpty fullWidth value={year || ""} onChange={handleOnChangeYear}>
                            {years
                                    .sort((a, b) => b - a)
                                    .map((yearLabel) => (
                                            <MenuItem key={yearLabel} value={yearLabel}>
                                                {yearLabel}
                                            </MenuItem>
                                    ))}
                        </Select>}
            </Grid>
            <Grid container direction={'column'} item xs={6} style={{paddingLeft: 30}}>
                <Typography style={{fontSize: 12, color: 'grey'}}>{t('laboratory.timeline.help1')}</Typography>
                <Typography style={{fontSize: 12, color: 'grey'}}>{t('laboratory.timeline.help2')}</Typography>
                <Typography style={{fontSize: 12, color: 'grey'}}>{t('laboratory.timeline.help3')}</Typography>
                <Typography style={{fontSize: 12, color: 'grey'}}>{t('laboratory.timeline.help4')}</Typography>
            </Grid>
            <Grid item xs={3} style={{textAlign: "right"}}>
                {!readonly && <LaboratoryFormDialog initialValues={selectedLaboratoryData ? selectedLaboratoryData.originalEntity : {}}
                                      forceOpen={editModalOpen} reset={closeEditModal}/>}
            </Grid>
        </Grid>

        <div className="timeline-component">
            <Timeline
                    key={timelineKey}
                    ref={timelineRef}
                    groups={groups}
                    items={items}
                    defaultTimeStart={minItemDate}
                    defaultTimeEnd={maxItemDate}
                    canMove={false}
                    maxZoom={3 * 365 * 24 * 60 * 60 * 1000} /*3 years*/
                    minZoom={7 * 24 * 60 * 60 * 1000} /*7 days*/
                    itemRenderer={itemRenderer.valueOf()}
                    groupRenderer={groupRenderer.valueOf()}
                    onItemContextMenu={handleOnRightClick}
                    lineHeight={54}
                    sidebarWidth={200}
                    traditionalZoom={true}
                    timeSteps={{
                        second: 0,
                        minute: 0,
                        hour: 0,
                        day: 1,
                        month: 1,
                        year: 1
                    }}
            >
                <TimelineHeaders className="sticky">
                    <SidebarHeader
                            style={{
                                background: "#F8F8FA",
                            }}
                    >
                        {({getRootProps}) => {
                            return (
                                    <div {...getRootProps()} className="header-layout">
                                        <div>{t('laboratory.timeline.lab-values')}</div>
                                    </div>
                            );
                        }}
                    </SidebarHeader>
                    <DateHeader unit="primaryHeader"/>
                    <DateHeader/>

                </TimelineHeaders>
            </Timeline>
            <LaboratoryContextMenu
                    anchorEl={anchorEl}
                    onClose={handleContextMenuClose}
                    onDelete={onLaboratoryValueDeleteHandler}
                    onEdit={onLaboratoryValueEditHandler}
            />
            <Dialog
                    open={deleteModalOpen}
                    onClose={closeDeleteModal}
                    PaperProps={{style: {marginTop: "-30vh"}}}
            >
                <DialogTitle>{t("global.generalConfirm")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {lang("deletionWarning", {
                            valueName: lang(selectedLaboratoryData && selectedLaboratoryData.group),
                            date: selectedLaboratoryData && selectedLaboratoryData.start_time.format("DD MMMM YYYY"),
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                            variant="contained"
                            color="default"
                            onClick={closeDeleteModal}
                    >
                        {t("global.cancel")}
                    </Button>
                    <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onDeleteItem();
                            }}
                    >
                        {t("global.confirm-delete")}
                    </Button>
                </DialogActions>
            </Dialog>
            <div
                    className={"timeline-footer"}
                    style={{bottom: 0, left: 0, right: 0, backgroundColor: "#f5f5f5", padding: "0px"}}
            />
        </div>
    </div>;
};

export default LaboratoryTimeline;
