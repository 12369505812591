import { memo } from 'react';
import { Box, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import {isEqual} from "lodash";
import { useTranslation } from "react-i18next";

export const AnamnesisFilters = memo(({ filters, setFilters }) => {
    const {t} = useTranslation();
    return (
        <Box display={"flex"} flexDirection={"row"} alignItems="center">
            <span style={{ marginRight: "8px" }}>{t('anamnesis.filters.title')}</span>
            <FormGroup row>
                {Object.entries(filters).map(([key, value], index) => {
                    return <FormControlLabel
                        control={
                            <Checkbox
                                checked={value}
                                color="primary"
                                size="small"
                                onChange={() => {
                                    setFilters({ ...filters, [key]: !value });
                                }}
                            />
                        }
                        label={t('anamnesis.filters.' + key)}
                    />})}
            </FormGroup>
        </Box>
    );
}, isEqual);
