/* eslint-disable */

//NOTE LM: This is a direct copy of the calculator.js from the source code of https://herzfit.dev.digital-medicine.org/
// Its used with their express permission to Roy.
//The changes are to get rid of export(s) keyword:
// * Remove the self called Calculator init setup shitfuckery
// * Remove initialResults init setup
// * the last line which is a return statement here instead of the default export there.
export function riskProfileCalculator(){
    var _a, _b;
    var SMART = [
        {
            "age": 50,
            "usys": 117.9285714,
            "uchol": 204.5714286,
            "uhdl": 45.28571429,
            "ugfr": 86.00142857,
            "ucrp": 0.082682598,
            "meanrisk": 13.6199691
        }, {
            "age": 51,
            "usys": 113.8333333,
            "uchol": 198,
            "uhdl": 43.33333333,
            "ugfr": 79.95666667,
            "ucrp": 0.799135195,
            "meanrisk": 16.90071901
        }, {
            "age": 52,
            "usys": 113.8333333,
            "uchol": 198,
            "uhdl": 43.33333333,
            "ugfr": 79.95666667,
            "ucrp": 0.799135195,
            "meanrisk": 16.90071901
        }, {
            "age": 53,
            "usys": 113.8333333,
            "uchol": 198,
            "uhdl": 43.33333333,
            "ugfr": 79.95666667,
            "ucrp": 0.799135195,
            "meanrisk": 16.90071901
        }, {
            "age": 54,
            "usys": 113.8333333,
            "uchol": 198,
            "uhdl": 43.33333333,
            "ugfr": 79.95666667,
            "ucrp": 0.799135195,
            "meanrisk": 16.90071901
        }, {
            "age": 55,
            "usys": 113.8333333,
            "uchol": 198,
            "uhdl": 43.33333333,
            "ugfr": 79.95666667,
            "ucrp": 0.799135195,
            "meanrisk": 16.90071901
        }, {
            "age": 56,
            "usys": 130.15,
            "uchol": 209.5,
            "uhdl": 50.2,
            "ugfr": 86.334,
            "ucrp": 0.633433999,
            "meanrisk": 14.04361514
        }, {
            "age": 57,
            "usys": 130.15,
            "uchol": 209.5,
            "uhdl": 50.2,
            "ugfr": 86.334,
            "ucrp": 0.633433999,
            "meanrisk": 14.04361514
        }, {
            "age": 58,
            "usys": 126.875,
            "uchol": 196,
            "uhdl": 49,
            "ugfr": 75.88375,
            "ucrp": 0.8980557,
            "meanrisk": 13.18994746
        }, {
            "age": 59,
            "usys": 126.875,
            "uchol": 196,
            "uhdl": 49,
            "ugfr": 75.88375,
            "ucrp": 0.8980557,
            "meanrisk": 13.18994746
        }, {
            "age": 60,
            "usys": 123.75,
            "uchol": 213.4,
            "uhdl": 65.1,
            "ugfr": 87.591,
            "ucrp": 0.895200319,
            "meanrisk": 16.09713316
        }, {
            "age": 61,
            "usys": 123.75,
            "uchol": 213.4,
            "uhdl": 65.1,
            "ugfr": 87.591,
            "ucrp": 0.895200319,
            "meanrisk": 16.09713316
        }, {
            "age": 62,
            "usys": 136.4375,
            "uchol": 203.25,
            "uhdl": 54.375,
            "ugfr": 77.01125,
            "ucrp": 0.389233463,
            "meanrisk": 19.19027078
        }, {
            "age": 63,
            "usys": 136.4375,
            "uchol": 203.25,
            "uhdl": 54.375,
            "ugfr": 77.01125,
            "ucrp": 0.389233463,
            "meanrisk": 19.19027078
        }, {
            "age": 64,
            "usys": 136.4375,
            "uchol": 203.25,
            "uhdl": 54.375,
            "ugfr": 77.01125,
            "ucrp": 0.389233463,
            "meanrisk": 19.19027078
        }, {
            "age": 65,
            "usys": 133.4,
            "uchol": 201.4,
            "uhdl": 60.1,
            "ugfr": 74.075,
            "ucrp": 0.477882945,
            "meanrisk": 18.21084361
        }, {
            "age": 66,
            "usys": 133.4,
            "uchol": 201.4,
            "uhdl": 60.1,
            "ugfr": 74.075,
            "ucrp": 0.477882945,
            "meanrisk": 18.21084361
        }, {
            "age": 67,
            "usys": 131.3,
            "uchol": 200,
            "uhdl": 45,
            "ugfr": 73.232,
            "ucrp": 0.879195918,
            "meanrisk": 21.1635441
        }, {
            "age": 68,
            "usys": 128.9090909,
            "uchol": 199.6363636,
            "uhdl": 48.72727273,
            "ugfr": 77.64636364,
            "ucrp": 0.392400017,
            "meanrisk": 21.1635441
        }, {
            "age": 69,
            "usys": 128.9090909,
            "uchol": 199.6363636,
            "uhdl": 48.72727273,
            "ugfr": 77.64636364,
            "ucrp": 0.392400017,
            "meanrisk": 21.1635441
        }, {
            "age": 70,
            "usys": 140.2272727,
            "uchol": 202.8181818,
            "uhdl": 48,
            "ugfr": 67.62545455,
            "ucrp": 0.693664483,
            "meanrisk": 24.09717096
        }, {
            "age": 71,
            "usys": 140.2272727,
            "uchol": 202.8181818,
            "uhdl": 48,
            "ugfr": 67.62545455,
            "ucrp": 0.693664483,
            "meanrisk": 24.09717096
        }, {
            "age": 72,
            "usys": 135.25,
            "uchol": 216,
            "uhdl": 58,
            "ugfr": 74.38666667,
            "ucrp": 0.585554911,
            "meanrisk": 24.09717096
        }, {
            "age": 73,
            "usys": 112,
            "uchol": 191.875,
            "uhdl": 54.5,
            "ugfr": 68.3525,
            "ucrp": 0.659975158,
            "meanrisk": 33.29348101
        }, {
            "age": 74,
            "usys": 120.3,
            "uchol": 183.8,
            "uhdl": 48.4,
            "ugfr": 79.338,
            "ucrp": 0.685648107,
            "meanrisk": 33.03743339
        }, {
            "age": 75,
            "usys": 133.5555556,
            "uchol": 204.6666667,
            "uhdl": 53.22222222,
            "ugfr": 68.23444444,
            "ucrp": 0.456299745,
            "meanrisk": 37.09423391
        }, {
            "age": 76,
            "usys": 136.375,
            "uchol": 216.25,
            "uhdl": 52,
            "ugfr": 68.3625,
            "ucrp": 0.700061079,
            "meanrisk": 37.09423391
        }, {
            "age": 77,
            "usys": 130.9166667,
            "uchol": 187,
            "uhdl": 47.66666667,
            "ugfr": 53.93583333,
            "ucrp": 0.90373305,
            "meanrisk": 43.94294186
        }, {
            "age": 78,
            "usys": 122.4375,
            "uchol": 179.25,
            "uhdl": 47.875,
            "ugfr": 64.67125,
            "ucrp": 1.282731819,
            "meanrisk": 43.94294186
        }, {
            "age": 79,
            "usys": 123.2777778,
            "uchol": 205.3333333,
            "uhdl": 52.44444444,
            "ugfr": 55.11,
            "ucrp": 0.520211122,
            "meanrisk": 46.61439941
        }, {
            "age": 80,
            "usys": 126.6538462,
            "uchol": 218.4615385,
            "uhdl": 55,
            "ugfr": 63.57153846,
            "ucrp": 0.139567263,
            "meanrisk": 48.58791701
        }
    ];
    var FRS10men = [
        {
            "age": 40,
            "n": 40,
            "mean": 4.477196875,
            "median": 3.701434294,
            "meanpce": 3.943294976,
            "medianpce": 2.626946815,
            "usys": 131.3125,
            "uchol": 225.505,
            "uhdl": 51.775
        }, {
            "age": 41,
            "n": 35,
            "mean": 4.667025961,
            "median": 4.122039374,
            "meanpce": 3.971265967,
            "medianpce": 2.905884594,
            "usys": 128.1428571,
            "uchol": 223.2342857,
            "uhdl": 47.94285714
        }, {
            "age": 42,
            "n": 34,
            "mean": 5.349426086,
            "median": 4.714393886,
            "meanpce": 4.836705359,
            "medianpce": 3.434926116,
            "usys": 129.6470588,
            "uchol": 219.8794118,
            "uhdl": 47.95
        }, {
            "age": 43,
            "n": 22,
            "mean": 6.58669036,
            "median": 5.584640758,
            "meanpce": 6.201494705,
            "medianpce": 4.244860673,
            "usys": 129.25,
            "uchol": 223.7,
            "uhdl": 48.35454545
        }, {
            "age": 44,
            "n": 25,
            "mean": 7.220329851,
            "median": 6.385897042,
            "meanpce": 6.577263277,
            "medianpce": 4.591026495,
            "usys": 133.7,
            "uchol": 235.148,
            "uhdl": 48.784
        }, {
            "age": 45,
            "n": 38,
            "mean": 6.912897759,
            "median": 5.774824911,
            "meanpce": 6.343492083,
            "medianpce": 4.751278059,
            "usys": 132,
            "uchol": 239.4973684,
            "uhdl": 51.24210526
        }, {
            "age": 46,
            "n": 28,
            "mean": 6.998900691,
            "median": 5.214492164,
            "meanpce": 6.051143715,
            "medianpce": 3.899947199,
            "usys": 132.75,
            "uchol": 226.475,
            "uhdl": 49.84285714
        }, {
            "age": 47,
            "n": 40,
            "mean": 7.299121808,
            "median": 5.998789672,
            "meanpce": 6.389226108,
            "medianpce": 4.694041641,
            "usys": 134.1,
            "uchol": 230.935,
            "uhdl": 49.415
        }, {
            "age": 48,
            "n": 37,
            "mean": 8.191704333,
            "median": 7.344123703,
            "meanpce": 7.049130902,
            "medianpce": 6.076949066,
            "usys": 132.472973,
            "uchol": 224.7351351,
            "uhdl": 47.95405405
        }, {
            "age": 49,
            "n": 38,
            "mean": 9.49035498,
            "median": 8.512727042,
            "meanpce": 8.736918577,
            "medianpce": 7.348903137,
            "usys": 132.1842105,
            "uchol": 237.6710526,
            "uhdl": 49.77368421
        }, {
            "age": 50,
            "n": 36,
            "mean": 10.67885102,
            "median": 7.807920417,
            "meanpce": 10.02805034,
            "medianpce": 7.218861336,
            "usys": 133.2222222,
            "uchol": 251.0888889,
            "uhdl": 49.36666667
        }, {
            "age": 51,
            "n": 39,
            "mean": 10.09253381,
            "median": 8.859974591,
            "meanpce": 9.448564916,
            "medianpce": 7.995545986,
            "usys": 133.8461538,
            "uchol": 231.1512821,
            "uhdl": 51.89230769
        }, {
            "age": 52,
            "n": 31,
            "mean": 11.34245539,
            "median": 8.576366577,
            "meanpce": 10.71008746,
            "medianpce": 7.357191724,
            "usys": 135.1612903,
            "uchol": 244.7612903,
            "uhdl": 56.47741935
        }, {
            "age": 53,
            "n": 45,
            "mean": 12.17164609,
            "median": 10.42979746,
            "meanpce": 11.66018807,
            "medianpce": 10.01397534,
            "usys": 138.4777778,
            "uchol": 240.8333333,
            "uhdl": 51.36666667
        }, {
            "age": 54,
            "n": 40,
            "mean": 12.44210337,
            "median": 11.43221228,
            "meanpce": 12.02798335,
            "medianpce": 10.90191963,
            "usys": 134.3125,
            "uchol": 238.855,
            "uhdl": 51.7175
        }, {
            "age": 55,
            "n": 38,
            "mean": 13.37983771,
            "median": 12.18920515,
            "meanpce": 12.67808443,
            "medianpce": 11.77030825,
            "usys": 136.75,
            "uchol": 240.1052632,
            "uhdl": 50.35789474
        }, {
            "age": 56,
            "n": 30,
            "mean": 13.10117734,
            "median": 10.87160603,
            "meanpce": 12.94113882,
            "medianpce": 11.01553234,
            "usys": 133.3166667,
            "uchol": 239.1033333,
            "uhdl": 53.48333333
        }, {
            "age": 57,
            "n": 42,
            "mean": 16.20061117,
            "median": 15.53040199,
            "meanpce": 15.97034185,
            "medianpce": 14.95854325,
            "usys": 135.9761905,
            "uchol": 252.5857143,
            "uhdl": 50.82857143
        }, {
            "age": 58,
            "n": 40,
            "mean": 15.08958117,
            "median": 13.00055702,
            "meanpce": 15.54050305,
            "medianpce": 13.66617611,
            "usys": 135.2625,
            "uchol": 250.9225,
            "uhdl": 52.1925
        }, {
            "age": 59,
            "n": 35,
            "mean": 15.9076488,
            "median": 13.90601179,
            "meanpce": 16.09710775,
            "medianpce": 16.15586973,
            "usys": 133.9142857,
            "uchol": 241.9942857,
            "uhdl": 51.98285714
        }, {
            "age": 60,
            "n": 37,
            "mean": 16.89930501,
            "median": 13.04700663,
            "meanpce": 17.28705845,
            "medianpce": 14.20527293,
            "usys": 137.1486486,
            "uchol": 229.0891892,
            "uhdl": 52.50540541
        }, {
            "age": 61,
            "n": 37,
            "mean": 15.71882446,
            "median": 15.46211054,
            "meanpce": 16.51022208,
            "medianpce": 16.39315178,
            "usys": 135.4054054,
            "uchol": 232.8054054,
            "uhdl": 53.58378378
        }, {
            "age": 62,
            "n": 26,
            "mean": 20.61317422,
            "median": 14.88131892,
            "meanpce": 22.17378278,
            "medianpce": 18.17566313,
            "usys": 143.3076923,
            "uchol": 234.8076923,
            "uhdl": 53.13076923
        }, {
            "age": 63,
            "n": 31,
            "mean": 18.30866481,
            "median": 17.5356023,
            "meanpce": 20.37729572,
            "medianpce": 19.10326179,
            "usys": 146.2419355,
            "uchol": 243.4741935,
            "uhdl": 57.17419355
        }, {
            "age": 64,
            "n": 33,
            "mean": 20.53175822,
            "median": 15.74560613,
            "meanpce": 23.7878878,
            "medianpce": 19.13228866,
            "usys": 140.7878788,
            "uchol": 234.2242424,
            "uhdl": 53.91818182
        }, {
            "age": 65,
            "n": 31,
            "mean": 23.98084787,
            "median": 22.6255081,
            "meanpce": 26.4236007,
            "medianpce": 23.58920869,
            "usys": 141.6612903,
            "uchol": 238.5645161,
            "uhdl": 50.37096774
        }, {
            "age": 66,
            "n": 31,
            "mean": 19.3548254,
            "median": 20.10196453,
            "meanpce": 22.79692164,
            "medianpce": 24.49251432,
            "usys": 138.016129,
            "uchol": 233.8129032,
            "uhdl": 49.06129032
        }, {
            "age": 67,
            "n": 28,
            "mean": 21.97759925,
            "median": 20.16120744,
            "meanpce": 26.81874928,
            "medianpce": 24.09728503,
            "usys": 144.6428571,
            "uchol": 233.8357143,
            "uhdl": 55.18928571
        }, {
            "age": 68,
            "n": 27,
            "mean": 25.78556186,
            "median": 23.93694707,
            "meanpce": 30.76389876,
            "medianpce": 28.41727699,
            "usys": 143.7777778,
            "uchol": 242.1037037,
            "uhdl": 53.27777778
        }, {
            "age": 69,
            "n": 30,
            "mean": 27.70950068,
            "median": 24.87102538,
            "meanpce": 32.80687081,
            "medianpce": 31.42124825,
            "usys": 144.1,
            "uchol": 235.7466667,
            "uhdl": 49.9
        }, {
            "age": 70,
            "n": 28,
            "mean": 27.16637414,
            "median": 23.03105494,
            "meanpce": 34.90106149,
            "medianpce": 31.32754639,
            "usys": 146.3214286,
            "uchol": 228.0964286,
            "uhdl": 49.34285714
        }, {
            "age": 71,
            "n": 28,
            "mean": 31.00789021,
            "median": 27.24842538,
            "meanpce": 37.53129188,
            "medianpce": 34.89670717,
            "usys": 147.9464286,
            "uchol": 229.4928571,
            "uhdl": 51.25357143
        }, {
            "age": 72,
            "n": 25,
            "mean": 29.52328626,
            "median": 24.90768917,
            "meanpce": 37.29579636,
            "medianpce": 32.89290866,
            "usys": 149.86,
            "uchol": 249.46,
            "uhdl": 55.428
        }, {
            "age": 73,
            "n": 19,
            "mean": 22.03345901,
            "median": 23.44897221,
            "meanpce": 32.43725378,
            "medianpce": 33.32427615,
            "usys": 135.5789474,
            "uchol": 230.0842105,
            "uhdl": 59.93684211
        }, {
            "age": 74,
            "n": 15,
            "mean": 32.1436006,
            "median": 30.39652112,
            "meanpce": 41.00270063,
            "medianpce": 38.19954887,
            "usys": 147.6333333,
            "uchol": 241.2066667,
            "uhdl": 54.05333333
        }];
    var FRS10woman = [
        {
            "age": 40,
            "n": 39,
            "mean": 2.006148306,
            "median": 2.001356987,
            "meanpce": 1.466992313,
            "medianpce": 0.958859636,
            "usys": 115.3589744,
            "uchol": 209.1230769,
            "uhdl": 71.51282051
        }, {
            "age": 41,
            "n": 33,
            "mean": 2.100420518,
            "median": 1.781176881,
            "meanpce": 1.658367782,
            "medianpce": 1.00080807,
            "usys": 111.7424242,
            "uchol": 214.7212121,
            "uhdl": 67.08181818
        }, {
            "age": 42,
            "n": 44,
            "mean": 2.889540457,
            "median": 2.223081723,
            "meanpce": 2.694794016,
            "medianpce": 1.28151543,
            "usys": 116.1022727,
            "uchol": 208.9409091,
            "uhdl": 62.13409091
        }, {
            "age": 43,
            "n": 34,
            "mean": 2.863390463,
            "median": 2.406000321,
            "meanpce": 2.533740876,
            "medianpce": 1.647314507,
            "usys": 114.0588235,
            "uchol": 222.7470588,
            "uhdl": 63.18235294
        }, {
            "age": 44,
            "n": 43,
            "mean": 3.329892,
            "median": 3.079900816,
            "meanpce": 2.990544833,
            "medianpce": 1.926676615,
            "usys": 120.3255814,
            "uchol": 214.8023256,
            "uhdl": 64.42093023
        }, {
            "age": 45,
            "n": 51,
            "mean": 3.146565839,
            "median": 2.574738237,
            "meanpce": 2.78362436,
            "medianpce": 1.720498654,
            "usys": 116,
            "uchol": 213.3647059,
            "uhdl": 64.51176471
        }, {
            "age": 46,
            "n": 39,
            "mean": 3.546473521,
            "median": 2.827603895,
            "meanpce": 2.448825138,
            "medianpce": 1.512732881,
            "usys": 125.3589744,
            "uchol": 208.0692308,
            "uhdl": 69.35128205
        }, {
            "age": 47,
            "n": 32,
            "mean": 4.364141869,
            "median": 3.206240101,
            "meanpce": 4.186034757,
            "medianpce": 2.026905032,
            "usys": 120.671875,
            "uchol": 220.8625,
            "uhdl": 62.971875
        }, {
            "age": 48,
            "n": 39,
            "mean": 6.082930453,
            "median": 4.990941646,
            "meanpce": 5.037632444,
            "medianpce": 3.870342645,
            "usys": 128.7948718,
            "uchol": 231.9923077,
            "uhdl": 65.09487179
        }, {
            "age": 49,
            "n": 47,
            "mean": 4.559002346,
            "median": 3.921012928,
            "meanpce": 3.358480998,
            "medianpce": 2.645672937,
            "usys": 125.9468085,
            "uchol": 226.6297872,
            "uhdl": 64.79361702
        }, {
            "age": 50,
            "n": 45,
            "mean": 5.418462116,
            "median": 4.019287133,
            "meanpce": 4.716245295,
            "medianpce": 2.814604849,
            "usys": 127.3333333,
            "uchol": 225.1533333,
            "uhdl": 66.08666667
        }, {
            "age": 51,
            "n": 41,
            "mean": 6.812534993,
            "median": 5.070314943,
            "meanpce": 5.831310588,
            "medianpce": 3.287191807,
            "usys": 128.9878049,
            "uchol": 240.8097561,
            "uhdl": 66.02682927
        }, {
            "age": 52,
            "n": 37,
            "mean": 5.950704866,
            "median": 5.713044528,
            "meanpce": 4.566192884,
            "medianpce": 3.853871882,
            "usys": 128.8378378,
            "uchol": 234.1594595,
            "uhdl": 67.04324324
        }, {
            "age": 53,
            "n": 51,
            "mean": 7.186537803,
            "median": 6.396097193,
            "meanpce": 6.380778345,
            "medianpce": 5.106189878,
            "usys": 129.9411765,
            "uchol": 237.0607843,
            "uhdl": 60.88627451
        }, {
            "age": 54,
            "n": 20,
            "mean": 8.003880074,
            "median": 6.821335242,
            "meanpce": 6.965359715,
            "medianpce": 5.173825785,
            "usys": 130.175,
            "uchol": 255.205,
            "uhdl": 67.465
        }, {
            "age": 55,
            "n": 25,
            "mean": 7.707492413,
            "median": 7.104309255,
            "meanpce": 6.964819567,
            "medianpce": 6.529324401,
            "usys": 128.44,
            "uchol": 245.46,
            "uhdl": 63.036
        }, {
            "age": 56,
            "n": 30,
            "mean": 6.491605296,
            "median": 5.67512384,
            "meanpce": 6.177935217,
            "medianpce": 5.163197863,
            "usys": 124.1833333,
            "uchol": 233.1233333,
            "uhdl": 64.56333333
        }, {
            "age": 57,
            "n": 34,
            "mean": 9.777140839,
            "median": 7.809929814,
            "meanpce": 9.548476311,
            "medianpce": 7.39061621,
            "usys": 129.8676471,
            "uchol": 247.7,
            "uhdl": 66.14705882
        }, {
            "age": 58,
            "n": 40,
            "mean": 7.957795922,
            "median": 6.942653428,
            "meanpce": 8.347355253,
            "medianpce": 6.524977216,
            "usys": 128.0625,
            "uchol": 246.3375,
            "uhdl": 70.105
        }, {
            "age": 59,
            "n": 41,
            "mean": 8.082693345,
            "median": 6.650061001,
            "meanpce": 8.817705482,
            "medianpce": 7.205476814,
            "usys": 125.8414634,
            "uchol": 246.2707317,
            "uhdl": 69.99756098
        }, {
            "age": 60,
            "n": 38,
            "mean": 8.132440071,
            "median": 6.811140582,
            "meanpce": 8.981206699,
            "medianpce": 7.688507185,
            "usys": 124.5657895,
            "uchol": 244.9605263,
            "uhdl": 64.11052632
        }, {
            "age": 61,
            "n": 25,
            "mean": 13.34725534,
            "median": 10.34572381,
            "meanpce": 13.98209673,
            "medianpce": 11.77961134,
            "usys": 134.36,
            "uchol": 258.344,
            "uhdl": 59.888
        }, {
            "age": 62,
            "n": 28,
            "mean": 9.821778894,
            "median": 8.711299579,
            "meanpce": 11.90571656,
            "medianpce": 10.48472653,
            "usys": 124.8392857,
            "uchol": 258.6964286,
            "uhdl": 60.06071429
        }, {
            "age": 63,
            "n": 41,
            "mean": 12.23082502,
            "median": 9.267787512,
            "meanpce": 15.04472478,
            "medianpce": 11.68060771,
            "usys": 130.5487805,
            "uchol": 244.7804878,
            "uhdl": 62.44390244
        }, {
            "age": 64,
            "n": 41,
            "mean": 10.56503312,
            "median": 9.611576891,
            "meanpce": 14.20037903,
            "medianpce": 13.39470329,
            "usys": 126.0609756,
            "uchol": 242.9073171,
            "uhdl": 61.85121951
        }, {
            "age": 65,
            "n": 44,
            "mean": 14.12576582,
            "median": 10.31556883,
            "meanpce": 18.39261201,
            "medianpce": 14.67263476,
            "usys": 132.4659091,
            "uchol": 249.7363636,
            "uhdl": 63.89090909
        }, {
            "age": 66,
            "n": 32,
            "mean": 13.02337254,
            "median": 11.99800043,
            "meanpce": 19.07875387,
            "medianpce": 18.45375681,
            "usys": 136.078125,
            "uchol": 237.315625,
            "uhdl": 62.528125
        }, {
            "age": 67,
            "n": 32,
            "mean": 15.60443292,
            "median": 14.45125641,
            "meanpce": 23.14501837,
            "medianpce": 21.28777192,
            "usys": 138.40625,
            "uchol": 250.909375,
            "uhdl": 59.859375
        }, {
            "age": 68,
            "n": 25,
            "mean": 17.72140678,
            "median": 11.12989438,
            "meanpce": 25.97143728,
            "medianpce": 18.90821941,
            "usys": 134.42,
            "uchol": 246.132,
            "uhdl": 62.788
        }, {
            "age": 69,
            "n": 31,
            "mean": 17.63668258,
            "median": 13.66799852,
            "meanpce": 28.16519691,
            "medianpce": 24.30613769,
            "usys": 142.7096774,
            "uchol": 255.3290323,
            "uhdl": 69.51612903
        }, {
            "age": 70,
            "n": 27,
            "mean": 17.1427862,
            "median": 12.99081716,
            "meanpce": 29.41843141,
            "medianpce": 25.45953889,
            "usys": 131.0925926,
            "uchol": 258.0259259,
            "uhdl": 62.27037037
        }, {
            "age": 71,
            "n": 29,
            "mean": 16.17148809,
            "median": 14.45210651,
            "meanpce": 30.41424194,
            "medianpce": 27.68899015,
            "usys": 133.2068966,
            "uchol": 244.5068966,
            "uhdl": 59.43448276
        }, {
            "age": 72,
            "n": 15,
            "mean": 19.72841041,
            "median": 16.04505405,
            "meanpce": 36.18924564,
            "medianpce": 33.16998883,
            "usys": 143.9333333,
            "uchol": 251.0066667,
            "uhdl": 60.21333333
        }, {
            "age": 73,
            "n": 19,
            "mean": 19.65697654,
            "median": 17.80278945,
            "meanpce": 38.90958525,
            "medianpce": 35.16367202,
            "usys": 133.1052632,
            "uchol": 263.8789474,
            "uhdl": 62.17894737
        }, {
            "age": 74,
            "n": 12,
            "mean": 18.63998823,
            "median": 14.4761311,
            "meanpce": 39.65935187,
            "medianpce": 36.40031957,
            "usys": 140.1666667,
            "uchol": 247.2333333,
            "uhdl": 62.34166667
        }];
    var scoreOPmen = [
        {"age": 65, "urisk": 6.61, "usys": 122.74, "uchol": 229.74, "uhdl": 67.52}, {
            "age": 66,
            "urisk": 8.78,
            "usys": 127.04,
            "uchol": 216.83,
            "uhdl": 57.18
        }, {"age": 67, "urisk": 10.81, "usys": 127.78, "uchol": 207.84, "uhdl": 52.81}, {
            "age": 68,
            "urisk": 9.8,
            "usys": 129.52,
            "uchol": 215.3,
            "uhdl": 57.61
        }, {"age": 69, "urisk": 12.54, "usys": 132.18, "uchol": 212.82, "uhdl": 60.23}, {
            "age": 70,
            "urisk": 12.79,
            "usys": 127.44,
            "uchol": 221.33,
            "uhdl": 62.91
        }, {"age": 71, "urisk": 13.75, "usys": 133.44, "uchol": 208.75, "uhdl": 60.3}, {
            "age": 72,
            "urisk": 13.35,
            "usys": 126.47,
            "uchol": 214.56,
            "uhdl": 60.81
        }, {"age": 73, "urisk": 15.49, "usys": 128.38, "uchol": 234.75, "uhdl": 58.18}, {
            "age": 74,
            "urisk": 14.44,
            "usys": 120.08,
            "uchol": 196.63,
            "uhdl": 58.2
        }, {"age": 75, "urisk": 16.4, "usys": 134.44, "uchol": 205.94, "uhdl": 67.18}, {
            "age": 76,
            "urisk": 16.15,
            "usys": 123.94,
            "uchol": 205.94,
            "uhdl": 63.48
        }, {"age": 77, "urisk": 19.4, "usys": 134.84, "uchol": 204, "uhdl": 65.58}, {
            "age": 78,
            "urisk": 18.99,
            "usys": 124.18,
            "uchol": 200.55,
            "uhdl": 59.26
        }, {"age": 79, "urisk": 22.97, "usys": 131.14, "uchol": 199.5, "uhdl": 60.81}, {
            "age": 80,
            "urisk": 21.67,
            "usys": 125.54,
            "uchol": 186.38,
            "uhdl": 59.1
        }, {"age": 81, "uchol": 194, "uhdl": 54, "usys": 120, "urisk": 25.31}, {
            "age": 82,
            "uchol": 199,
            "uhdl": 58,
            "usys": 121,
            "urisk": 23.68
        }, {"age": 83, "uchol": 194, "uhdl": 61, "usys": 124, "urisk": 26.65}, {
            "age": 84,
            "uchol": 184,
            "uhdl": 57,
            "usys": 115,
            "urisk": 26.12
        }, {"age": 85, "uchol": 203, "uhdl": 65, "usys": 124, "urisk": 30.21}, {
            "age": 86,
            "uchol": 206,
            "uhdl": 59,
            "usys": 122,
            "urisk": 33.12
        }, {"age": 87, "uchol": 218, "uhdl": 60, "usys": 116, "urisk": 37.65}, {
            "age": 88,
            "uchol": 218,
            "uhdl": 60,
            "usys": 116,
            "urisk": 37.65
        }, {"age": 89, "uchol": 218, "uhdl": 60, "usys": 116, "urisk": 37.65}, {
            "age": 90,
            "uchol": 218,
            "uhdl": 60,
            "usys": 116,
            "urisk": 37.65
        }, {"age": 91, "uchol": 218, "uhdl": 60, "usys": 116, "urisk": 37.65}, {
            "age": 92,
            "uchol": 218,
            "uhdl": 60,
            "usys": 116,
            "urisk": 37.65
        }, {"age": 93, "uchol": 218, "uhdl": 60, "usys": 116, "urisk": 37.65}, {
            "age": 94,
            "uchol": 218,
            "uhdl": 60,
            "usys": 116,
            "urisk": 37.65
        }, {"age": 95, "uchol": 218, "uhdl": 60, "usys": 116, "urisk": 37.65}, {
            "age": 96,
            "uchol": 218,
            "uhdl": 60,
            "usys": 116,
            "urisk": 37.65
        }, {"age": 97, "uchol": 218, "uhdl": 60, "usys": 116, "urisk": 37.65}, {
            "age": 98,
            "uchol": 218,
            "uhdl": 60,
            "usys": 116,
            "urisk": 37.65
        }, {"age": 99, "uchol": 218, "uhdl": 60, "usys": 116, "urisk": 37.65}, {
            "age": 100,
            "uchol": 218,
            "uhdl": 60,
            "usys": 116,
            "urisk": 37.65
        }, {"age": 101, "uchol": 218, "uhdl": 60, "usys": 116, "urisk": 37.65}, {
            "age": 102,
            "uchol": 218,
            "uhdl": 60,
            "usys": 116,
            "urisk": 37.65
        }, {"age": 103, "uchol": 218, "uhdl": 60, "usys": 116, "urisk": 37.65}, {
            "age": 104,
            "uchol": 218,
            "uhdl": 60,
            "usys": 116,
            "urisk": 37.65
        }, {"age": 105, "uchol": 218, "uhdl": 60, "usys": 116, "urisk": 37.65}];
    var scoreOPwomen = [
        {"age": 65, "urisk": 5.71, "usys": 117.76, "uchol": 235, "uhdl": 69.89}, {
            "age": 66,
            "urisk": 4.51,
            "usys": 120.36,
            "uchol": 233.91,
            "uhdl": 73.04
        }, {"age": 67, "urisk": 5.3, "usys": 121.73, "uchol": 237.5, "uhdl": 69.31}, {
            "age": 68,
            "urisk": 6.19,
            "usys": 116.78,
            "uchol": 242.22,
            "uhdl": 78.08
        }, {"age": 69, "urisk": 6.01, "usys": 116.26, "uchol": 237.13, "uhdl": 73.94}, {
            "age": 70,
            "urisk": 5.19,
            "usys": 114,
            "uchol": 231,
            "uhdl": 74.14
        }, {"age": 71, "urisk": 8.77, "usys": 113.92, "uchol": 239.39, "uhdl": 70.8}, {
            "age": 72,
            "urisk": 8.02,
            "usys": 119.41,
            "uchol": 228.82,
            "uhdl": 76.76
        }, {"age": 73, "urisk": 8.75, "usys": 117.31, "uchol": 225.92, "uhdl": 77.64}, {
            "age": 74,
            "urisk": 10.08,
            "usys": 118.23,
            "uchol": 237.54,
            "uhdl": 74.7
        }, {"age": 75, "urisk": 10.33, "usys": 113.1, "uchol": 235.87, "uhdl": 75.25}, {
            "age": 76,
            "urisk": 15.4,
            "usys": 130.53,
            "uchol": 238.06,
            "uhdl": 71.04
        }, {"age": 77, "urisk": 15.01, "usys": 118.92, "uchol": 227.21, "uhdl": 71.91}, {
            "age": 78,
            "urisk": 17.84,
            "usys": 125.55,
            "uchol": 220.1,
            "uhdl": 67.16
        }, {"age": 79, "urisk": 15.56, "usys": 117.48, "uchol": 238.64, "uhdl": 77.55}, {
            "age": 80,
            "urisk": 20.53,
            "usys": 132.19,
            "uchol": 223.38,
            "uhdl": 77.08
        }, {"age": 81, "uchol": 222, "uhdl": 67, "usys": 132, "urisk": 23.22}, {
            "age": 82,
            "uchol": 200,
            "uhdl": 74,
            "usys": 139,
            "urisk": 18.69
        }, {"age": 83, "uchol": 225, "uhdl": 74, "usys": 122, "urisk": 25.94}, {
            "age": 84,
            "uchol": 247,
            "uhdl": 73,
            "usys": 130,
            "urisk": 31.71
        }, {"age": 85, "uchol": 217, "uhdl": 74, "usys": 121, "urisk": 29.51}, {
            "age": 86,
            "uchol": 217,
            "uhdl": 74,
            "usys": 121,
            "urisk": 29.51
        }, {"age": 87, "uchol": 217, "uhdl": 74, "usys": 121, "urisk": 29.51}, {
            "age": 88,
            "uchol": 217,
            "uhdl": 74,
            "usys": 121,
            "urisk": 29.51
        }, {"age": 89, "uchol": 217, "uhdl": 74, "usys": 121, "urisk": 29.51}, {
            "age": 90,
            "uchol": 217,
            "uhdl": 74,
            "usys": 121,
            "urisk": 29.51
        }, {"age": 91, "uchol": 217, "uhdl": 74, "usys": 121, "urisk": 29.51}, {
            "age": 92,
            "uchol": 217,
            "uhdl": 74,
            "usys": 121,
            "urisk": 29.51
        }, {"age": 93, "uchol": 217, "uhdl": 74, "usys": 121, "urisk": 29.51}, {
            "age": 94,
            "uchol": 217,
            "uhdl": 74,
            "usys": 121,
            "urisk": 29.51
        }, {"age": 95, "uchol": 217, "uhdl": 74, "usys": 121, "urisk": 29.51}, {
            "age": 96,
            "uchol": 217,
            "uhdl": 74,
            "usys": 121,
            "urisk": 29.51
        }, {"age": 97, "uchol": 217, "uhdl": 74, "usys": 121, "urisk": 29.51}, {
            "age": 98,
            "uchol": 217,
            "uhdl": 74,
            "usys": 121,
            "urisk": 29.51
        }, {"age": 99, "uchol": 217, "uhdl": 74, "usys": 121, "urisk": 29.51}, {
            "age": 100,
            "uchol": 217,
            "uhdl": 74,
            "usys": 121,
            "urisk": 29.51
        }, {"age": 101, "uchol": 217, "uhdl": 74, "usys": 121, "urisk": 29.51}, {
            "age": 102,
            "uchol": 217,
            "uhdl": 74,
            "usys": 121,
            "urisk": 29.51
        }, {"age": 103, "uchol": 217, "uhdl": 74, "usys": 121, "urisk": 29.51}, {
            "age": 104,
            "uchol": 217,
            "uhdl": 74,
            "usys": 121,
            "urisk": 29.51
        }, {"age": 105, "uchol": 217, "uhdl": 74, "usys": 121, "urisk": 29.51}];
    var score2men = [
        {
            "age": 40,
            "n": 12,
            "urisk": 2.542643937,
            "usys": 120.8333333,
            "uchol": 222.5833333,
            "uhdl": 57.36666667
        }, {"age": 41, "n": 16, "urisk": 1.92687787, "usys": 113.875, "uchol": 212.25, "uhdl": 54.185}, {
            "age": 42,
            "n": 22,
            "urisk": 2.475448065,
            "usys": 127.2272727,
            "uchol": 217.5454545,
            "uhdl": 58.28545455
        }, {
            "age": 43,
            "n": 18,
            "urisk": 2.025829649,
            "usys": 116.9166667,
            "uchol": 206.2777778,
            "uhdl": 56.72444444
        }, {"age": 44, "n": 15, "urisk": 2.25312935, "usys": 114.3, "uchol": 203.6, "uhdl": 57.79733333}, {
            "age": 45,
            "n": 24,
            "urisk": 2.560664213,
            "usys": 115.375,
            "uchol": 211.1666667,
            "uhdl": 58.99666667
        }, {
            "age": 46,
            "n": 27,
            "urisk": 3.133616194,
            "usys": 121.2407407,
            "uchol": 210.6296296,
            "uhdl": 51.91851852
        }, {"age": 47, "n": 32, "urisk": 3.319667537, "usys": 119.03125, "uchol": 230.6875, "uhdl": 56.72}, {
            "age": 48,
            "n": 29,
            "urisk": 3.350245399,
            "usys": 121.4482759,
            "uchol": 207.2758621,
            "uhdl": 60.15862069
        }, {"age": 49, "n": 25, "urisk": 2.920992637, "usys": 117.56, "uchol": 199.92, "uhdl": 57.9168}, {
            "age": 50,
            "n": 32,
            "urisk": 4.203247225,
            "usys": 120.421875,
            "uchol": 221.15625,
            "uhdl": 59.25875
        }, {
            "age": 51,
            "n": 22,
            "urisk": 3.906738912,
            "usys": 117.1136364,
            "uchol": 218.2272727,
            "uhdl": 57.74545455
        }, {"age": 52, "n": 17, "urisk": 4.401484583, "usys": 125.5294118, "uchol": 215, "uhdl": 58.74117647}, {
            "age": 53,
            "n": 24,
            "urisk": 4.259188514,
            "usys": 123.2291667,
            "uchol": 217.2083333,
            "uhdl": 59.64833333
        }, {
            "age": 54,
            "n": 27,
            "urisk": 4.809164354,
            "usys": 121.7962963,
            "uchol": 214.1851852,
            "uhdl": 54.52148148
        }, {
            "age": 55,
            "n": 27,
            "urisk": 4.98004979,
            "usys": 122.8518519,
            "uchol": 210.5185185,
            "uhdl": 58.80592593
        }, {
            "age": 56,
            "n": 14,
            "urisk": 5.407526274,
            "usys": 123.6428571,
            "uchol": 211.1428571,
            "uhdl": 56.24571429
        }, {
            "age": 57,
            "n": 18,
            "urisk": 6.121196849,
            "usys": 125.8611111,
            "uchol": 218.8333333,
            "uhdl": 55.05333333
        }, {"age": 58, "n": 19, "urisk": 5.8091273, "usys": 125.7631579, "uchol": 217.7894737, "uhdl": 60.43157895}, {
            "age": 59,
            "n": 16,
            "urisk": 6.46648984,
            "usys": 123.40625,
            "uchol": 218.5625,
            "uhdl": 55.195
        }, {"age": 60, "n": 20, "urisk": 7.28859742, "usys": 129, "uchol": 223.3, "uhdl": 57.1}, {
            "age": 61,
            "n": 27,
            "urisk": 7.152541047,
            "usys": 122.5,
            "uchol": 227.1111111,
            "uhdl": 59.26814815
        }, {"age": 62, "n": 20, "urisk": 7.788855382, "usys": 125.575, "uchol": 214.55, "uhdl": 55.526}, {
            "age": 63,
            "n": 23,
            "urisk": 8.312857448,
            "usys": 123.3043478,
            "uchol": 213.173913,
            "uhdl": 55.64869565
        }, {
            "age": 64,
            "n": 23,
            "urisk": 7.794957955,
            "usys": 119.9347826,
            "uchol": 213.3478261,
            "uhdl": 58.03130435
        }, {"age": 65, "n": 16, "urisk": 7.903784233, "usys": 120.4375, "uchol": 228.5, "uhdl": 69.91}, {
            "age": 66,
            "n": 19,
            "urisk": 9.442771817,
            "usys": 127.8947368,
            "uchol": 219.3684211,
            "uhdl": 58.49894737
        }, {"age": 67, "n": 17, "urisk": 10.6682938, "usys": 128.7058824, "uchol": 222.6470588, "uhdl": 50.84}, {
            "age": 68,
            "n": 19,
            "urisk": 10.08786364,
            "usys": 128.8421053,
            "uchol": 216.8947368,
            "uhdl": 59.16210526
        }, {"age": 69, "n": 15, "urisk": 11.8321807, "usys": 133.2666667, "uchol": 214.4, "uhdl": 61.08266667}];
    var score2women = [
        {
            "age": 40,
            "n": 13,
            "urisk": 0.580147735,
            "usys": 102.0384615,
            "uchol": 190.6153846,
            "uhdl": 68.67384615
        }, {
            "age": 41,
            "n": 17,
            "urisk": 0.591555702,
            "usys": 102.2058824,
            "uchol": 198.7058824,
            "uhdl": 75.97176471
        }, {
            "age": 42,
            "n": 26,
            "urisk": 0.721349672,
            "usys": 107.7692308,
            "uchol": 193.5769231,
            "uhdl": 63.46615385
        }, {
            "age": 43,
            "n": 26,
            "urisk": 0.76686485,
            "usys": 104.0961538,
            "uchol": 194.0384615,
            "uhdl": 69.55076923
        }, {
            "age": 44,
            "n": 19,
            "urisk": 0.806540553,
            "usys": 103.4210526,
            "uchol": 206.8947368,
            "uhdl": 73.15368421
        }, {
            "age": 45,
            "n": 27,
            "urisk": 0.915514926,
            "usys": 106.7592593,
            "uchol": 197.1111111,
            "uhdl": 68.11407407
        }, {
            "age": 46,
            "n": 38,
            "urisk": 0.988013141,
            "usys": 108.1842105,
            "uchol": 207.1578947,
            "uhdl": 75.70631579
        }, {"age": 47, "n": 30, "urisk": 1.0004413, "usys": 108.6666667, "uchol": 208.3666667, "uhdl": 72.64666667}, {
            "age": 48,
            "n": 32,
            "urisk": 1.207141159,
            "usys": 110.15625,
            "uchol": 208.75,
            "uhdl": 72.505
        }, {
            "age": 49,
            "n": 26,
            "urisk": 1.872385065,
            "usys": 111.9807692,
            "uchol": 214.1153846,
            "uhdl": 69.03846154
        }, {
            "age": 50,
            "n": 22,
            "urisk": 1.508444572,
            "usys": 112.6590909,
            "uchol": 211.9545455,
            "uhdl": 75.49636364
        }, {"age": 51, "n": 32, "urisk": 1.390707509, "usys": 108.953125, "uchol": 213, "uhdl": 77.9825}, {
            "age": 52,
            "n": 16,
            "urisk": 1.419483384,
            "usys": 109.9375,
            "uchol": 225.0625,
            "uhdl": 77.85
        }, {
            "age": 53,
            "n": 31,
            "urisk": 1.835551177,
            "usys": 116.8387097,
            "uchol": 231.8387097,
            "uhdl": 77.74967742
        }, {"age": 54, "n": 26, "urisk": 2.288742139, "usys": 113.0769231, "uchol": 225.5, "uhdl": 75.54461538}, {
            "age": 55,
            "n": 21,
            "urisk": 2.184734379,
            "usys": 107.2857143,
            "uchol": 224.1904762,
            "uhdl": 74.55238095
        }, {"age": 56, "n": 28, "urisk": 2.206689588, "usys": 106.75, "uchol": 237.4285714, "uhdl": 71.71714286}, {
            "age": 57,
            "n": 28,
            "urisk": 2.496065268,
            "usys": 110.1607143,
            "uchol": 232.1071429,
            "uhdl": 78.27857143
        }, {
            "age": 58,
            "n": 34,
            "urisk": 2.832043726,
            "usys": 116.4852941,
            "uchol": 226.0588235,
            "uhdl": 76.48941176
        }, {"age": 59, "n": 34, "urisk": 3.151345603, "usys": 113.6911765, "uchol": 227.5, "uhdl": 73.85176471}, {
            "age": 60,
            "n": 28,
            "urisk": 3.447860541,
            "usys": 115.1964286,
            "uchol": 238.1428571,
            "uhdl": 74.58
        }, {
            "age": 61,
            "n": 31,
            "urisk": 4.20028198,
            "usys": 113.3225806,
            "uchol": 229.2258065,
            "uhdl": 64.96258065
        }, {"age": 62, "n": 20, "urisk": 4.144162703, "usys": 113.875, "uchol": 239.4, "uhdl": 76.186}, {
            "age": 63,
            "n": 28,
            "urisk": 4.298793756,
            "usys": 118.5892857,
            "uchol": 238.3214286,
            "uhdl": 75.01428571
        }, {
            "age": 64,
            "n": 31,
            "urisk": 4.509830297,
            "usys": 113.7741935,
            "uchol": 232.483871,
            "uhdl": 76.52516129
        }, {"age": 65, "n": 20, "urisk": 4.997852503, "usys": 115.975, "uchol": 234.8, "uhdl": 71.604}, {
            "age": 66,
            "n": 24,
            "urisk": 5.351858886,
            "usys": 121.2291667,
            "uchol": 240.5416667,
            "uhdl": 74.15
        }, {
            "age": 67,
            "n": 17,
            "urisk": 5.824003673,
            "usys": 122.0882353,
            "uchol": 240.5294118,
            "uhdl": 69.97647059
        }, {"age": 68, "n": 12, "urisk": 6.047655626, "usys": 114.125, "uchol": 246.8333333, "uhdl": 80.63333333}, {
            "age": 69,
            "n": 18,
            "urisk": 6.760375504,
            "usys": 119.25,
            "uchol": 246.1666667,
            "uhdl": 74.93333333
        }];
    var Calculator = {
        Smart: 'smart',
        Frs10: 'frs10',
        Score2OP: 'score2OP',
        Score2: 'score2'
    };
    var MeanData = (_a = {},
        _a[Calculator.Smart] = [SMART, SMART],
        _a[Calculator.Frs10] = [FRS10woman, FRS10men],
        _a[Calculator.Score2OP] = [scoreOPwomen, scoreOPmen],
        _a[Calculator.Score2] = [score2women, score2men],
        _a);
    var MeanDataAgeRange = (_b = {},
        _b[Calculator.Smart] = [50, 80],
        _b[Calculator.Frs10] = [40, 74],
        _b[Calculator.Score2OP] = [65, 105],
        _b[Calculator.Score2] = [40, 69],
        _b);
    var initialResult = {
        'formel': '',
        'show_risk': false,
        'user_value': 0,
        'mean_value': 0,
        'opt_value': 0,
        'heart_age': 0,
        'relative_opt_value': 0,
        'relative_mean_value': 0,
        'user': ""
    };
    var HeartAgeCalculator = /** @class */ (function () {
        function HeartAgeCalculator() {
        }

        // FIXME not relevant for web calc
        // private static bloodPressureAverageLimit: number = 7;
        HeartAgeCalculator.get = function (age, sex, userValues) {
            if (userValues.Stroke || userValues.HeartAttack || userValues.Extension || userValues.Window) {
                return this.prepareCalculationSMART(sex, age, userValues);
            }
            if (userValues.Diabetes) {
                if (age < 70) {
                    var resultFRS = this.prepareCalculateFRS10(sex, age, userValues);
                    if (Math.abs(age - resultFRS.heart_age) >= this.deviationLimit) {
                        resultFRS.heart_age = 0;
                        resultFRS.show_risk = true;
                    }
                    return resultFRS;
                } else if (age >= 70) {
                    return this.prepareCalculateSCORE2OP(sex, age, userValues);
                }
            } else {
                if (age < 40) {
                    return this.prepareCalculateFRS10(sex, age, userValues);
                }
                if (age >= 40 && age <= 69) {
                    return this.prepareCalculateSCORE2(sex, age, userValues);
                }
                if (age >= 70) {
                    return this.prepareCalculateSCORE2OP(sex, age, userValues);
                }
            }
            return initialResult;
        };
        HeartAgeCalculator.meanValues = function (age, sex, userValues) {
            var values = {
                sys: 0,
                chol: 0,
                hdl: 0,
                gfr: null,
                crp: null,
                dias: null
            };
            if (userValues.Stroke || userValues.HeartAttack || userValues.Extension || userValues.Window) {
                var smartValues = this.getRowByAge(Calculator.Smart, sex, age);
                values.sys = smartValues.usys;
                values.chol = smartValues.uchol;
                values.hdl = smartValues.uhdl;
                values.gfr = smartValues.ugfr;
                values.crp = smartValues.ucrp;
                return values;
            }
            if (userValues.Diabetes) {
                if (age < 70) {
                    var frsValues = this.getRowByAge(Calculator.Frs10, sex, age);
                    values.sys = frsValues.usys;
                    values.chol = frsValues.uchol;
                    values.hdl = frsValues.uhdl;
                    return values;
                }
                if (age >= 70) {
                    var scoreValues = this.getRowByAge(Calculator.Score2OP, sex, age);
                    values.sys = scoreValues.usys;
                    values.chol = scoreValues.uchol;
                    values.hdl = scoreValues.uhdl;
                    values.dias = scoreValues.udias;
                    return values;
                }
            } else {
                if (age < 40) {
                    var frsValues = this.getRowByAge(Calculator.Frs10, sex, age);
                    values.sys = frsValues.usys;
                    values.chol = frsValues.uchol;
                    values.hdl = frsValues.uhdl;
                    return values;
                }
                if (age >= 40 && age < 70) {
                    var scoreValues = this.getRowByAge(Calculator.Score2, sex, age);
                    values.sys = scoreValues.sys;
                    values.chol = scoreValues.chol;
                    values.hdl = scoreValues.hdl;
                    return values;
                }
                if (age >= 70) {
                    var scoreValues = this.getRowByAge(Calculator.Score2OP, sex, age);
                    values.sys = scoreValues.usys;
                    values.chol = scoreValues.uchol;
                    values.hdl = scoreValues.uhdl;
                    return values;
                }
            }
            return values;
        };
        HeartAgeCalculator.getRowByAge = function (calculator, sex, age) {
            var _a;
            var json_data = MeanData[calculator][sex];
            var data = JSON.parse(JSON.stringify(json_data));
            age = Math.min(Math.max(MeanDataAgeRange[calculator][0], age), MeanDataAgeRange[calculator][1]);
            return (_a = data.find(function (row) {
                return row.age == age;
            })) !== null && _a !== void 0 ? _a : undefined;
        };
        // SMART
        HeartAgeCalculator.prepareCalculationSMART = function (sex, age, userValues) {
            var result = {
                'formel': Calculator.Smart,
                'show_risk': true,
                'user_value': 0,
                'mean_value': 0,
                'opt_value': 0,
                'heart_age': 0,
                'relative_opt_value': 0,
                'relative_mean_value': 0,
                'user': ""
            };
            var mean_data = this.getRowByAge(Calculator.Smart, sex, age);
            result.mean_value = mean_data.meanrisk / 100;
            var user = {
                "usex": sex,
                "uage": age,
                "uchol": userValues.Cholesterol ? userValues.Cholesterol : mean_data.uchol,
                "uhdl": userValues.HdlCholesterol ? userValues.HdlCholesterol : mean_data.uhdl,
                "ugfr": userValues.Renal ? userValues.Renal : mean_data.ugfr,
                "ucrp": userValues.Inflammation ? userValues.Inflammation : mean_data.ucrp,
                "uCAD": userValues.HeartAttack ? userValues.HeartAttack : 0,
                "uCBD": userValues.Stroke ? userValues.Stroke : 0,
                "uPAD": userValues.Window ? userValues.Window : 0,
                "uAAA": userValues.Extension ? userValues.Extension : 0,
                "uyears": userValues.HeartDisease ? userValues.HeartDisease : 0,
                "usmoker": userValues.Smoker == 1 ? 1 : 0,
                "usys": userValues.BloodPressureSystolic ? userValues.BloodPressureSystolic : mean_data.usys,
                "udiabetes": userValues.Diabetes
            };

            var _a = this.calculateSMART(user), user_value = _a.user_value, opt_value = _a.opt_value;
            result.user_value = user_value;
            result.opt_value = opt_value;
            result.show_risk = true;
            result.relative_mean_value = this.round(result.user_value / result.mean_value, 1);
            result.relative_opt_value = this.round(result.user_value / result.opt_value, 1);
            result.user = JSON.stringify(user, null, 2);
            return result;
        };
        // FRS10
        HeartAgeCalculator.prepareCalculateFRS10 = function (sex, age, userValues) {
            var result = {
                'formel': Calculator.Frs10,
                'show_risk': false,
                'user_value': 0,
                'mean_value': 0,
                'opt_value': 0,
                'heart_age': 0,
                'relative_opt_value': 0,
                'relative_mean_value': 0,
                'user': ""
            };
            var mean_data = this.getRowByAge(Calculator.Frs10, sex, age);
            result.mean_value = mean_data.mean / 100;
            var user = {
                "usex": sex,
                "uage": age,
                "usys": userValues.BloodPressureSystolic ? userValues.BloodPressureSystolic : mean_data.usys,
                "uchol": userValues.Cholesterol ? userValues.Cholesterol : mean_data.uchol,
                "uhdl": userValues.HdlCholesterol ? userValues.HdlCholesterol : mean_data.uhdl,
                "usmoking": userValues.Smoker == 1 ? 1 : 0,
                "udiabetes": userValues.Diabetes,
                "treatment": 0
            };
            var _a = this.calculateFRS10(user), user_value = _a.user_value, opt_value = _a.opt_value,
                heart_age = _a.heart_age;
            result.user_value = user_value;
            result.opt_value = opt_value;
            result.heart_age = heart_age;
            result.show_risk = heart_age == 0;
            result.relative_mean_value = this.round(result.user_value / result.mean_value, 1);
            result.relative_opt_value = this.round(result.user_value / result.opt_value, 1);
            result.user = JSON.stringify(user, null, 2);
            return result;
        };
        // SCORE2
        HeartAgeCalculator.prepareCalculateSCORE2 = function (sex, age, userValues) {
            var result = {
                'formel': Calculator.Score2,
                'show_risk': false,
                'user_value': 0,
                'mean_value': 0,
                'opt_value': 0,
                'heart_age': 0,
                'relative_opt_value': 0,
                'relative_mean_value': 0,
                'user': ""
            };
            var mean_data = this.getRowByAge(Calculator.Score2, sex, age);
            result.mean_value = mean_data.urisk / 100;
            var user = {
                "usex": sex,
                "uage": age,
                "usys": userValues.BloodPressureSystolic ? userValues.BloodPressureSystolic : mean_data.usys,
                "uchol": userValues.Cholesterol ? userValues.Cholesterol : mean_data.uchol,
                "uhdl": userValues.HdlCholesterol ? userValues.HdlCholesterol : mean_data.uhdl,
                "usmoking": userValues.Smoker == 1 ? 1 : 0
            };
            var _a = this.calculateSCORE2(user), user_value = _a.user_value, opt_value = _a.opt_value,
                heart_age = _a.heart_age;
            result.user_value = user_value;
            result.opt_value = opt_value;
            result.heart_age = heart_age;
            result.show_risk = heart_age == 0;
            result.relative_mean_value = this.round(result.user_value / result.mean_value, 1);
            result.relative_opt_value = this.round(result.user_value / result.opt_value, 1);
            result.user = JSON.stringify(user, null, 2);
            return result;
        };
        // SCORE2OP
        HeartAgeCalculator.prepareCalculateSCORE2OP = function (sex, age, userValues) {
            var result = {
                'formel': Calculator.Score2OP,
                'show_risk': false,
                'user_value': 0,
                'mean_value': 0,
                'opt_value': 0,
                'heart_age': 0,
                'relative_opt_value': 0,
                'relative_mean_value': 0,
                'user': ""
            };
            var mean_data = this.getRowByAge(Calculator.Score2OP, sex, age);
            result.mean_value = mean_data.urisk / 100;
            var user = {
                "usex": sex,
                "uage": age,
                "usys": userValues.BloodPressureSystolic ? userValues.BloodPressureSystolic : mean_data.usys,
                "uchol": userValues.Cholesterol ? userValues.Cholesterol : mean_data.uchol,
                "uhdl": userValues.HdlCholesterol ? userValues.HdlCholesterol : mean_data.uhdl,
                "usmoking": userValues.Smoker == 1 ? 1 : 0,
                "udiabetes": userValues.Diabetes
            };
            var _a = this.calculateSCORE2OP(user), user_value = _a.user_value, opt_value = _a.opt_value,
                heart_age = _a.heart_age;
            result.user_value = user_value;
            result.opt_value = opt_value;
            result.heart_age = heart_age;
            result.show_risk = heart_age == 0;
            result.relative_mean_value = this.round(result.user_value / result.mean_value, 1);
            result.relative_opt_value = this.round(result.user_value / result.opt_value, 1);
            result.user = JSON.stringify(user, null, 2);
            return result;
        };
        HeartAgeCalculator.round = function (value, decimals) {
            var factor = Math.pow(10, decimals);
            return Math.round(value * factor) / factor;
        };
        HeartAgeCalculator.avg = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return args.reduce(function (a, b) {
                return a + b;
            }, 0) / args.length;
        };
        HeartAgeCalculator.calculateSCORE2OP = function (user) {
            var result = {
                'user_value': 0,
                'opt_value': 0,
                'heart_age': 0
            };
            result.user_value = this.score2OP(user);
            result.opt_value = this.score2OP({
                "usex": user.usex,
                "uage": user.uage,
                "usys": 120,
                "uchol": 150,
                "uhdl": 50,
                "usmoking": 0,
                "udiabetes": 0
            });
            for (var uage = 16; uage <= 120; uage++) {
                var norm_value = this.score2OP({
                    "usex": user.usex,
                    "uage": uage,
                    "usys": 130,
                    "uchol": 200,
                    "uhdl": 50,
                    "usmoking": 0,
                    "udiabetes": 0
                });
                if (result.user_value <= norm_value) {
                    result.heart_age = uage;
                    break;
                }
            }
            return result;
        };
        HeartAgeCalculator.score2OP = function (user) {
            var meanLP_men = 0.093;
            var meanLP_women = 0.229;
            var s10y_men = 0.758;
            var s10y_women = 0.808;
            var scale1_men = 0.01;
            var scale2_men = 1.25;
            var scale1_women = -0.1;
            var scale2_women = 1.1;
            var CFchol = 38.67;
            // # convert
            user.uchol = user.uchol / CFchol;
            user.uhdl = user.uhdl / CFchol;
            // #:::::::::::::::::::::::::::::::::::
            // # >> prep input ----
            // #:::::::::::::::::::::::::::::::::::
            var chol_center = user.uchol - 6;
            var sys_center = user.usys - 150;
            var hdl_center = user.uhdl - 1.4;
            var age_center = user.uage - 73;
            if (user.usex === 0) {
                // #..................
                // # >> CHD ----
                // #..................
                var coef_age = 0.0789;
                var coef_I_diabetes = 0.6010;
                var coef_I_diabetesxage = -0.0107;
                var coef_I_smok = 0.4921;
                var coef_I_smokxage = -0.0255;
                var coef_sys = 0.0102;
                var coef_sysxage = -0.0004;
                var coef_chol = 0.0605;
                var coef_cholxage = -0.0009;
                var coef_hdl = -0.3040;
                var coef_hdlxage = 0.0154;
                var thepred = coef_age * age_center +
                    coef_I_diabetes * user.udiabetes +
                    coef_I_diabetesxage * user.udiabetes * age_center +
                    coef_I_smok * user.usmoking +
                    coef_I_smokxage * user.usmoking * age_center +
                    coef_sys * sys_center +
                    coef_sysxage * sys_center * age_center +
                    coef_chol * chol_center +
                    coef_cholxage * chol_center * age_center +
                    coef_hdl * hdl_center +
                    coef_hdlxage * hdl_center * age_center;
                var theta_original = 1 - Math.pow(s10y_women, Math.exp(thepred - meanLP_women));
                var theta = 1 - Math.exp(-Math.exp(scale1_women + scale2_women * Math.log(-Math.log(1 - theta_original))));
                var the10yrisk = theta;
                return the10yrisk;
            } else if (user.usex === 1) {
                // #..................
                // # >> CHD ----
                // #..................
                var coef_age = 0.0634;
                var coef_I_diabetes = 0.4245;
                var coef_I_diabetesxage = -0.0174;
                var coef_I_smok = 0.3524;
                var coef_I_smokxage = -0.0247;
                var coef_sys = 0.0094;
                var coef_sysxage = -0.0005;
                var coef_chol = 0.0850;
                var coef_cholxage = 0.0073;
                var coef_hdl = -0.3564;
                var coef_hdlxage = 0.0091;
                var thepred = coef_age * age_center +
                    coef_I_diabetes * user.udiabetes +
                    coef_I_diabetesxage * user.udiabetes * age_center +
                    coef_I_smok * user.usmoking +
                    coef_I_smokxage * user.usmoking * age_center +
                    coef_sys * sys_center +
                    coef_sysxage * sys_center * age_center +
                    coef_chol * chol_center +
                    coef_cholxage * chol_center * age_center +
                    coef_hdl * hdl_center +
                    coef_hdlxage * hdl_center * age_center;
                var theta_original = 1 - Math.pow(s10y_men, Math.exp(thepred - meanLP_men)); // 1 - s10y_men * e^(thepred - meanLP_men)
                var theta = 1 - Math.exp(-Math.exp(scale1_men + scale2_men * Math.log(-Math.log(1 - theta_original)))); // 1 - e^(-e^(scale1_men + scale2_men * log(-log(1 - theta_original))))
                var the10yrisk = theta;
                return the10yrisk;
            } else {
                return -1;
            }
        };
        HeartAgeCalculator.calculateSMART = function (user) {
            var result = {
                'user_value': 0,
                'opt_value': 0
            };
            result.user_value = this.smart10y(user);
            result.opt_value = this.smart10y({
                "usex": user.usex,
                "uage": user.uage,
                "uchol": 110,
                "uhdl": 50,
                "ugfr": 80,
                "ucrp": 0.1,
                "uCAD": user.uCAD,
                "uCBD": user.uCBD,
                "uPAD": user.uPAD,
                "uAAA": user.uAAA,
                "uyears": user.uyears,
                "usmoker": 0,
                "usys": 120,
                "udiabetes": 0
            });
            return result;
        };
        HeartAgeCalculator.smart10y = function (user) {
            // # conversion factor mmol/l to mg/dl
            var CFchol = 38.67;
            // # convert
            var uchol = user.uchol / CFchol;
            var uhdl = user.uhdl / CFchol;
            // #::::::::::::::::::::::::::::
            // # >> Coefficients ----
            // #::::::::::::::::::::::::::::
            var coef_age = -0.0850;
            var coef_ageXage = 0.00105;
            var coef_I_male = 0.156;
            var coef_I_smok = 0.262;
            var coef_sys = 0.00429;
            var coef_diabetes = 0.223;
            var coef_I_CAD = 0.140;
            var coef_I_CBD = 0.406;
            var coef_I_AAA = 0.558;
            var coef_I_PAD = 0.283;
            var coef_years = 0.0229;
            var coef_hdl = -0.426;
            var coef_chol = 0.0959;
            var coef_gfr = -0.0532;
            var coef_gfrXgfr = 0.000306;
            var coef_log_crp = 0.139;
            // # linpred
            var eqA = coef_age * user.uage +
                coef_ageXage * user.uage * user.uage +
                coef_I_male * user.usex +
                coef_I_smok * user.usmoker +
                coef_sys * user.usys +
                coef_diabetes * user.udiabetes +
                coef_I_CAD * user.uCAD +
                coef_I_CBD * user.uCBD +
                coef_I_AAA * user.uAAA +
                coef_I_PAD * user.uPAD +
                coef_years * user.uyears +
                coef_hdl * uhdl +
                coef_chol * uchol +
                coef_gfr * user.ugfr +
                coef_gfrXgfr * user.ugfr * user.ugfr +
                coef_log_crp * Math.log(user.ucrp);
            return 1 - Math.pow(0.81066, Math.exp(eqA + 2.099));
        };
        HeartAgeCalculator.calculateSCORE2 = function (user) {
            var result = {
                'user_value': 0,
                'opt_value': 0,
                'heart_age': 0
            };
            result.user_value = this.score2(user);
            result.opt_value = this.score2({
                "usex": user.usex,
                "uage": user.uage,
                "usys": 120,
                "uchol": 150,
                "uhdl": 50,
                "usmoking": 0
            });
            for (var uage = 16; uage <= 120; uage++) {
                var norm_value = this.score2({
                    "usex": user.usex,
                    "uage": uage,
                    "usys": 130,
                    "uchol": 200,
                    "uhdl": 50,
                    "usmoking": 0
                });
                if (result.user_value <= norm_value) {
                    result.heart_age = uage;
                    break;
                }
            }
            return result;
        };
        HeartAgeCalculator.score2 = function (user) {
            var s10y_men = 0.9605;
            var s10y_women = 0.9776;
            var scale1_men = -0.1565;
            var scale2_men = 0.8009;
            var scale1_women = -0.3143;
            var scale2_women = 0.7701;
            var CFchol = 38.67;
            // # convert
            user.uchol = user.uchol / CFchol;
            user.uhdl = user.uhdl / CFchol;
            // #:::::::::::::::::::::::::::::::::::
            // # >> prep input ----
            // #:::::::::::::::::::::::::::::::::::
            var chol_center = (user.uchol - 6) / 1;
            var sys_center = (user.usys - 120) / 20;
            var hdl_center = (user.uhdl - 1.3) / 0.5;
            var age_center = (user.uage - 60) / 5;
            if (user.usex === 0) {
                // #..................
                // # >> CHD ----
                // #..................
                var coef_age = 0.4648;
                var coef_I_smok = 0.7744;
                var coef_I_smokxage = -0.1088;
                var coef_sys = 0.3131;
                var coef_sysxage = -0.0277;
                var coef_chol = 0.1002;
                var coef_cholxage = -0.0226;
                var coef_hdl = -0.2606;
                var coef_hdlxage = 0.0613;
                var thepred = coef_age * age_center +
                    coef_I_smok * user.usmoking +
                    coef_sys * sys_center +
                    coef_chol * chol_center +
                    coef_hdl * hdl_center +
                    coef_I_smokxage * user.usmoking * age_center +
                    coef_sysxage * sys_center * age_center +
                    coef_cholxage * chol_center * age_center +
                    coef_hdlxage * hdl_center * age_center;
                var theta_original = 1 - Math.pow(s10y_women, Math.exp(thepred));
                var theta = 1 - Math.exp(-Math.exp(scale1_women + scale2_women * Math.log(-Math.log(1 - theta_original))));
                var the10yrisk = theta;
                return the10yrisk;
            } else if (user.usex === 1) {
                // #..................
                // # >> CHD ----
                // #..................
                var coef_age = 0.3742;
                var coef_I_smok = 0.6012;
                var coef_I_smokxage = -0.0755;
                var coef_sys = 0.2777;
                var coef_sysxage = -0.0255;
                var coef_chol = 0.1458;
                var coef_cholxage = -0.0281;
                var coef_hdl = -0.2698;
                var coef_hdlxage = 0.0426;
                var thepred = coef_age * age_center +
                    coef_I_smok * user.usmoking +
                    coef_sys * sys_center +
                    coef_chol * chol_center +
                    coef_hdl * hdl_center +
                    coef_I_smokxage * user.usmoking * age_center +
                    coef_sysxage * sys_center * age_center +
                    coef_cholxage * chol_center * age_center +
                    coef_hdlxage * hdl_center * age_center;
                var theta_original = 1 - Math.pow(s10y_men, Math.exp(thepred)); // 1 - s10y_men * e^(thepred - meanLP_men)
                var theta = 1 - Math.exp(-Math.exp(scale1_men + scale2_men * Math.log(-Math.log(1 - theta_original)))); // 1 - e^(-e^(scale1_men + scale2_men * log(-log(1 - theta_original))))
                var the10yrisk = theta;
                return the10yrisk;
            } else {
                return -1;
            }
        };
        HeartAgeCalculator.calculateFRS10 = function (user) {
            var result = {
                'user_value': 0,
                'opt_value': 0,
                'heart_age': 0
            };
            result.user_value = this.frs10y(user);
            result.opt_value = this.frs10y({
                "usex": user.usex,
                "uage": user.uage,
                "usys": 120,
                "uchol": 150,
                "uhdl": 50,
                "treatment": 0,
                "usmoking": 0,
                "udiabetes": 0
            });
            for (var uage = 16; uage <= 120; uage++) {
                var norm_value = this.frs10y({
                    "usex": user.usex,
                    "uage": uage,
                    "usys": 130,
                    "uchol": 200,
                    "uhdl": 50,
                    "treatment": 0,
                    "usmoking": 0,
                    "udiabetes": 0
                });
                if (result.user_value <= norm_value) {
                    result.heart_age = uage;
                    break;
                }
            }
            return result;
        };
        HeartAgeCalculator.frs10y = function (user) {
            if (user.usex === 0) {
                return this.frs10ywomen(user);
            } else if (user.usex === 1) {
                return this.frs10ymen(user);
            } else {
                return 0;
            }
        };
        HeartAgeCalculator.frs10ywomen = function (user) {
            var crude_10_year_survival = 0.9394610;
            // Women
            var coef_ln_age = 2.32888;
            var coef_ln_totalchol = 1.20904;
            var coef_ln_hdl = -0.70833;
            var coef_ln_untreated = 2.76157;
            var coef_ln_treated = 2.82263;
            var coef_smoking = 0.52873;
            var coef_diabetes = 0.69154;
            var eqL = coef_ln_age * Math.log(user.uage) +
                coef_ln_totalchol * Math.log(user.uchol) +
                coef_ln_hdl * Math.log(user.uhdl) +
                coef_ln_untreated * (user.treatment === 0 ? Math.log(user.usys) : 0) +
                coef_ln_treated * (user.treatment === 1 ? Math.log(user.usys) : 0) +
                coef_smoking * user.usmoking +
                coef_diabetes * user.udiabetes;
            // const mean_ln_age = 3.998936
            // const mean_ln_totalchol = 5.44751
            // const mean_ln_hdl = 4.13092
            // const mean_ln_untreated = 3.747835
            // const mean_ln_treated = 1.084758
            // const prop_smoking = 0.173588
            // const prop_diabetes = 0.04817276
            // const eqG = coef_ln_age * mean_ln_age +
            //     coef_ln_totalchol * mean_ln_totalchol +
            //     coef_ln_hdl * mean_ln_hdl +
            //     coef_ln_untreated * mean_ln_untreated +
            //     coef_ln_treated * mean_ln_treated +
            //     coef_smoking * prop_smoking +
            //     coef_diabetes * prop_diabetes
            // The formular above is static and the calcualted value is 26.51012
            // Hence this calculation is skipped and its static value ist used
            var eqG = 26.51012;
            var eqA = eqL - eqG;
            var eqB = Math.exp(eqA);
            var result = (1 - Math.pow(crude_10_year_survival, eqB)); // 🐛 Older versions calculate this value times 100 (* 100)
            return result;
        };
        HeartAgeCalculator.frs10ymen = function (user) {
            var crude_10_year_survival = 0.8826905;
            // Men
            var coef_ln_age = 3.06117;
            var coef_ln_totalchol = 1.12370;
            var coef_ln_hdl = -0.93263;
            var coef_ln_untreated = 1.93303;
            var coef_ln_treated = 1.99881;
            var coef_smoking = 0.65451;
            var coef_diabetes = 0.57367;
            var eqL = coef_ln_age * Math.log(user.uage) +
                coef_ln_totalchol * Math.log(user.uchol) +
                coef_ln_hdl * Math.log(user.uhdl) +
                coef_ln_untreated * (user.treatment === 0 ? Math.log(user.usys) : 0) +
                coef_ln_treated * (user.treatment === 1 ? Math.log(user.usys) : 0) +
                coef_smoking * user.usmoking +
                coef_diabetes * user.udiabetes;
            // const mean_ln_age = 4.010242
            // const mean_ln_totalchol = 5.448849
            // const mean_ln_hdl = 3.904954
            // const mean_ln_untreated = 3.96042
            // const mean_ln_treated = 0.9496274
            // const prop_smoking = 0.2405619
            // const prop_diabetes = 0.06496927
            // const eqG = coef_ln_age * mean_ln_age +
            //     coef_ln_totalchol * mean_ln_totalchol +
            //     coef_ln_hdl * mean_ln_hdl +
            //     coef_ln_untreated * mean_ln_untreated +
            //     coef_ln_treated * mean_ln_treated +
            //     coef_smoking * prop_smoking +
            //     coef_diabetes * prop_diabetes
            // The formular above is static and the calcualted value is 24.50548
            // Hence this calculation is skipped and its static value ist used
            var eqG = 24.50548;
            var eqA = eqL - eqG;
            var eqB = Math.exp(eqA);
            var result = (1 - Math.pow(crude_10_year_survival, eqB)); // 🐛 Older versions calculate this value times 100 (* 100)
            return result;
        };
        HeartAgeCalculator.deviationLimit = 20;
        return HeartAgeCalculator;
    }());
    return HeartAgeCalculator;

}
