import React, {Component} from 'react';
import {Typography} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import SortIcon from 'components/sort-icon/SortIcon';

const HeaderItem = (props) => {
    const {onSort, sortParameter, sortName, title, reverseSort, item, style, left } = props;
    const positioningStyles = left ? { paddingLeft: '14px', alignItems: 'center' } : {};
    return (
        <Flex item={item || '1 0 5%'} container onClick={onSort && onSort(sortName)} center={left ? false : true} justifyContent='flex-start' style={style || {width: '5%', ...positioningStyles}}>
            <Typography style={{color: '#585757', fontSize: 13, textAlign: 'left'}}>{title}</Typography>
            {
                onSort &&
                <SortIcon isShown={sortParameter === sortName} reverse={reverseSort}/>
            }
        </Flex>
    );
};

export class PatientHeader extends Component {
    render() {
        const {t: translate, onSort, sortParameter, reverseSort} = this.props;

        return (
            <Flex item container grow={0} style={{
                backgroundColor: "#F8F8FA",
                marginLeft: 70,
                marginRight: 10,
                height: 40,
                borderRadius: '15px 15px 0px 0px',
                border: '1px solid #E2E1E4'
            }}>

                <HeaderItem {...{
                    left: true,
                    title: translate('global.name'),
                    sortName: 'familyName',
                    onSort, reverseSort, sortParameter,
                }}/>

                <HeaderItem {...{
                    left: true,
                    title: translate('global.programs'),
                    sortName: 'programs',
                    onSort, reverseSort, sortParameter,
                }}/>

                <HeaderItem {...{
                    left: true,
                    title: translate('global.wo'),
                    sortName: 'place',
                    onSort, reverseSort, sortParameter,
                }}/>

                <HeaderItem {...{
                    left: true,
                    title: translate('global.nextTask'),
                    sortName: 'description',
                    onSort, reverseSort, sortParameter,
                }}/>

                <HeaderItem {...{
                    left: true,
                    title: translate('global.date'),
                    sortName: 'date',
                    onSort, reverseSort, sortParameter,
                }}/>

                <HeaderItem {...{
                    title: translate('global.manage'),
                    sortName: 'unseenSum',
                    onSort, reverseSort, sortParameter,
                }}/>

            </Flex>
        );
    }
}

export default withTranslation()(PatientHeader);
