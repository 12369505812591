import {Box, useTheme, Tooltip, Select, MenuItem, withStyles, IconButton, makeStyles} from "@material-ui/core";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {Field, LabeledValue} from "./ProgramStepRendererCommonComponents";
import {dateValue, getTranslationArgsForTimeSpan} from "./utils";
import RepeatIcon from "@mui/icons-material/Repeat";
import warningIcon from "assets/img/high-voltage.png";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CheckIcon from "@mui/icons-material/Check";
import {toTime} from "./ProgramStepRendererEditMode";
import TrueDateSelectorDialog from "./TrueDateSelectorDialog";
import React, {useState} from "react";
import StatusSwitch from "./StatusSwitch";
import {DEPENDENT_ON_PREVIOUS_TASK, RECURRING, EXACT} from "domain/ProgramStepDateType.model";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useIsManager} from "utils";
import {toArray} from "utils/Utils";
import {useSendProgramStepReminderMutation, useSendProgramStepSpecialRequestMutation} from "scenes/patient/ProgramApi";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import {useEnqueueError, useEnqueueSuccess} from "components/alert/SnackbarHooks";
import ReminderEmail from "./ReminderEmail";
import SpecialRequestEmail from "./SpecialRequestEmail";

const CalculatedDateRenderer = (t, theme) => props => {
    const {
        dateTimeSpan, dateType, date, dateTimeSpanValue, missingDependency, previousDateType, dependency,
        previousDependency, isCompleted
    } = props;
    const translatedDateTimeSpan = t(getTranslationArgsForTimeSpan(dateTimeSpan, dateTimeSpanValue || 2));

    const shouldShowMissingDependencyWarning = dateType === DEPENDENT_ON_PREVIOUS_TASK && missingDependency;

    const getTooltipText = (dateType, previousDateType) => {
        const dependentTasksTooltipTranslatedText = t("program.after-previous-calculated-date-tooltip", {
            dateTimeSpan: translatedDateTimeSpan || "(days, weeks, months?)",
            dateTimeSpanValue: dateTimeSpanValue || "n",
            taskNumber: dependency?.position || previousDependency?.position || "",
            taskDescription: dependency?.description || previousDependency?.description || "",
        });
        const recurringEventTranslatedText = t("program.reocurring-event-tooltip", {
            dateTimeSpan: t(getTranslationArgsForTimeSpan(dateTimeSpan, dateTimeSpanValue, "DATIVE")),
            dateTimeSpanValue: dateTimeSpanValue,
        });

        const shouldShowDependencyText = dateType === DEPENDENT_ON_PREVIOUS_TASK || (dateType === EXACT && previousDateType === DEPENDENT_ON_PREVIOUS_TASK);
        const shouldShowRecurringEventText = dateType === EXACT && previousDateType === RECURRING;

        if (shouldShowDependencyText) {
            return (!!dependency || !!previousDependency) ? dependentTasksTooltipTranslatedText : t('program.after-previous-calculated-date-tooltip-with-deleted-dependency')
        } else if (shouldShowRecurringEventText) {
            return recurringEventTranslatedText
        } else {
            return ""
        }
    }
    const tooltipText = getTooltipText(dateType, previousDateType);
    const tooltipElement = tooltipText && <span style={{fontSize: 13}}>{tooltipText}</span>;

    return <div style={{position: "relative"}}>
        <div
                style={{
                    color: shouldShowMissingDependencyWarning ? theme.palette.error.main : isCompleted ? '#00AE9E' : theme.palette.info.main,
                    fontStyle: "italic",
                    paddingRight: "30px",
                    display: "inline-block",
                    whiteSpace: "pre-wrap",
                }}
        >
            <Tooltip placement="right" arrow title={tooltipElement}>
                {(dateType === EXACT || dateType === RECURRING) ? <time>{date}</time> :
                        <time>{t("program.calculatedDate")}</time>}
            </Tooltip>
        </div>
        {shouldShowMissingDependencyWarning && (
                <Tooltip title={t("global.broken-step-dependency")} placement={"top"}>
                    <img
                            src={warningIcon}
                            alt={""}
                            width="20px"
                            style={{
                                position: "absolute",
                                right: 4,
                                bottom: 2,
                            }}
                    />
                </Tooltip>
        )}
    </div>;
};

const useStyles = makeStyles((theme) => ({
    "@keyframes slide": {
        "0%": {
            backgroundPosition: "100% 0",
        },
        "100%": {
            backgroundPosition: "-300% 0",
        }
    },
    loadingContainer: {
        userSelect: "none",
        background: "linear-gradient(90deg, transparent, rgba(0,0,0, .12), transparent)",
        backgroundSize: "200% 100%",
        animation: `$slide 2.4s infinite `,
        "& > *": {
            filter: "blur(5px) grayscale(1) opacity(.8)",
        }
    }
}));

const StyledCompactSelect = withStyles({
    root: {
        fontSize: "14px",
        paddingTop: "2px",
        paddingBottom: "2px",
    },
})(Select);

export const ProgramStepRendererViewMode = (props) => {
    const {
        step, saveStep, openRepeatedTaskConfigurationModal, dependencyIsMissing, otherStepsInProgram, refreshPrograms,
        isStepCollapsed, toggleCollapse, isLoading, isProgramSuspended
    } = props;
    const classes = useStyles();
    const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false);
    const managerRoleSelected = useIsManager();
    const {t} = useTranslation();
    const theme = useTheme();

    const dateFormat = (date, time) => {
        if (time) {
            const [hours, minutes] = time.split(":");
            return moment(date).hours(hours).minutes(minutes).format("DD. MMM YYYY HH:mm");
        } else {
            return moment(date).format("DD. MMM YYYY") + "  --:--";
        }
    };

    const toggleStatus = (event, value) => {
        if (!value || moment(step.date).isSame(moment(), "day")) {
            saveStep({
                ...step,
                isCompleted: value,
            });
        } else {
            setIsDateSelectorOpen(true);
        }
    };

    const dateLabelValue = dateValue({
        dateTimeSpan: step.dateTimeSpan,
        dateTimeSpanValue: step.dateTimeSpanValue,
        date: step.date,
        time: toTime(step.date),
        dateType: step.dateType,
        missingDependency: dependencyIsMissing,
        dependency: otherStepsInProgram.find((x) => x.id === step.dependsOnTaskId),
        calculatedDateRenderer: CalculatedDateRenderer(t, theme),
        previousDateType: step.previousDateType,
        previousDependency: otherStepsInProgram.find((x) => x.id === step.previousDependsOnTaskId),
        dateUpdatedBy: step.dateUpdatedBy,
        isCompleted: step.isCompleted
    });

    const canCompleteStep = !isProgramSuspended && (step.patientCanComplete || managerRoleSelected);

    const availableNotificationTypes = toArray(step.availableNotificationTypes, ',');

    return <Box className={isLoading ? classes.loadingContainer : ""} display="flex" position={"relative"}
                flexDirection={"column"}>
        <Box style={{}} display="flex" position={"relative"} padding={"10px"}>
            <Box
                    flex={'0 0 200px'}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    marginRight={5}
            >
                <Box display={"flex"} flexDirection={"row"} alignItems={"flex-end"}>
                    <LabeledValue
                            label={t("global.date")}
                            value={dateLabelValue
                                    .map(dateFormat)
                                    .map((dateString) => [dateString, t("global.o'clock")].join(" "))
                                    .getRenderedView()}
                    />
                    {step.dateType === RECURRING && (
                            <Tooltip
                                    arrow
                                    placement="top"
                                    title={
                                        <span style={{fontSize: 13}}>
                                        {t("program.reocurring-event-tooltip", {
                                            dateTimeSpan: t(
                                                    getTranslationArgsForTimeSpan(
                                                            step.dateTimeSpan,
                                                            step.dateTimeSpanValue,
                                                            "DATIVE"
                                                    )
                                            ),
                                            dateTimeSpanValue: step.dateTimeSpanValue,
                                        })}
                                    </span>
                                    }
                            >
                                <RepeatIcon
                                        onClick={openRepeatedTaskConfigurationModal}
                                        style={{
                                            fontSize: "23px",
                                            marginRight: 4,
                                            cursor: "pointer",
                                            color: theme.palette.info.main,
                                        }}
                                />
                            </Tooltip>
                    )}
                </Box>
                {!isStepCollapsed &&
                        <StatusSwitch {...{status: step.isCompleted, toggleStatus, disabled: !canCompleteStep}} />}
            </Box>
            <Box flex={'1 1 auto'}>
                <Box display="flex">
                    <Box flex={2} marginRight="20px">
                        <Field
                                {...{
                                    isEditable: false,
                                    value: step.description,
                                    label: t("global.description"),
                                    name: "description",
                                    onChange: () => {
                                    },
                                    boldValue: true,
                                }}
                        />
                    </Box>
                    {!step.isCompleted && <Box flex={1}>
                        <Field
                                {...{
                                    isEditable: false,
                                    value: step.place,
                                    label: t("global.wo"),
                                    name: "place",
                                    onChange: () => {
                                    },
                                }}
                        />
                    </Box>}
                </Box>
                {!isStepCollapsed && <Box>
                    <Field
                            {...{
                                isEditable: false,
                                value: step.comment,
                                label: t("global.comment"),
                                name: "comment",
                                onChange: () => {
                                },
                            }}
                    />
                </Box>}
            </Box>
            {step.isCompleted && isStepCollapsed && <Box marginRight={1} alignItems={'center'}>
                <CheckIcon style={{color: "rgba(0, 174, 158, .5)", fontSize: "40px"}}/>
            </Box>}
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} flex={'0 0 40px'}>
                {!step.isCompleted &&
                        <Box position={"relative"}>
                            {step.reminderIsOn && <ReminderEmail step={step} refreshPrograms={refreshPrograms}/>}
                            {step.specialRequestIsOn &&
                                    <SpecialRequestEmail step={step} refreshPrograms={refreshPrograms}/>}
                        </Box>
                }
                {step.isCompleted && <IconButton onClick={() => {
                    toggleCollapse(step.id);
                }} size="small">
                    {isStepCollapsed ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
                </IconButton>}
            </Box>
        </Box>
        {step.informResultPreference && !isStepCollapsed && (
                <Box

                        display={"flex"}
                        bgcolor={theme.palette.grey[200]}
                        padding={"4px"}
                        style={{borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px"}}
                        justifyContent={"center"}
                        borderTop={`1px solid ${theme.palette.divider}`}
                        alignItems={"center"}
                >
                    <span style={{marginRight: "10px"}}>
                        {t("global.how-would-you-like-to-be-informed-about-examination-result")}
                    </span>

                    <StyledCompactSelect
                            id="demo-simple-select"
                            name="informResultPreferenceType"
                            value={step.informResultPreferenceType ?? ''}
                            displayEmpty={true}
                            renderValue={step.informResultPreferenceType ? undefined : () => t("global.please-select")}
                            onChange={(event) => {
                                saveStep({...step, informResultPreferenceType: event.target.value});
                            }}
                    >
                        {availableNotificationTypes.map((x) => {
                            return (
                                    <MenuItem
                                            key={x}
                                            value={x}
                                    >
                                        {t(`program.step-notifications.types.${x}`)}
                                    </MenuItem>
                            );
                        })}
                    </StyledCompactSelect>
                </Box>
        )}
        {isDateSelectorOpen && (
                <TrueDateSelectorDialog {...{step, saveStep, onClose: () => setIsDateSelectorOpen(false)}} />
        )}
    </Box>;
};
