export const CaseStatusEnum = Object.freeze({
    NewForClinic: "NEW_FOR_CLINIC",
    AcceptedForClinic: "ACCEPTED_FOR_CLINIC",
    New: "NEW",
    RegisterPatient: "REGISTER_PATIENT",
    WaitForDecision: "WAIT_FOR_DECISION",
    CheckForCostCoverage: "CHECK_FOR_COST_COVERAGE",
    WaitForPayment: "WAIT_FOR_PAYMENT",
    WaitForGdprConsentForm: "WAIT_FOR_GDPR_CONSENT_FORM",
    AskForMissingDocuments: "ASK_FOR_MISSING_DOCUMENTS",
    CheckDocuments: "CHECK_DOCUMENTS",
    PrepareCaseForExpert: "PREPARE_CASE_FOR_EXPERT",
    RequestExpertToTakeOverCase: "REQUEST_EXPERT_TO_TAKE_OVER_CASE",
    CaseNotReviewedByExpert: "CASE_NOT_REVIEWED_BY_EXPERT",
    CaseUnderReviewByExpert: "CASE_UNDER_REVIEW_BY_EXPERT",
    GenerateReportOfTheSecondOpinion: "GENERATE_REPORT_OF_THE_SECOND_OPINION",
    CallPatientToTellTheResult: "CALL_PATIENT_TO_TELL_THE_RESULT",
    InvoiceCostCoverageInstance: "INVOICE_COST_COVERAGE_INSTANCE",
    NotifyExpertToSendInvoice: "NOTIFY_EXPERT_TO_SEND_INVOICE",
    FurtherQuestionsForTheExperts: "FURTHER_QUESTIONS_FOR_THE_EXPERTS",
    GetFeedbackFromPatient: "GET_FEEDBACK_FROM_PATIENT",
    StudyQuestionnaire: "STUDY_QUESTIONNAIRE",
    Cancellation: "CANCELLATION",
    Done: "DONE"
});
