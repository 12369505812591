/**
 * @typedef {import('./HematologyRiskProfile.mapper').HematologyRiskProfile} HematologyRiskProfile
 */

const minMaxScores = {
    redCellDistributionWidth: [1, 2.5],
    meanCorpuscularVolumeScore: [1, 2.5],
    singleDNMT3A: [0.5, 1],
    highRiskMutation: [1, 2.5],
    mutationNumber: [1, 2.5],
    varianAlleleFractionAboveThreshold: [1, 2],
    cythopenia: [1, 1.5],
    ageScore: [1, 1.5],
};

/**  @type {[number, number]} */
export const minMaxScoresSumTuple = Object.values(minMaxScores).reduce(
    (acc, [min, max]) => [acc[0] + min, acc[1] + max],
    [0, 0]
);

/**
 *
 * @param {number} score Risk score
 * @returns {number} Risk score in percentage
 */
export function scoreToPercentage(score, maxPercentage = 100) {
    const [minScore, maxScore] = minMaxScoresSumTuple;
    if (score <= minScore) return 0;
    let normalizedScore = (score - minScore) / (maxScore - minScore);
    let percentage = normalizedScore * 100;
    return percentage > maxPercentage ? maxPercentage : Number.parseFloat(percentage.toPrecision(2));
}

/**
 * @param {HematologyRiskProfile} input
 * @returns {number} hematological risk score
 */
export const hematologyRiskCalculator = ({
    redCellDistributionWidth,
    meanCorpuscularVolume,
    singleDNMT3A,
    highRiskMutation,
    mutationNumber,
    varianAlleleFractionAboveThreshold,
    cythopenia,
    age,
}) => {
    return [
        calculateMutationScore({
            redCellDistributionWidth,
            meanCorpuscularVolume,
        }),
        calculateBloodValuesScore({
            singleDNMT3A,
            highRiskMutation,
            mutationNumber,
            varianAlleleFractionAboveThreshold,
            cythopenia,
        }),
        calculateAgeScore(age),
    ].reduce((acc, score) => acc + score);
};

function calculateMutationScore({ redCellDistributionWidth, meanCorpuscularVolume }) {
    const redCellDistributionWidthScore =
        redCellDistributionWidth < 15
            ? minMaxScores.redCellDistributionWidth[0]
            : minMaxScores.redCellDistributionWidth[1];
    const meanCorpuscularVolumeScore =
        meanCorpuscularVolume < 100
            ? minMaxScores.meanCorpuscularVolumeScore[0]
            : minMaxScores.meanCorpuscularVolumeScore[1];
    return redCellDistributionWidthScore + meanCorpuscularVolumeScore;
}

/**
 *
 * @param {Object} bloodValues
 * @param {boolean} bloodValues.singleDNMT3A
 * @param {boolean} bloodValues.highRiskMutation
 * @param {number} bloodValues.mutationNumber
 * @param {boolean} bloodValues.varianAlleleFractionAboveThreshold
 * @param {'CHIP' | 'CCUS'} bloodValues.cythopenia
 * @returns {number}
 */
function calculateBloodValuesScore({
    singleDNMT3A,
    highRiskMutation,
    mutationNumber,
    varianAlleleFractionAboveThreshold,
    cythopenia,
}) {
    // what if there is zero DNM3A mutations ?
    return [
        singleDNMT3A ? minMaxScores.singleDNMT3A[0] : minMaxScores.singleDNMT3A[1],
        highRiskMutation ? minMaxScores.highRiskMutation[1] : minMaxScores.highRiskMutation[0],
        mutationNumber <= 1 ? minMaxScores.mutationNumber[0] : minMaxScores.mutationNumber[1],
        varianAlleleFractionAboveThreshold
            ? minMaxScores.varianAlleleFractionAboveThreshold[1]
            : minMaxScores.varianAlleleFractionAboveThreshold[0],
        cythopenia === "CCUS" ? minMaxScores.cythopenia[1] : minMaxScores.cythopenia[0],
    ].reduce((acc, score) => acc + score);
}

/**
 *
 * @param {number} age
 * @returns {number} score
 */
function calculateAgeScore(age) {
    return age < 65 ? 1 : 1.5;
}
