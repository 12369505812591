import React, {Component} from 'react';
import {
    MenuItem,
    Dialog,
    Button,
    Typography,
    IconButton,
    InputAdornment,
    Tooltip,
    Portal
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import connector from './UserManager.connect';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import {Autocomplete} from '@material-ui/lab';
import _ from 'lodash';
import Divider from 'components/divider/Divider';
import EpiAlert from 'components/alert/EpiAlert';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from '@material-ui/icons/Visibility';
import PasswordChecklist from "react-password-checklist";

export class UserManager extends Component {

    state = {
        isOpen: false,
        isNewPasswordOpen: false,
        user: {},
        emailAddress: '',
        originalEmailAddress: '',
        mfaType: 'NONE',
        givenName: '',
        familyName: '',
        initials: '',
        newPassword: '',
        passwordRepeat: '',
        isValidPassword: true,
        isPasswordVisible: false,
        alert: '',
        errorCode: '',
    }

    componentDidMount() {
        if (this.props.userBusinessId) {
            this.props.getUser(this.props.userBusinessId).then(response => {
                this.setState({
                    user: response?.payload?.data,
                    emailAddress: response?.payload?.data?.emailAddress,
                    originalEmailAddress: response?.payload?.data?.emailAddress,
                    givenName: response?.payload?.data?.givenName,
                    familyName: response?.payload?.data?.familyName,
                    initials: response?.payload?.data?.initials,
                    mfaType: response?.payload?.data?.mfaType
                })
            })
        }
    }

    toggleOpen = event => {
        event.stopPropagation();
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    handleStateChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    togglePasswordVisibility = () => {
        this.setState(state => ({isPasswordVisible: !state.isPasswordVisible}));
    };

    validatePassword = isValid => {
        this.setState({isValidPassword: isValid});
    }

    updateUser = () => {
        if (!_.isEmpty(this.state.user)) {
            this.props.updateUser({
                ...this.state.user,
                emailAddress: this.state.emailAddress,
                givenName: this.state.givenName,
                familyName: this.state.familyName,
                initials: this.state.initials,
                mfaType: this.state.mfaType,
                ...(this.state.newPassword && this.state.isValidPassword && {password: this.state.newPassword})
            })
                .then(() => {
                    this.setState({
                        alert: 'success',
                        errorCode: '',
                        isOpen: false,
                        originalEmailAddress: this.state.emailAddress
                    });
                    if (this.props.updateCallback) {
                        this.props.updateCallback();
                    }
                })
                .catch(error => {
                    this.setState({alert: 'error', errorCode: `${error.error.response.status}`});
                })
                .finally(() => {
                    this.setState({
                        isNewPasswordOpen: false,
                        newPassword: '',
                        passwordRepeat: '',
                        isValidPassword: true,
                        isPasswordVisible: false,
                    })
                })
        }
    }

    closeSnackbar = () => {
        this.setState({
            alert: '',
        });
    };

    toggleIsNewPasswordOpen = () => {
        this.setState({
            isNewPasswordOpen: !this.state.isNewPasswordOpen,
        });
    };

    stopPropagation = e => {
        e.stopPropagation();
    };

    render() {
        const {t: translate, emailAddresses = [], title, buttonStyle, style} = this.props;
        const {
            isOpen, emailAddress, mfaType, newPassword, passwordRepeat, isValidPassword,
            isPasswordVisible, givenName, familyName, initials, user, isNewPasswordOpen
        } = this.state;
        const {
            toggleOpen, handleStateChange, togglePasswordVisibility, validatePassword, updateUser,
            toggleIsNewPasswordOpen, stopPropagation
        } = this;
        const isOpenDisabled = _.isEmpty(user);

        return (
            <Flex item container style={{minWidth: 150, ...style}}>
                <Tooltip title={isOpenDisabled ? translate('global.missing-user') : ''}>
                    <span style={buttonStyle}>
                        <Button size='small' variant='contained' color="primary" disabled={isOpenDisabled} onClick={toggleOpen}>
                                {title || translate('global.edit-user-management')}
                        </Button>
                    </span>
                </Tooltip>
                {
                    isOpen &&
                    <Dialog open={true} maxWidth={"lg"} onClick={stopPropagation}>
                        <Flex item container column padding={20} style={{width: '50vw'}}>
                            <Flex item container alignItems={'center'}>
                                <Typography variant={'h6'}>User account</Typography>
                                <Flex item container justifyContent={'flex-end'}>
                                    <Button variant={'outlined'} size={'small'} style={{margin: 5}}
                                            onClick={toggleOpen}>
                                        {translate('global.close')}
                                    </Button>
                                    <Button color={'primary'} variant={'contained'} size={'small'} style={{margin: 5}}
                                            onClick={updateUser}>
                                        {translate('global.save')}
                                    </Button>
                                </Flex>
                            </Flex>
                            <Autocomplete
                                options={emailAddresses || []}
                                onChange={()=>{}}
                                disabled
                                onInputChange={()=>{}}
                                value={emailAddress || null}
                                freeSolo
                                renderInput={params => (
                                    <DefaultTextField
                                        {...params}
                                        label={translate('case.email')}
                                    />
                                )}
                            />

                            <Divider/>
                            <DefaultTextField value={givenName || ''}
                                              label={translate("case.first-name")}
                                              error={!givenName}
                                              name={'givenName'}
                                              onChange={handleStateChange}/>

                            <DefaultTextField label={translate("case.last-name")}
                                              value={familyName || ''}
                                              onChange={handleStateChange}
                                              error={!familyName}
                                              name={'familyName'}/>
                            <DefaultTextField label={translate("global.initials")}
                                              value={initials || ''}
                                              inputProps={{maxLength: 3}}
                                              onChange={handleStateChange}
                                              name={'initials'}/>
                            <DefaultTextField label="Two-factor authentication"
                                              select
                                              disabled
                                              SelectProps={{
                                                  value: mfaType,
                                                  onChange: handleStateChange
                                              }}>
                                <MenuItem key={'MAIL'} value={'MAIL'}>
                                    Email
                                </MenuItem>
                                <MenuItem key={'NONE'} value={'NONE'}>
                                    None
                                </MenuItem>
                            </DefaultTextField>
                            <Divider/>
                            {
                                !isNewPasswordOpen &&
                                <Button variant={'contained'} size={'small'} style={{margin: 5}}
                                        onClick={toggleIsNewPasswordOpen}>
                                    {translate('global.change-password')}
                                </Button>
                            }
                            {
                                isNewPasswordOpen &&
                                <>
                                    <DefaultTextField
                                        name={'newPassword'}
                                        label={translate('login.new-password')}
                                        error={!isValidPassword}
                                        type={isPasswordVisible ? 'text' : 'password'}
                                        value={newPassword}
                                        onChange={handleStateChange}
                                        inputProps={{
                                            autoComplete: 'new-password'
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Toggle password visibility"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>)
                                        }}
                                    />
                                    <DefaultTextField
                                        name={'passwordRepeat'}
                                        label={translate('login.reset-password-new-repeat')}
                                        error={!isValidPassword}
                                        type={isPasswordVisible ? 'text' : 'password'}
                                        value={passwordRepeat}
                                        onChange={handleStateChange}
                                        style={{marginBottom: 10}}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Toggle password visibility"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>)
                                        }}
                                    />
                                    <PasswordChecklist
                                        rules={["minLength", "specialChar", "number", "capital", "match"]}
                                        minLength={10}
                                        value={newPassword}
                                        valueAgain={passwordRepeat}
                                        onChange={validatePassword}
                                        messages={{
                                            minLength: translate('global.minLength'),
                                            specialChar: translate('global.specialChar'),
                                            number: translate('global.password-number'),
                                            capital: translate('global.capital'),
                                            match: translate('global.match'),
                                        }}
                                    />
                                </>
                            }
                        </Flex>
                    </Dialog>
                }
                <Portal>
                    <EpiAlert
                        {...{
                            isOpen: !!this.state.alert,
                            close: this.closeSnackbar,
                            severity: this.state.alert === 'error' ? this.state.alert : 'success',
                            message:
                                this.state.alert === 'error'
                                    ? translate('global.backend-call-failed')
                                    : this.state.alert
                                        ? translate(`global.${this.state.alert}`)
                                        : '',
                        }}
                    />
                </Portal>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(UserManager));
