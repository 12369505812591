import {Document, Page} from 'react-pdf';
import {pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import React, { useState } from 'react';
import { Dialog } from "@material-ui/core";
import utils from 'utils/Utils';
import pdfjsWorker from 'react-pdf/dist/cjs/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PdfViewer = ({fileBlobUrl, onClose}) => {
    const [numPages, setNumPages] = useState(null);
    const isMobile = utils.checkMobileBrowser();
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
            <Dialog open={true} maxWidth={'xl'} onClose={onClose} scroll={'body'}>
                <Document file={{url: fileBlobUrl}} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(
                            new Array(numPages),
                            (page, index) => (
                                    <Page width={isMobile ? '100vw' : 900}
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                    />
                            ),
                    )}
                </Document>
            </Dialog>
    );
};

export default PdfViewer;
