import {useState, useEffect, useMemo} from "react";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Title from "scenes/managed-care/component/Title";
import {isManager, isPatient} from "domain/User.model";
import _ from "lodash";
import fp, {cloneDeep, zip} from "lodash/fp";
import {useSelector} from "react-redux";
import {useParams, useNavigate, Navigate} from "react-router-dom";
import {
    useFetchPatientProgramsQuery,
    useNewCareProgramStepMutation,
    useUpsertCareProgramMutation,
    useRequestToLeaveCareProgramMutation,
} from "scenes/patient/ProgramApi";
import update from "immutability-helper";
import EpiAlert from "components/alert/EpiAlert";
//TODO LM: Rename library 'functions' to program-step-utils
import {getStepsOrderAfterReordering} from "scenes/patient/functions/getStepsOrderAfterReordering";
import {sortSteps, getStepDateWithAddedTimeSpan} from "scenes/patient/functions";
import moment from "moment";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import {PATIENT_PAGE} from "routes/routes";
import {EXACT, RECURRING, DEPENDENT_ON_PREVIOUS_TASK} from "domain/ProgramStepDateType.model";
import {EVERY_TIME} from "domain/DependentCycle.model";
import {useScrollNewlyCreatedStepIntoView, useStepTracker} from "./ProgramStepRenderer/hooks";
import {ProgramUnsubscribeModal} from "./ProgramUnsubscribeModal";
import utils from "utils/Utils";
import StepsContext from "scenes/patient/program-view/StepsContext";

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 1,
        position: "relative",
        background: "white",
    },
}));

const getDependentStepsWithIndexes = (changingStep, selectedProgram) => {
    const steps = selectedProgram.steps;
    const dependentSteps = steps
            .filter((step) => step.dependsOnTaskId === changingStep.id) // only this step dependencies
            .filter(
                    (step) =>
                            changingStep.isCompleted &&
                            step &&
                            step.dateType === DEPENDENT_ON_PREVIOUS_TASK &&
                            step.isCompleted === false
            )
            .map((dependentStep) => ({
                ...dependentStep,
                date: getStepDateWithAddedTimeSpan({
                    //TODO LM: Inject User selected date here
                    fromDate: changingStep.date,
                    dateTimeSpan: dependentStep.dateTimeSpan,
                    timeSpanValue: dependentStep.dateTimeSpanValue,
                }),
                dateType: EXACT,
                previousDateType: dependentStep.dateType,
                dateUpdatedBy: "ALGORITHM",
                dependsOnTaskId: null,
                previousDependsOnTaskId: dependentStep.dependsOnTaskId,
            }));
    const dependentStepIndexes = dependentSteps.map((_step) => steps.findIndex((__step) => __step.id === _step.id));
    return zip(dependentStepIndexes, dependentSteps);
};

export const dependencyTree = (rootStep, steps, result) => {
    for (let i = 0; i < steps.length; i++) {
        const item = steps[i];
        if (item.dependsOnTaskId === rootStep.id && item.dependentCycle === EVERY_TIME) {
            dependencyTree(item, steps, result);
        }
    }

    //NOTE: Switcharoo to preserve order on the same branch.
    if (result.find((item) => item.dependsOnTaskId === rootStep.dependsOnTaskId)) {
        result.push(rootStep);
    } else {
        result.unshift(rootStep);
    }

    return result;
};

const cloneRecurringStep = (step, careProgramId) => {
    const {id,dependsOnTaskId,previousDependsOnTaskId, ...other} = step;
    return {
        ...other,
        careProgramId,
        clonedFromId: step.id,
        clonedFromDependsOnTaskId: step.dependsOnTaskId,
        date: getStepDateWithAddedTimeSpan({
            fromDate: step.date
                    ? moment(step.date).hours(0).minutes(0).seconds(0).millisecond(0).toDate()
                    : moment().hours(0).minutes(0).seconds(0).millisecond(0).toDate(),
            dateTimeSpan: step.dateTimeSpan,
            timeSpanValue: step.dateTimeSpanValue,
        }),
        isCompleted: false,
        creationFinished: true,
        dateUpdatedBy: "ALGORITHM",
    };
};

const getNewRecurringStepPosition = (newStep, program, positionOfTheLastStepInOriginalTree) => {
    const sortedOriginalSteps = sortSteps(program.steps, program.stepsOrder);
    const sortedStepsAfterOriginalRecurringTreeWithDates = sortedOriginalSteps
            .slice(positionOfTheLastStepInOriginalTree)
            .filter((_step) => _step.date)
            .sort((a, b) => (a.date === b.date ? 0 : a.date < b.date ? -1 : 1));
    const futureStep = sortedStepsAfterOriginalRecurringTreeWithDates.find((_step) =>
            moment(_step.date).isAfter(moment(newStep.date))
    );
    const futureStepIndex = futureStep ? sortedOriginalSteps.findIndex((_step) => _step.id === futureStep.id) : 0;
    //NOTE LM: If we have steps with dates after the new step we add the new tree right before
    if (futureStep) {
        return futureStepIndex;
    } else {
        //NOTE LM: If we have steps with dates after the new step but no future step, then the new step is the last
        if (sortedStepsAfterOriginalRecurringTreeWithDates.length > 1) {
            return sortedOriginalSteps.length;
            //NOTE LM: If there are no original steps with dates in the future, then we place the new step under the original tree
        } else {
            return positionOfTheLastStepInOriginalTree + 1;
        }
    }
};

const updateProgramWithRecurringStep = (program, changedStep) => {
    let position = undefined;
    let _program = _.cloneDeep(program);
    if (changedStep.dateType === RECURRING && changedStep.creationFinished && changedStep.isCompleted) {
        const originalTree = dependencyTree(changedStep, program.steps, []);
        const positionOfTheLastStepInOriginalTree = program.stepsOrder.findIndex(
                (item) => item === "" + originalTree[originalTree.length - 1].id
        );
        const newSteps = originalTree.map((item) => cloneRecurringStep(item, program.id));
        //TODO LM: Talk about this with Roy, if this is not just one step, the date calculation makes no sense
        position = getNewRecurringStepPosition(newSteps[0], program, positionOfTheLastStepInOriginalTree);
        _program.steps = [..._program.steps, ...newSteps];
    }
    return {position, _program};
};

const updateProgramByDependentSteps = (step, program, originalProgram, user) => {
    const dependentStepsWithIndexes = getDependentStepsWithIndexes(step, program);
    const dependsOnStep = program.steps.find((s) => s.id === step.dependsOnTaskId);
    const stepIndex = program.steps.findIndex((_step) => _step.id === step.id);

    if (stepIndex > -1) {
        const changedOriginalStep = originalProgram.steps.find((_step) => _step.id === step.id);
        const updateDateUpdatedBy = (step) => {
            const oldDate = changedOriginalStep.date;
            const newDate = step.date;
            const dateChanged = newDate && !moment(newDate).isSame(moment(oldDate));
            return dateChanged
                    ? update(step, {
                        dateUpdatedBy: {$set: isPatient(user) ? "PATIENT" : "MANAGER"},
                        dateType: {$set: EXACT},
                    })
                    : step;
        };

        //NOTE LM: This is needed so when the step depends on an already completed step, it immediately becomes exact
        const updatedStepByAlgorithm = (step) => {
            const shouldAfterPreviousBeCalculatedNow =
                    dependsOnStep?.isCompleted &&
                    step.dateType === DEPENDENT_ON_PREVIOUS_TASK &&
                    step.isCompleted === false &&
                    step.creationFinished === true;
            return shouldAfterPreviousBeCalculatedNow
                    ? update(step, {
                        date: {
                            $set: getStepDateWithAddedTimeSpan({
                                fromDate: step.date,
                                dateTimeSpan: step.dateTimeSpan,
                                timeSpanValue: step.dateTimeSpanValue,
                            }),
                        },
                        dateType: {$set: EXACT},
                        previousDateType: {$set: step.dateType},
                        dateUpdatedBy: {$set: "ALGORITHM"},
                    })
                    : step;
        };
        const resetStepReoccurringStatusAfterMarkedAsDone = (step) => {
            return step.isCompleted && step.creationFinished && step.dateType === RECURRING
                    ? update(step, {
                        previousDateType: {$set: step.dateType},
                        dateType: {$set: EXACT},
                    })
                    : step;
        };

        const updatedStep = fp.flow(
                updateDateUpdatedBy,
                updatedStepByAlgorithm,
                resetStepReoccurringStatusAfterMarkedAsDone
        )(step);
        const mappedSteps = Object.fromEntries(
                dependentStepsWithIndexes.map(([index, value]) => [index, {$set: value}])
        );
        return update(program, {
            steps: {
                ...mappedSteps,
                [stepIndex]: {$set: updatedStep},
            },
        });
    }
};

const updateProgramWithChangedStep = (program, changedStep) => {
    const index = program.steps.findIndex((item) => item.id === changedStep.id);
    return update(program, {
        steps: {
            [index]: {
                $set: changedStep,
            },
        },
    });
};

const ProgramView = () => {
    const classes = useStyles();
    const user = useSelector((state) => state.mainReducer.user);
    const navigate = useNavigate();
    const [isDragged, setIsDragged] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [editableId, setEditableId] = useState("");
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const toggleDeleteDialog = () => setIsDeleteDialogOpen(!isDeleteDialogOpen);
    const {t: translate} = useTranslation();
    const {patientId, programId} = useParams();

    const {
        data: programs = [],
        isSuccess: isProgramsSuccess,
        isError: isProgramError,
        isFetching: isPatientProgramsFetching,
        refetch: refreshPrograms,
    } = useFetchPatientProgramsQuery(patientId, {skip: !utils.isNumeric(programId)});

    const originalProgram = useMemo(
            () => programs?.find((item) => Number(programId) === item.careProgramData.id)?.careProgramData || {},
            [programs, programId]
    );

    const [program, setProgram] = useState({});

    // TODO: better to move load of program one component up and don't render Program view till request is finished
    const {alreadySeenSteps, collapsedSteps, setAlreadySeenSteps, setCollapsedSteps} = useStepTracker(
            originalProgram,
            isProgramsSuccess,
            isProgramError
    );
    const {stepsContainerRef} = useScrollNewlyCreatedStepIntoView(editableId, program);

    const [isReorderNotPossible, setIsReorderNotPossible] = useState(false);
    const [upsertCareProgram, {isLoading, error}] = useUpsertCareProgramMutation();
    const [newCareProgramStep, {isLoading: isNewStepLoading}] = useNewCareProgramStepMutation();
    const [requestToLeaveCareProgram] = useRequestToLeaveCareProgramMutation();
    const sortedSteps = _.sortBy(program?.steps || [], (item) => program?.stepsOrder.map(Number).indexOf(item.id));
    const addStepButtonShouldBeShown = isManager(user) && !editableId && !program.suspendedParticipation;

    const isUserManager = isManager(user);

    const saveStep = (step) => {
        let updatedProgram = updateProgramWithChangedStep(program, step); // mutates program step
        const {position, _program} = updateProgramWithRecurringStep(updatedProgram, step);
        // the only place where program step fields are manipulated based on some business logic
        updatedProgram = updateProgramByDependentSteps(step, _program, originalProgram, user);
        setProgram(updatedProgram);
        upsertCareProgram({position, careProgram: updatedProgram});
        setEditableId("");
    };

    /**
     *
     * @param {number} position - position of the new step in the program
     * @returns {Promise<void>}
     */
    const addStep = (position = 0) => {
        return newCareProgramStep({careProgramId: programId, position})
                .unwrap()
                .then((response) => {
                    const stepsOrder = response?.stepsOrder;
                    const steps = response?.steps;
                    const found = steps.find((x) => x.id === Number(stepsOrder[position]));
                    if (!found) return;
                    setEditableId(found?.id);
                });
    };

    const reorderSteps = (result) => {
        if (!result.destination) return; // null value is possible if only one element is present
        const {steps, stepsOrder} = program;
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;
        const sourceStep = steps.find((item) => "" + item.id === stepsOrder[sourceIndex]);
        const stepsBetween =
                sourceIndex < destinationIndex
                        ? stepsOrder.slice(sourceIndex, destinationIndex + 1)
                        : stepsOrder.slice(destinationIndex, sourceIndex);
        const isAnyStepBetweenADependency = steps
                .filter((item) => stepsBetween.includes("" + item.id))
                .some((item) => sourceStep?.dependsOnTaskId === item?.id);

        if (!isAnyStepBetweenADependency) {
            const newStepsOrder = getStepsOrderAfterReordering(steps, stepsOrder, {sourceIndex, destinationIndex});

            const updatedProgram = update(program, {
                stepsOrder: {
                    $set: newStepsOrder,
                },
            });
            setProgram(updatedProgram);
            upsertCareProgram({careProgram: updatedProgram});
        } else {
            setIsReorderNotPossible(true);
        }
    };

    const markStepAsSeen = (stepId) => {
        const isStepAlreadySeen = alreadySeenSteps.find((x) => x === stepId);
        if (isStepAlreadySeen) return;
        setAlreadySeenSteps([...alreadySeenSteps, stepId]);
    };

    const toggleCollapse = (stepId) => [
        setCollapsedSteps(
                collapsedSteps.includes(stepId) ? collapsedSteps.filter((x) => x !== stepId) : [...collapsedSteps, stepId]
        ),
    ];

    const deleteStep = (id) => {
        const index = program.steps.findIndex((_step) => _step.id === id);
        const stepOrderIndex = program.stepsOrder.findIndex((_id) => Number(_id) === id);
        if (index > -1) {
            const updatedSteps = program.steps.map((item) => {
                if (item.dependsOnTaskId === id) {
                    const itemCopy = cloneDeep(item);
                    itemCopy.dateType = "EXACT";
                    itemCopy.dateTimeSpan = null;
                    itemCopy.dateTimeSpanValue = null;
                    itemCopy.dependsOnTaskId = null;
                    return itemCopy;
                }
                return item;
            });

            const programWithUpdatedSteps = {
                ...cloneDeep(program),
                steps: updatedSteps,
            };

            const updatedProgramWithoutStepToDelete = update(programWithUpdatedSteps, {
                steps: {
                    $splice: [[index, 1]],
                },
                stepsOrder: stepOrderIndex > -1 ? {$splice: [[stepOrderIndex, 1]]} : {$set: program.stepsOrder},
            });
            setProgram(updatedProgramWithoutStepToDelete);
            upsertCareProgram({careProgram: updatedProgramWithoutStepToDelete});
        }
    };

    const handleProgramParticipationChange = (reason) => {
        isUserManager ? handleManagerUnsubscribe(reason) : handlePatientUnsubscribe(reason);
    };

    const handleManagerUnsubscribe = (reason) => {
        upsertCareProgram({
            careProgram: update(program, {
                suspendedParticipation: {
                    $set: true,
                },
                clinicTerminationReason: {
                    $set: reason,
                },
            }),
        })
                .unwrap()
                .then(() => {
                    navigate(PATIENT_PAGE.pathWithId(patientId));
                });
    };

    const handleCancelProgramTermination = () => {
        upsertCareProgram({
            careProgram: update(program, {
                requestToUnsubscribe: {
                    $set: false,
                },
            }),
        });
    };

    const handleReopenProgram = () => {
        upsertCareProgram({
            careProgram: {
                ...program,
                requestToUnsubscribe: false,
                suspendedParticipation: false,
                terminationReason: null,
                clinicTerminationReason: null,
            },
        });
    };

    const handlePatientUnsubscribe = (reason) => {
        toggleDeleteDialog();
        requestToLeaveCareProgram({programId, reason})
                .then(() => {
                    setAlertMessage("success");
                })
                .catch((e) => {
                    console.log("Error during unsubscribing from program", e);
                });
    };

    const onGoBack = () => {
        navigate(PATIENT_PAGE.pathWithId(patientId));
    };

    const closeSnackbar = () => setAlertMessage("");
    useEffect(() => {
        if (error) {
            setAlertMessage("error");
        }
    }, [error]);

    useEffect(() => {
        if (isProgramsSuccess) {
            setProgram(originalProgram);
        }
    }, [isProgramsSuccess, originalProgram]);

    return !utils.isNumeric(programId) ||
    (isProgramsSuccess &&
            !isPatientProgramsFetching &&
            !programs.find((item) => item.careProgramData.id === Number(programId))) ? (
            <Navigate to={"/"}/>
    ) : (
            <Box data-testid="program-view-page" className={classes.root}>
                <Title title={translate("global.program-details")} onBack={onGoBack} isLoading={isLoading}/>

                <StepsContext
                        {...{
                            stepsContainerRef,
                            classes,
                            program,
                            translate,
                            toggleDeleteDialog,
                            reorderSteps,
                            setIsDragged,
                            sortedSteps,
                            isLoading,
                            isNewStepLoading,
                            isPatientProgramsFetching,
                            deleteStep,
                            originalProgram,
                            saveStep,
                            editableId,
                            setEditableId,
                            addStep,
                            addStepButtonShouldBeShown,
                            isDragged,
                            alreadySeenSteps,
                            collapsedSteps,
                            toggleCollapse,
                            markStepAsSeen,
                            user,
                            cancelProgramTermination: handleCancelProgramTermination,
                            reopenProgram: handleReopenProgram,
                            refreshPrograms,
                            readonlyMode: program.suspendedParticipation
                        }}
                />

                <EpiAlert
                        {...{
                            isOpen: !!alertMessage,
                            close: closeSnackbar,
                            severity: alertMessage === "error" ? alertMessage : "success",
                            message:
                                    alertMessage === "error"
                                            ? translate("global.backend-call-failed")
                                            : alertMessage
                                                    ? translate(`global.${alertMessage}`)
                                                    : "",
                        }}
                />
                <ProgramUnsubscribeModal
                        programName={program.name}
                        requestToUnsubscribe={program.requestToUnsubscribe}
                        isOpen={isDeleteDialogOpen}
                        onClose={toggleDeleteDialog}
                        onUnsubscribe={handleProgramParticipationChange}
                />
                <ConfirmationDialog
                        {...{
                            singleConfirmationButton: true,
                            dialogOpen: isReorderNotPossible,
                            onConfirm: () => setIsReorderNotPossible(false),
                            confirmationTextKey: "global.reorder-not-possible",
                        }}
                />
            </Box>
    );
};

export default ProgramView;
