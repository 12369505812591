import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Sidebar from "components/sidebar/Sidebar";
import {Tab, Tabs} from "@material-ui/core";
import Flex from "components/grid/Flex";
import {useTranslation} from "react-i18next";
import PatientSidebar from "scenes/patient/patient-sidebar/PatientSidebar";
import {ThemeProvider, createTheme} from "@material-ui/core/styles";
import {Hmo_Theme} from "components/hmo-theme/HmoTheme";
import {patientThemePrimary} from "components/colors/Colors";
import {useParams, Outlet, Link, useLocation, Navigate} from "react-router-dom";
import { ContactPersonConfirmationsOfConfidentialityModal } from "./ContactPersonPatientConfidantialityModal";
import {
    PATIENT_PAGE,
    PATIENT_PAGE_ANAMNESE,
    PATIENT_PAGE_MEDICATION_PLAN,
    PATIENT_PAGE_LABORATORY,
    PATIENT_PAGE_MY_RISK
} from 'routes/routes';
import utils from 'utils/Utils';
import _ from "lodash";
import {useContactConfirmationOfPatientConfidentialityQuery, useFetchPatientByIdQuery} from "scenes/patient/PatientApi";
import { useIsContactPerson } from "../../utils";

export const patientTheme = createTheme({
    ...Hmo_Theme,
    palette: {
        ...Hmo_Theme.palette,
        primary: {
            main: patientThemePrimary,
        },
        error: {
            main: "#CF3A59",
        },
        sidebarColor: patientThemePrimary,
    },
    overrides: {
        ...Hmo_Theme.overrides,
        MuiTypography: Hmo_Theme.overrides.MuiTypography,
        MuiTextField: {
            root: {
                // margin: theme.spacing(1),
            },
        },
        MuiInputBase: {
            root: {
                background: "white",
                border: "1px solid #E2E1E4",
                borderRadius: "10px",
                padding: "5px",
                paddingLeft: "15px",
                '&[class*="MuiInputBase-multiline-"]': {
                    paddingTop: "5px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    paddingBottom: "5px",
                    "& textarea": {
                        padding: "6px 0 7px",
                    },
                },
            },
        },
        MuiInput: {
            input: {
                "&::placeholder": {
                    color: "#d7d7d7",
                    opacity: 1
                },
            },
            root: {
                paddingLeft: '10px',
            },
            underline: {
                "&:before": {
                    borderBottom: "none",
                    borderBottomStyle: "none",
                },
                "&.Mui-disabled:before": {
                    borderBottom: "none",
                    borderBottomStyle: "none",
                },
                "&:after": {
                    borderBottom: "none",
                },
                "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none",
                },
            },
        },
        MuiGrid: {
            root: {
                '&[class*="MuiGrid-container-"]': {
                    margin: "0",
                },
            },
        },
        MuiFormLabel: {
            root: {
                marginLeft: "0px",
                '& + [class*="MuiInput-formControl-"]': {
                    marginTop: "20px",
                },
            },
        },
        MuiTabs: {
            indicator: {
                backgroundColor: "#05343F",
            },
        },
        MuiContainer: {
            root: {
                paddingLeft: "2px",
                paddingRight: "2px",
                "@media (min-width: 600px)": {
                    paddingLeft: "10px",
                    paddingRight: "10px",
                },
            },
        },
        MuiButton: {
            root: {
                borderRadius: "10px",
                textTransform: 'unset',
                fontSize: "1rem",
                fontWeight: "normal",
                paddingTop: "6px",
                paddingRight: "28px",
                paddingBottom: "6px",
                paddingLeft: "28px",
            },
            sizeSmall: {
                paddingRight: "18px",
                paddingLeft: "18px",
                borderRadius: "6px",
            },
            contained: {
                // color: 'magenta !important'
            },
        },
        MuiSwitch: {
            sizeSmall: {
                padding: '3px',
                '& $track': {
                    borderRadius: '12px',
                }
            },
            switchBase: {},
            thumb: {},
            track: {},
            checked: {},
        },
        MuiAutocomplete: {
            inputRoot: {
                '&[class*="MuiAutocomplete-inputRoot"]': {
                    padding: '5px 10px'
                }
            }
        }
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "500px",
    },
    tab: {
        fontSize: 14,
        textTransform: "capitalize",
    },
    tabRoot: {
        borderBottom: "1px solid #E2E1E4",
        fontColor: "#E2E1E4",
        "& .MuiTabs-scroller": {},
        "& .MuiTabs-flexContainer": {
            maxHeight: "20px",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "#05343F",
            borderWidth: "1px",
        },
    },
    row: {
        padding: theme.spacing(0),
    },
}));

const PatientPage = (_props) => {
    const contactPersonRoleSelected = useIsContactPerson();
    const {patientId, programId} = useParams();
    const {
        data: patientData = {},
        isSuccess,
        isFetching
    } = useFetchPatientByIdQuery(patientId, {skip: !utils.isNumeric(patientId)});
    const {t} = useTranslation();
    const classes = useStyles();
    const {pathname} = useLocation();
    const programsPath = PATIENT_PAGE.pathWithId(patientId);

    const medicationPlanPath = PATIENT_PAGE_MEDICATION_PLAN.pathWithId(patientId);
    const anamnesePath = PATIENT_PAGE_ANAMNESE.pathWithId(patientId);
    const laboratoryPath = PATIENT_PAGE_LABORATORY.pathWithId(patientId);
    const myRiskPath = PATIENT_PAGE_MY_RISK.pathWithId(patientId);

    return isSuccess && !isFetching && _.isEmpty(patientData)
            ? <Navigate to={'/'}/>
            : <ThemeProvider theme={patientTheme}>
                <Sidebar/>
                <Flex container item column style={{margin: `0 10px 10px 65px`}}>
                    <Flex item container>
                        <Flex item container grow={5} padding={15} column>
                            {
                                !programId &&
                                <Tabs className={classes.tabRoot} value={pathname}>
                                    <Tab walkthrough-element="program-overview" className={classes.tab}
                                         label={t("patient.programOverview")}
                                         value={programsPath} to={programsPath} component={Link}/>
                                    <Tab className={classes.tab} label={t("patient.medicationPlan")}
                                         data-testid="medication-plan-tab-link"
                                         value={medicationPlanPath} to={medicationPlanPath} component={Link}/>
                                    <Tab className={classes.tab} label={t("patient.anamnase-questionaire")}
                                         data-testid="anamnese-tab-link"
                                         value={anamnesePath} to={anamnesePath} component={Link}/>
                                    <Tab className={classes.tab} label={t("patient.laboratory-history")}
                                         data-testid="laboratory-tab-link"
                                         value={laboratoryPath} to={laboratoryPath} component={Link}/>
                                    <Tab className={classes.tab} label={t("patient.my-risk")}
                                         data-testid="my-risk-tab-link"
                                         value={myRiskPath} to={myRiskPath} component={Link}/>
                                </Tabs>
                            }

                            {contactPersonRoleSelected && <ContactPersonConfirmationsOfConfidentialityModal />}
                            <Outlet/>
                        </Flex>
                        <Flex item container style={{maxWidth: 450, minWidth: 400}}>
                            <PatientSidebar/>
                        </Flex>
                    </Flex>
                </Flex>
            </ThemeProvider>;
};

PatientPage.propTypes = {};

export default PatientPage;
