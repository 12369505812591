import {
    HEMOGLOBIN, KREATININ, TOTAL_CHOLESTEROL, LDL_CHOLESTEROL, HDL_CHOLESTEROL, CRP, LEUKOZYTEN, MCV, RDW, THROMBOZYTEN,
    GFR
} from 'domain/Laboratory.model';

export const LaboratoryGroupList = (lang) => ([
    {id: HEMOGLOBIN, title: lang(HEMOGLOBIN), className: {[HEMOGLOBIN]: true}, width: 200},
    {id: KREATININ, title: lang(KREATININ), className: {[KREATININ]: true}},
    {id: TOTAL_CHOLESTEROL, title: lang(TOTAL_CHOLESTEROL), className: {[TOTAL_CHOLESTEROL]: true}},
    {id: LDL_CHOLESTEROL, title: lang(LDL_CHOLESTEROL), className: {[LDL_CHOLESTEROL]: true}},
    {id: HDL_CHOLESTEROL, title: lang(HDL_CHOLESTEROL), className: {[HDL_CHOLESTEROL]: true}},
    {id: CRP, title: lang(CRP), className: {[CRP]: true}, width: 300},
    {id: LEUKOZYTEN, title: lang(LEUKOZYTEN), className: {[LEUKOZYTEN]: true}},
    {id: MCV, title: lang(MCV), className: {[MCV]: true}},
    {id: RDW, title: lang(RDW), className: {[RDW]: true}},
    {id: THROMBOZYTEN, title: lang(THROMBOZYTEN), className: {[THROMBOZYTEN]: true}},
    {id: GFR, title: lang(GFR), className: {[GFR]: true}},
]);

export const FormInputData = [
    {
        id: HEMOGLOBIN,
        options: {
            mask: '00,0',
            radix: ',',
            defaultUnit: 'gperdl',
            units: [
                {
                    value: 'gperdl',
                    label: 'g/dl'
                },
                {
                    value: 'mmolperl',
                    label: 'mmol/l'
                }
            ]
        }
    },
    {
        id: KREATININ,
        options: {
            mask: '00,0',
            radix: ',',

            defaultUnit: 'mgperdl',
            units: [
                {
                    value: 'mgperdl',
                    label: 'mg/dl'
                },
                {
                    value: 'mikromolperll',
                    label: 'μmol/l'
                },
                {
                    value: 'nmolperlml',
                    label: 'nmol/ml'
                }
            ]
        }
    },
    {
        id: TOTAL_CHOLESTEROL,
        options: {
            mask: '0000,0',
            radix: ',',

            defaultUnit: 'mgperdl',
            units: [
                {
                    value: 'mgperdl',
                    label: 'mg/dl'
                },
                {
                    value: 'mmolperldl',
                    label: 'mmol/dl'
                }
            ]
        }

    },
    {
        id: LDL_CHOLESTEROL,
        options: {
            mask: '0000,0',
            radix: ',',

            defaultUnit: 'mgperdl',
            units: [
                {
                    value: 'mgperdl',
                    label: 'mg/dl'
                },
                {
                    value: 'mmolperldl',
                    label: 'mmol/dl'
                }
            ]
        }
    },
    {
        id: HDL_CHOLESTEROL,
        options: {
            mask: '0000,0',
            radix: ',',

            defaultUnit: 'mgperdl',
            units: [
                {
                    value: 'mgperdl',
                    label: 'mg/dl'
                },
                {
                    value: 'mmolperldl',
                    label: 'mmol/dl'
                }
            ]
        }
    },
    {
        id: CRP,
        options: {
            mask: '000,0',
            radix: ',',

            defaultUnit: 'mgperl',
            units: [
                {
                    value: 'mgperl',
                    label: 'mg/l'
                },
                {
                    value: 'nmolperll',
                    label: 'nmol/l'
                },
                {
                    value: 'nmolperml',
                    label: 'nmol/ml'
                }
            ]
        }
    },
    {
        id: LEUKOZYTEN,
        options: {
            mask: '00,0',
            radix: ',',
            defaultUnit: 'gperl',
            units: [
                {
                    value: 'gperl',
                    label: 'G/l'
                }
            ]
        }
    },
    {
        id: MCV,
        options: {
            mask: '00,0',
            radix: ',',
            defaultUnit: 'fperl',
            units: [
                {
                    value: 'fperl',
                    label: 'f/l'
                }
            ]
        }
    },
    {
        id: RDW,
        options: {
            mask: '00,0',
            radix: ',',
            defaultUnit: 'percent',
            units: [
                {
                    value: 'percent',
                    label: '%'
                }
            ]
        }
    },
    {
        id: THROMBOZYTEN,
        options: {
            mask: '00,0',
            radix: ',',
            defaultUnit: 'gperl',
            units: [
                {
                    value: 'gperl',
                    label: 'G/l'
                }
            ]
        }
    },
    {
        id: GFR,
        options: {
            mask: '00,0',
            radix: ',',
            defaultUnit: 'mlpermin',
            units: [
                {
                    value: 'mlpermin',
                    label: 'ml/min'
                }
            ]
        }
    }];

export const ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    width: 800,
    showTooltips: true,
    spanGaps: true,
    plugins: {
        legend: {
            display: false
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'month'
            },
            ticks: {
                source: 'auto',
                autoSkip: true
            }
        },
        y: {
            display: true,
            suggestedMax: 10,
            suggestedMin: 0,
            title: {
                display: true,
                text: ' '
            }
        }
    }
};

export const ChartData = ({lang}) => ([
    {index: 0, title: lang(HEMOGLOBIN)},
    {index: 1, title: lang(KREATININ)},
    {index: 2, title: lang(TOTAL_CHOLESTEROL)},
    {index: 3, title: lang(LDL_CHOLESTEROL)},
    {index: 4, title: lang(HDL_CHOLESTEROL)},
    {index: 5, title: lang(CRP)},
    {index: 6, title: lang(LEUKOZYTEN)},
    {index: 7, title: lang(MCV)},
    {index: 8, title: lang(RDW)},
    {index: 9, title: lang(THROMBOZYTEN)},
    {index: 10, title: lang(GFR)}
]);

export const ChartDataset = ({chartData, lang}) => [
    {
        label: lang(HEMOGLOBIN),
        data: chartData
            .filter(data => data.hemoglobin)
            .map(data => ({x: new Date(data.recordDate), y: data.hemoglobin})),
        borderColor: 'red',
        fill: false
    },
    {
        label: lang(KREATININ),
        data: chartData
            .filter(data => data.kreatinin)
            .map(data => ({x: new Date(data.recordDate), y: data.kreatinin})),
        borderColor: 'blue',
        fill: false
    },
    {
        label: lang(TOTAL_CHOLESTEROL),
        data: chartData
            .filter(data => data.totalcholesterol)
            .map(data => ({x: new Date(data.recordDate), y: data.totalcholesterol})),
        borderColor: 'green',
        fill: false
    },
    {
        label: lang(LDL_CHOLESTEROL),
        data: chartData
            .filter(data => data.ldlcholesterol)
            .map(data => ({x: new Date(data.recordDate), y: data.ldlcholesterol})),
        borderColor: 'orange',
        fill: false
    },
    {
        label: lang(HDL_CHOLESTEROL),
        data: chartData
            .filter(data => data.hdlcholesterol)
            .map(data => ({x: new Date(data.recordDate), y: data.hdlcholesterol})),
        borderColor: 'purple',
        fill: false
    },
    {
        label: lang(CRP),
        data: chartData
            .filter(data => data.crp)
            .map(data => ({x: new Date(data.recordDate), y: data.crp})),
        borderColor: 'black',
        fill: false
    },
    {
        label: lang(LEUKOZYTEN),
        data: chartData
            .filter(data => data.leukozyten)
            .map(data => ({x: new Date(data.recordDate), y: data.leukozyten})),
        borderColor: '#3980a0',
        fill: false
    },
    {
        label: lang(MCV),
        data: chartData
            .filter(data => data.mcv)
            .map(data => ({x: new Date(data.recordDate), y: data.mcv})),
        borderColor: '#5f7204',
        fill: false
    },
    {
        label: lang(RDW),
        data: chartData
            .filter(data => data.rdw)
            .map(data => ({x: new Date(data.recordDate), y: data.rdw})),
        borderColor: '#2900db',
        fill: false
    },
    {
        label: lang(THROMBOZYTEN),
        data: chartData
            .filter(data => data.thrombozyten)
            .map(data => ({x: new Date(data.recordDate), y: data.thrombozyten})),
        borderColor: '#a8206b',
        fill: false
    },
    {
        label: lang(GFR),
        data: chartData
            .filter(data => data.gfr)
            .map(data => ({x: new Date(data.recordDate), y: data.gfr})),
        borderColor: '#7b883b',
        fill: false
    }
];
