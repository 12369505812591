import HMOApi, {Laboratory} from "store/api/HMOApi";

const BASE_URL = '/laboratory';

export const LaboratoryApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        fetchLaboratoryByPatient : build.query({
            query: ({patientId}) => ({ url: `${BASE_URL}/patient/${patientId}` }),
            providesTags: [Laboratory],
        }),

        saveLaboratory: build.mutation({
            query: (data) => ({
                url: BASE_URL,
                method: 'POST',
                data,
            }),
            invalidatesTags: [Laboratory]
        }),

        updateLaboratoryValue: build.mutation({
            query: ({payload, id}) => ({
                url: `${BASE_URL}/${id}`,
                method: 'PUT',
                data: payload,
            }),
            invalidatesTags: [Laboratory]
        }),

        deleteLaboratory: build.mutation({
            query: (id) => ({
                url: `${BASE_URL}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [Laboratory],

        }),
    })
});

export const {
    useFetchLaboratoryByPatientQuery,
    useSaveLaboratoryMutation,
    useUpdateLaboratoryValueMutation,
    useDeleteLaboratoryMutation,
} = LaboratoryApi;
