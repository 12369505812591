import React, {Component} from 'react';
import {
    withStyles,
} from '@material-ui/core';
import Flex from 'components/grid/Flex';
import {LANDING, CASES, CLINICS, MANAGED_CARE, CARE_PROVIDERS} from 'routes/routes';
import {withTranslation} from 'react-i18next';
import Profile from 'components/profile/Profile';
import {sideBarIndex} from 'components/zindex/zIndex';
import {connect} from 'react-redux';
import connector from './Sidebar.connect';
import {isCaseManager, isClinic, isManager} from 'domain/User.model';
import SidebarListItem from './SidebarListItem';

export const styles = theme => {
    return {
        main: {
            backgroundColor: theme.palette.sidebarColor,
            color: 'red',
        }
    }
};

const Footer = () => {
    return <Flex container direction={'column'} alignItems={'center'}>
        <Profile/>
    </Flex>
};

export class Sidebar extends Component {

    render() {
        const {classes, user} = this.props;

        return (
                <Flex item container column className={classes.main} style={{
                    position: 'fixed',
                    height: '100vh',
                    top: 0,
                    left: 0,
                    width: 60,
                    maxWidth: 60,
                    zIndex: sideBarIndex,
                }}>
                    <SidebarListItem route={LANDING}/>
                    {
                        (isCaseManager(user) || isClinic(user)) &&
                        <SidebarListItem route={CASES}/>
                    }
                    {
                        isCaseManager(user) &&
                            <SidebarListItem route={CLINICS}/>
                    }
                    {
                        isCaseManager(user) &&
                            <SidebarListItem route={CARE_PROVIDERS}/>
                    }
                    {
                        isManager(user) &&
                        <SidebarListItem route={MANAGED_CARE}/>
                    }
                    <Flex item={'1'} container justifyContent={'center'} direction={'column'}/>
                    <Footer/>
                </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(Sidebar)));
