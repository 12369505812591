import _ from 'lodash';
import {logout} from 'scenes/Main.action';
import {LOGIN, BLOCK_APP_SCREEN, LOGOUT} from 'scenes/Main.action.types';
import {FETCH_CURRENT_USER} from 'scenes/user-manager/UserManager.action.types';
import {enqueueSnackbar} from "notistack";
import i18n from 'i18next';
import { REGISTER } from '../scenes/registration/Registration.action.types';

const HttpStatus = Object.freeze({
    OK: 200,
    BadRequest: 400,
    Unauthorized: 401,
    NotFound: 404,
    InternalServerError: 500,
});

export const interceptors = {
    request: [
        (getState, config) => {
            const mainReducer = getState.getState().mainReducer;
            const sourceAction = _.get(config, 'reduxSourceAction.type');
            const sourceRequest = _.get(config, 'reduxSourceAction.payload.request');
            const accessToken = _.get(mainReducer, 'accessToken');
            const selectedHeader = window.localStorage.getItem("selectedRole");
            if (accessToken && sourceAction !== LOGIN && sourceRequest?.withCredentials !== false) {
                config.headers['Authorization'] = 'Bearer ' + accessToken;
                if(selectedHeader) {
                    config.headers['Selected-Role'] = selectedHeader;
                }
            }
            return config;
        }
    ],
    response: [{
        success: function ({getState, dispatch, getSourceAction}, res) {
            if (res && res.data && res.data.hasOwnProperty('errors')) {
                return Promise.reject(res);
            } else {
                return res;
            }
        },
        error: function ({getState, dispatch, getSourceAction}, error) {
            const errorCode = _.get(error, 'response.status');
            const sourceAction = _.get(error, 'response.config.reduxSourceAction.type');

            const sessionTimeoutMessage = i18n.t('global.notifications.session-timeout-notification');
            const notAuthorizedMessage = i18n.t('global.notifications.not-authorized-notification');
            const badRequestMessage = i18n.t('global.notifications.bad-request-notification');
            const excludeUrls = ['api/anamnesis']
            const excludedUrl = (excludeUrls.some(url => error.request.responseURL.includes(url)));
            // TODO: source actions that are allowed should be in an array
            switch(errorCode) {
                case HttpStatus.Unauthorized:
                    if(sourceAction !== LOGIN && sourceAction !== LOGOUT && sourceAction !== FETCH_CURRENT_USER && sourceAction !== REGISTER) {
                        if(error.response.data === "EXPIRED_TOKEN") {
                            enqueueSnackbar(sessionTimeoutMessage, {variant: 'default', preventDuplicate: true, anchorOrigin: {horizontal: 'center', vertical: 'top'}});
                        } else {
                            enqueueSnackbar(notAuthorizedMessage, {variant: 'error', preventDuplicate: true, anchorOrigin: {horizontal: 'center', vertical: 'top'}});
                        }
                        dispatch({type: BLOCK_APP_SCREEN});
                        // Does not meter if multiple error responses end-up here creating multiple timeouts,
                        // we will logout only once since we are clearing all timeouts on logout
                        console.log('User is no longer Authenticated. Enforcing logout.');
                        setTimeout(() => {
                            dispatch(logout());
                        }, 3000)
                    }
                    break;
                case HttpStatus.BadRequest:
                    if(excludedUrl) {
                        break;
                    }
                    enqueueSnackbar(badRequestMessage, {variant: 'error', preventDuplicate: true});
                    break;
                default:
                    // not handling globally right now
                    // enqueueSnackbar(translatedGeneralError, {variant: 'error'});
            }
            return Promise.reject(error);
        }
    }]
};
