import _ from 'lodash';

export function chipCoefficient(anamneses) {
    if (isRelevantHeartDiseasePresent(anamneses)) {
        return heartDiseaseCoefficient(anamneses);
    } else {
        return nonHeartDiseaseCoefficient(anamneses);
    }
}

function nonHeartDiseaseCoefficient(anamneses) {
    if (anamneses?.chipMutationData?.length < 1) return 1;
    const sumVaf = _.sum(anamneses?.chipMutationData.map(mutation => Number(mutation.vaf)));
    if (anamneses?.chipMutationData?.length === 1) {
        return sumVaf < 2
            ? 1.3
            : sumVaf < 10
                ? 1.7
                : 2.4
    } else {
        return sumVaf < 2
            ? 1.5
            : sumVaf < 10
                ? 2
                : 2.8
    }
}

function heartDiseaseCoefficient(anamneses) {
    if (anamneses?.chipMutationData?.length < 1) return 1;
    const sumVaf = _.sum(anamneses?.chipMutationData.map(mutation => Number(mutation.vaf)));
    if (anamneses?.chipMutationData?.length === 1) {
        return sumVaf < 2
            ? 1.54
            : sumVaf < 10
                ? 2.3
                : 2.92
    } else {
        return sumVaf < 2
            ? 1.68
            : sumVaf < 10
                ? 2.34
                : 3.71
    }
}

function isRelevantHeartDiseasePresent(anamneses) {
    return anamneses.hasEnlargementOfTheAorta ||
        anamneses.coronaryArteryDisease ||
        anamneses.haveMiocardialHeartAttack ||
        anamneses.haveAtherosclerosis ||
        anamneses.haveStroke ||
        anamneses.haveTransientIschemicAttack ||
        anamneses.haveAtherosclerosisLegArteries ||
        anamneses.havePlumonaryArteryEmbolism ||
        anamneses.haveCardiomypathy ||
        anamneses.haveSufferFromHeartFailure ||
        anamneses.haveValvularHeartDisease ||
        anamneses.haveHeartValveTreatment ||
        anamneses.haveCardiacArrhythmia ||
        anamneses.haveCongenitalHeartDefect ||
        anamneses.havePacemakerOrDefibrillator ||
        anamneses.haveMyocardialBiopsy;
}
