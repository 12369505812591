import { Box, Grid, Card, CardHeader, IconButton, CardContent, TextField, MenuItem, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { IMaskInput } from "react-imask";

const geneValues = Object.freeze([
    "ABCB1",
    "ABCG2",
    "ADA",
    "ASXL1",
    "ASXL2",
    "ATRX",
    "BCOR",
    "BCORL1",
    "BRAF",
    "CALR",
    "CBL",
    "CDA",
    "CDKN2A",
    "CEBPA",
    "CSF3R",
    "CSFR1",
    "CSNK1A1",
    "CUX1",
    "DAXX",
    "DCK",
    "DCLK1",
    "DDX41",
    "DIS3",
    "DNMT3A",
    "ETNK1",
    "ETV6",
    "EZH2",
    "FAM5C",
    "FBXW7",
    "FLT3",
    "GATA1",
    "GATA2",
    "GATA3",
    "HNRNPK",
    "HRAS",
    "IDH1",
    "IDH2",
    "IL6R ",
    "IL7R",
    "JAK1",
    "JAK2",
    "JAK3",
    "KDM6A",
    "KIT",
    "KMT2A",
    "KRAS",
    "MIR-142",
    "MPL",
    "MYD88",
    "NF1",
    "NOTCH1",
    "NPM1",
    "NRAS",
    "NT5C2",
    "PDGFRA",
    "PDGFRB",
    "PHF6",
    "PIGA",
    "PPM1D",
    "PRPF8",
    "PTEN",
    "PTPN11",
    "PTPRT",
    "RAD21",
    "RUNX1",
    "SETBP1",
    "SF1",
    "SF3A1",
    "SF3B1",
    "SH2B3",
    "SMC1A",
    "SMC3",
    "SRSF2",
    "STAG2",
    "SUZ12",
    "TERC",
    "TERT",
    "TET2",
    "TP53",
    "U2AF1",
    "U2AF2",
    "UBA1",
    "WAC",
    "WT1",
    "ZEB2",
    "ZRSR2"
]);

const sampleLocations = Object.freeze([
    "PeripheralBlood",
    "BoneMarrow",
    "BoneMarrowLeftHip",
    "BoneMarrowRightHip",
 ]);

const getMonthList = (fromDate) => {
    const startDate = moment(fromDate);
    const endDate = moment();
    const monthDiff = endDate.diff(startDate, "months");
    // month diff does not include current month so we need to add 1
    return Array.from({ length: monthDiff + 1 }, (_, i) => startDate.clone().add(i, "months").toDate()).reverse();
};

const formatDateForDatesList = (locale) => (date) => {
    return [moment(date).locale(locale).format("MMMM YYYY"), moment(date).format("YYYY-MM")];
};

export const MutationModalForm = ({ onClose, onSubmit, rowToEdit }) => {
    const { t, i18n } = useTranslation();
    const formik = useFormik({
        initialValues: rowToEdit || {
            gene: "",
            dna: "",
            protein: "",
            vaf: "",
            date: "",
            exon: "",
            source: ""
        },
        validationSchema: Yup.object({
            gene: Yup.string().required(),
        }),
        onSubmit: (v) => onSubmit(v),
    });

    const monthList = getMonthList("2000-01-01").map(formatDateForDatesList(i18n.language));

    return (
        <Box mt={"40vh"}>
            <Grid container justifyContent="center">
                <Grid item xs={11} sm={11} md={9} lg={8} xl={6}>
                    <Card>
                        <CardHeader
                            title={t("anamnesis.AddMutationBtn")}
                            action={
                                <>
                                    <IconButton
                                        onClick={() => {
                                            onClose();
                                        }}
                                        size="small"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </>
                            }
                        />
                        <CardContent>
                            <form onSubmit={formik.handleSubmit}>
                                <Box display={"flex"} style={{ gap: "8px" }}>
                                    <Autocomplete
                                        id="combo-box-gene"
                                        freeSolo
                                        options={geneValues}
                                        value={formik.values.gene}
                                        filterOptions={(options) =>
                                            options.filter((option) =>
                                                option.toLowerCase().includes(formik.values.gene.toLowerCase())
                                            )
                                        }
                                        onInputChange={(e, value) => {
                                            formik.setFieldValue("gene", value);
                                        }}
                                        disableClearable
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    placeholder=""
                                                    InputLabelProps={{ shrink: true, type: "search" }}
                                                    label="Gen"
                                                    style={{ width: 140 }}
                                                    name="gene"
                                                    required
                                                    error={Boolean(formik.errors.gene)}
                                                    helperText={formik.errors.gene}
                                                />
                                            );
                                        }}
                                    />
                                    <TextField
                                        name="exon"
                                        style={{ flex: .3 }}
                                        label="Exon"
                                        InputLabelProps={{ shrink: true }}
                                        value={formik.values.exon}
                                        onChange={(value) => formik.setFieldValue("exon", value)}
                                        InputProps={{
                                            shrink: true,
                                            inputComponent: MaskedTimeInput,
                                        }}
                                    />
                                    <TextField
                                        name="dna"
                                        style={{ flex: 1 }}
                                        label="DNA"
                                        placeholder="z.B. c.234A>T"
                                        InputLabelProps={{ shrink: true }}
                                        value={formik.values.dna}
                                        onChange={formik.handleChange}
                                    />
                                    <TextField
                                        name="protein"
                                        style={{ flex: 1 }}
                                        label="Protein"
                                        placeholder="Z.B. p.Leu650Gln"
                                        InputLabelProps={{ shrink: true }}
                                        value={formik.values.protein}
                                        onChange={formik.handleChange}
                                    />
                                    <TextField
                                        name="vaf"
                                        style={{ flex: 1 }}
                                        label="VAF2 (%)"
                                        type={"number"}
                                        helperText={t("global.below-zero-conversion")}
                                        InputLabelProps={{ shrink: true }}
                                        value={formik.values.vaf}
                                        onChange={formik.handleChange}
                                    />
                                    <TextField
                                        name="source"
                                        style={{ flex: 1.5 }}
                                        label="Source"
                                        InputLabelProps={{ shrink: true }}
                                        value={formik.values.source}
                                        onChange={formik.handleChange}
                                        SelectProps={{
                                            MenuProps: {
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 500, // Set the maximum height for the dropdown
                                                    },
                                                },
                                            },
                                        }}
                                        select
                                    >
                                        {sampleLocations.map(value => (
                                            <MenuItem key={value} value={value}>
                                                {t(`anamnesis.chip.source.${value}`)}
                                            </MenuItem>))
                                        }
                                    </TextField>
                                    <TextField
                                        style={{ flex: 1.0 }}
                                        name="date"
                                        value={formik.values.date}
                                        onChange={formik.handleChange}
                                        label={t("global.date")}
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{
                                            name: "date",
                                            MenuProps: {
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 500, // Set the maximum height for the dropdown
                                                    },
                                                },
                                            },
                                        }}
                                        select
                                    >
                                        {monthList.map(([label, value]) => (
                                            <MenuItem key={value} value={value}>
                                                {label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                                <Box display="flex" justifyContent={"flex-end"} style={{ gap: "10px" }} mt={2}>
                                    <Button onClick={onClose} variant="contained">
                                        {t("global.cancel")}
                                    </Button>
                                    <Button type="submit" variant="contained" color="primary">
                                        {t("global.save")}
                                    </Button>
                                </Box>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export const MaskedTimeInput = (props) => {
    const { value, inputRef, onChange, className, name } = props;
    return (
        <div>
            <IMaskInput
                className={className}
                placeholder="01"
                mask={"00"}
                name={name}
                min={1}
                max={99}
                value={value}
                inputRef={inputRef}
                onAccept={(value, mask) => {
                    onChange(value);
                }}
            />
        </div>
    );
};
