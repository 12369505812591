import { getPatientList } from "./ManagedCare.action";

export default {
    mapStateToProps: (state) => {
        return {
            configuration: state.caseReducer.configuration,
            user: state.mainReducer.user,
            userDetails: state.mainReducer.userDetails,
            programNamesFromPatients: state.managedCareReducer.programNamesFromPatients,
            patients: state.managedCareReducer.patients,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            getPatientList: () => dispatch(getPatientList()),
        };
    }
};
