import {
    Box,
    useTheme,
    TextField,
    Radio,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Button,
    Select,
    Switch
} from "@material-ui/core";
import DatePicker from "components/calendar/DatePicker";
import {useTranslation} from "react-i18next";
import {Field} from "./ProgramStepRendererCommonComponents";
import {EXACT, RECURRING, DEPENDENT_ON_PREVIOUS_TASK} from 'domain/ProgramStepDateType.model';
import {JUST_ONCE, EVERY_TIME} from 'domain/DependentCycle.model';
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import {anyPropertyChanged} from 'utils/Utils';
import {PossibleTimePeriods} from "domain/Program.model";
import moment from 'moment';
import {MaskedTimeInput} from "scenes/patient/program-view/ProgramStepRenderer/MaskedTimeInput";
import {useState} from 'react';

export const ProgramStepRendererCreationMode = (props) => {
    const {cancelStepHandler, saveStep, otherStepsInProgram} = props;
    const [step, setStep] = useState(props.step);
    const [time, setTime] = useState(null);
    const onChange = event => {
        setStep({...step, [event.target.name]: event.target.value});
    };
    const isChanged = anyPropertyChanged(step, props.step);
    const {t} = useTranslation();
    const theme = useTheme();
    return (
            <Box position={"relative"} display="flex" flexDirection="column" style={{gap: "10px", padding: "10px"}}>
                <>
                    <Box position={"absolute"} right={"20px"}>
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                            <NotificationsNoneIcon
                                    style={{
                                        color: step.reminderIsOn
                                                ? theme.palette.warning.main
                                                : theme.palette.grey[500],
                                        marginRight: theme.spacing(1)
                                    }}
                            />
                            <span style={{marginRight: theme.spacing(2)}}>{t('global.remind-patient')}</span>
                            <span style={{fontWeight: step.reminderIsOn ? '' : 'bold'}}>{t('global.no')}</span>
                            <Switch
                                    checked={step.reminderIsOn}
                                    color="primary"
                                    onChange={(event, value) => setStep({...step, reminderIsOn: value})}
                                    name="reminderIsOn"
                            />
                            <span style={{fontWeight: step.reminderIsOn ? 'bold' : ''}}>{t('global.yes')}</span>
                        </Box>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} marginLeft={"-10px"}>
                            <Radio
                                    onChange={() => {
                                        setStep({
                                            ...step,
                                            dateType: EXACT,
                                            dateTimeSpan: null,
                                            dateTimeSpanValue: null,
                                        });
                                    }}
                                    name="dateType"
                                    value={step.dateType}
                                    checked={step.dateType === EXACT}
                                    color="primary"
                            />
                            <div>{t('program.exactDate')}</div>
                            {step.dateType === EXACT && (
                                    <Box marginLeft={'14px'} display={"flex"} flexDirection={"row"}>
                                        <DatePicker
                                                {...{
                                                    smallLabel: true,
                                                    style: {flex: "0 1 130px"},
                                                    value: step.date,
                                                    name: "date",
                                                    onChange: (value) => {
                                                        const date = moment(value).set({hour: '00', minute: '00'});
                                                        setStep({
                                                            ...step,
                                                            date
                                                        })
                                                    },
                                                }}
                                        />
                                        <TextField
                                                name="time"
                                                onChange={(time) => {
                                                    setTime(time);
                                                }}
                                                label={t("global.time")}
                                                value={time}
                                                InputProps={{
                                                    shrink: true,
                                                    inputComponent: MaskedTimeInput,
                                                }}
                                        />
                                    </Box>)}
                        </Box>
                        <Box
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                                marginTop={"10px"}
                                marginLeft={"-10px"}
                        >
                            <Radio
                                    onChange={() => {
                                        setStep({
                                            ...step,
                                            dateTimeSpan: PossibleTimePeriods.Days,
                                            dateType: RECURRING,
                                            dateTimeSpanValue: 1,
                                        });
                                    }}
                                    name="dateType"
                                    value={step.dateType}
                                    checked={step.dateType === RECURRING}
                                    color="primary"
                            />{" "}
                            <span style={{marginRight: "10px"}}>{t('program.every')}</span>{" "}
                            <TextField
                                    disabled={step.dateType !== RECURRING}
                                    type="number"
                                    name="dateTimeSpanValue"
                                    onChange={onChange}
                                    value={step.dateTimeSpanValue ?? 1}
                                    style={{width: "40px", marginRight: "10px"}}
                            />
                            <TextField
                                    disabled={step.dateType !== RECURRING}
                                    onChange={onChange}
                                    name="dateTimeSpan"
                                    value={step.dateTimeSpan ?? PossibleTimePeriods.Days}
                                    select
                                    style={{marginRight: "10px"}}
                            >
                                {Object.entries(PossibleTimePeriods).map(([label, value]) => (
                                        <MenuItem key={value} value={value}>
                                            {t(`timeSpans.${label.toLowerCase()}`)}
                                        </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        <Box display={"flex"}
                             flexDirection={"row"}
                             alignItems={"center"}
                             marginLeft={"-10px"}
                             marginTop="10px">
                            <Radio
                                    name="dateType"
                                    onChange={() => {
                                        setStep({
                                            ...step,
                                            dateTimeSpan: PossibleTimePeriods.Days,
                                            dateType: DEPENDENT_ON_PREVIOUS_TASK,
                                            dateTimeSpanValue: 1,
                                        });
                                        // TODO LM: should setup time period default and time period default value
                                    }}
                                    checked={step.dateType === DEPENDENT_ON_PREVIOUS_TASK}
                                    value={step.dateType}
                                    color="primary"
                            />
                            <TextField
                                    disabled={step.dateType !== DEPENDENT_ON_PREVIOUS_TASK}
                                    type="number"
                                    name="dateTimeSpanValue"
                                    onChange={onChange}
                                    value={step.dateTimeSpanValue ?? 1}
                                    style={{width: "40px", marginRight: "10px"}}
                            />
                            <TextField
                                    disabled={step.dateType !== DEPENDENT_ON_PREVIOUS_TASK}
                                    onChange={onChange}
                                    name="dateTimeSpan"
                                    value={step.dateTimeSpan ?? PossibleTimePeriods.Days}
                                    select
                                    style={{marginRight: "10px"}}
                            >
                                {Object.entries(PossibleTimePeriods).map(([label, value]) => (
                                        <MenuItem key={value} value={value}>
                                            {t(`timeSpans.${label.toLowerCase()}`)}
                                        </MenuItem>
                                ))}
                            </TextField>
                            <div>{t('program.afterTask')}</div>
                            <Select value={step.dependsOnTaskId ?? ''}
                                    name="dependsOnTaskId"
                                    onChange={onChange}
                                    style={{marginLeft: "10px"}}>
                                {
                                    otherStepsInProgram.map((_step) =>
                                            <MenuItem value={_step.id} disabled={_step.isCompleted}>
                                                <span style={{fontWeight: 'bold'}}>{_step.position}:</span>
                                                {` ${_step.description || ''}`}
                                            </MenuItem>
                                    )
                                }
                            </Select>
                            {
                                otherStepsInProgram?.find(item => item.id === step.dependsOnTaskId)?.dateType === RECURRING &&
                                <Select value={step.dependentCycle}
                                        name="dependentCycle"
                                        onChange={onChange}
                                        style={{marginLeft: "10px"}}>
                                    <MenuItem value={JUST_ONCE}>
                                        {t('global.JUST_ONCE')}
                                    </MenuItem>
                                    <MenuItem value={EVERY_TIME}>
                                        {t('global.EVERY_TIME')}
                                    </MenuItem>
                                </Select>
                            }

                        </Box>
                    </Box>
                </>
                <Box>
                    <Field {...{
                        isEditable: true,
                        value: step.description,
                        label: t("global.description"),
                        name: "description",
                        onChange,
                    }} />
                </Box>
                <Field {...{
                    isEditable: true,
                    value: step.place,
                    label: t("global.wo"),
                    name: "place",
                    onChange,
                }} />
                <Box>
                    <Field {...{
                        isEditable: true,
                        value: step.comment,
                        label: t("global.comment"),
                        name: "comment",
                        onChange,
                    }} />
                </Box>
                <FormControlLabel
                        control={
                            <Checkbox
                                    checked={step.informResultPreference}
                                    onChange={() => {
                                        setStep({
                                            ...step,
                                            informResultPreference: !Boolean(step.informResultPreference)
                                        });
                                    }}
                                    color="primary"
                            />
                        }
                        label={t('global.ask-patient-about-method-of-informing-examination-result')}
                />

                <Box justifyContent={"flex-end"} display={"flex"} flex={"0 1 auto"} sx={{paddingTop: 10}}>
                    <Button
                            variant={"contained"}
                            size="small"
                            style={{marginRight: 5}}
                            onClick={cancelStepHandler}
                    >
                        {t("global.cancel")}
                    </Button>
                    <Button
                            variant={"contained"}
                            size="small"
                            color={"primary"}
                            onClick={() => {
                                //TODO LM: creationFinished is no longer needed, get rid of it
                                let date = step.date;
                                if (time) {
                                    const [hours, minutes] = time.split(":");
                                    const paddedHours = hours ? hours.padEnd(2, "0") : "00";
                                    const paddedMinutes = minutes ? minutes.padEnd(2, "0") : "00";
                                    date = moment(date).set({hour: paddedHours, minute: paddedMinutes});
                                }
                                saveStep({...step, date, creationFinished: true});
                            }}
                            disabled={!isChanged}
                    >
                        {t("global.save")}
                    </Button>
                </Box>
            </Box>
    );
};
