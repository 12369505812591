import {Pie, Cell, PieChart, Legend} from "recharts";
import {useTranslation} from "react-i18next";
import {hematologyRiskProfileMapper, missingHematoAnswers} from "./calculators/HematologyRiskProfile.mapper";
import {hematologyRiskCalculator, scoreToPercentage} from "./calculators/hematologyRiskCalculator";
import {Paper, Typography} from "@material-ui/core";
import Accordion from "components/grid/Accordion";
import {useIsDebugView} from "utils/useDebugView";
import Flex from 'components/grid/Flex';

const ScoreType = {
    LOW: "low",
    INTERMEDIATE: "intermediate",
    HIGH: "high",
};

const riskColor = {
    low: "#FFD760",
    intermediate: "#FFA013",
    high: "#AC0000",
};

const CHRSscoreToScoreType = (score) => {
    if (score <= 9.5) {
        return ScoreType.LOW;
    } else if (score > 9.5 && score < 12.5) {
        return ScoreType.INTERMEDIATE;
    } else {
        return ScoreType.HIGH;
    }
};

const CHRSscoreTypeToTranslationString = (scoreType) => {
    return ["my-risk", "score-label", scoreType].join(".");
};


function RiskPieChart({scoreColor, scoreLabel, size, riskPercentage = 0, isEmpty}) {
    return (
            <PieChart width={size} height={size}>
                <text
                        x={"50%"}
                        y={"50%"}
                        fill={isEmpty ? '#d7d7d7' : scoreColor}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            fontSize: size / (scoreLabel.length >= 10 ? 10 : scoreLabel.length >= 7 ? 6 : 4),
                            fontWeight: "bold",
                        }}
                >
                    {scoreLabel}
                </text>
                <Pie
                        dataKey="percent"
                        isAnimationActive={false}
                        innerRadius="80%"
                        outerRadius="100%"
                        startAngle={-270}
                        paddingAngle={0}
                        data={[{percent: 100 - riskPercentage}, {percent: riskPercentage}]}
                >
                    <Cell id={"empty"} fill={"#f2f2f2"}/>
                    <Cell id={"fullRiskPercent"} fill={isEmpty ? '#f2f2f2' : scoreColor}/>

                </Pie>
            </PieChart>
    );
}

export default function HematologyRisk({patientData, mutationData, laboratoryData}) {
    const {t} = useTranslation();
    const debugView = useIsDebugView();
    const riskProfileInput = hematologyRiskProfileMapper(patientData, laboratoryData, mutationData);
    const risk = hematologyRiskCalculator(riskProfileInput);
    const missingAnswers = missingHematoAnswers({patientData, mutationData, laboratoryData})
    const scoreType = CHRSscoreToScoreType(risk);
    const scoreTitle = scoreType === ScoreType.LOW
            ? t("my-risk.title-LOW") : scoreType === ScoreType.INTERMEDIATE
                    ? t("my-risk.title-INTERMEDIATE") : t("my-risk.title-HIGH")
    const scoreColor = riskColor[scoreType];
    const isEmpty = missingAnswers.length > 0
    const scoreLabel = isEmpty ? t('my-risk.not-verifiable') : t(CHRSscoreTypeToTranslationString(scoreType));
    const riskPercentage = scoreToPercentage(risk, 90);

    return <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
        <Typography style={{fontSize: 24, fontWeight: "bold", textAlign: "center"}}>
            {t("my-risk.hematology")}
        </Typography>
        <RiskPieChart {...{size: 340, scoreColor, scoreLabel, riskPercentage, isEmpty}}/>
        <Flex container item center>
            <Typography style={{fontSize: 12}}>Clonal Hematopoiesis Risk Score (CHRS)</Typography>
        </Flex>
        <Typography variant={"h6"} style={{margin: "20px 0px"}}>
            { isEmpty ? t("my-risk.missing-answers") : scoreTitle }
        </Typography>

        {!!missingAnswers.length &&
                <Paper style={{padding: 10, minWidth: '100%'}} elevation={4}>
                    {
                            missingAnswers.filter(answer =>
                                    ['my-risk.missing-mcv', 'my-risk.missing-rdw'].includes(answer)
                            ).length > 0 &&
                            <>
                                <Typography style={{margin: "5px 0px", fontWeight: "bold", fontSize: 16}}>
                                    {t("patient.laboratory-history")}
                                </Typography>
                                <ul>
                                    {missingAnswers.includes('my-risk.missing-mcv') &&
                                            <li>
                                                <Typography style={{fontWeight: "bold"}}>
                                                    {t('my-risk.missing-mcv')}
                                                </Typography>
                                            </li>
                                    }
                                    {missingAnswers.includes('my-risk.missing-rdw') &&
                                            <li>
                                                <Typography style={{fontWeight: "bold"}}>
                                                    {t('my-risk.missing-rdw')}
                                                </Typography>
                                            </li>
                                    }
                                </ul>
                            </>
                    }
                    {
                            missingAnswers.includes('my-risk.missing-chip') &&
                            <>
                                <Typography style={{margin: "5px 0px", fontWeight: "bold", fontSize: 16}}>
                                    {t("my-risk.chip-mutation")}
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography style={{fontWeight: "bold"}}>
                                            {t('my-risk.missing-chip')}
                                        </Typography>
                                    </li>
                                </ul>
                            </>
                    }
                    {
                            missingAnswers.includes('my-risk.missing-birthday') &&
                            <>
                                <Typography style={{margin: "5px 0px", fontWeight: "bold", fontSize: 16}}>
                                    {t("my-risk.missing-birthday")}
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography style={{fontWeight: "bold"}}>
                                            {t('my-risk.missing-birthday')}
                                        </Typography>
                                    </li>
                                </ul>
                            </>
                    }
                </Paper>
        }

        {debugView && (
                <div style={{width: "100%"}}>
                    <Accordion openByDefault={true} title={`Risk Profile score: ${risk} or ${riskPercentage}%`}>
                        {/* {JSON.stringify(mutationData) + "\n"} */}
                        <div>
                            <div>{`Low: score <= 9.5`}</div>
                            <div>{`Intermediate risk: >9.5 and <12.5`}</div>
                            <div>{`High risk: >= 12.5`}</div>
                            <div>Input Data:</div>
                            <pre>
                                {JSON.stringify(riskProfileInput, '\n', 2)}
                            </pre>
                        </div>
                    </Accordion>
                </div>
        )}
    </div>;
}
