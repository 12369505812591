import {Box, useTheme} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Field, LabeledValue} from "scenes/patient/program-view/ProgramStepRenderer/ProgramStepRendererCommonComponents";
import {EXACT, RECURRING, DEPENDENT_ON_PREVIOUS_TASK} from 'domain/ProgramStepDateType.model';
import {getTranslationArgsForTimeSpan} from "scenes/patient/program-view/ProgramStepRenderer/utils/getTranslationArgsForTimeSpan";
import {pipe} from "lodash/fp";
import warningIcon from "assets/img/high-voltage.png";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import CheckIcon from '@mui/icons-material/Check';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

function getTemplateDateLabelText(translation) {
    return ({dateType, dateTimeSpan, dateTimeSpanValue, position, dependencyIsMissing}) => {
        const text = (() => {
            if (dependencyIsMissing) {
                return translation("program.calculatedDate");
            }
            switch (dateType) {
                case EXACT:
                    return translation("global.fixed-date");
                case RECURRING:
                    return translation("program.recurring-cycle-template-view-mode-text", {
                        dateTimeSpan: translation(getTranslationArgsForTimeSpan(dateTimeSpan, dateTimeSpanValue)),
                        dateTimeSpanValue: dateTimeSpanValue,
                    });
                case DEPENDENT_ON_PREVIOUS_TASK:
                    return translation("program.after-previous-calculated-date", {
                        dateTimeSpan: translation(getTranslationArgsForTimeSpan(dateTimeSpan, dateTimeSpanValue)),
                        dateTimeSpanValue: dateTimeSpanValue,
                        position,
                    });
                default:
                    return "";
            }
        })();
        return {dateType, text, dependencyIsMissing};
    };
}

function getTemplateDateLabelElement(theme) {
    return ({dateType, text, dependencyIsMissing}) => {
        return (
                <Box position={"relative"} width={"220px"}>
                <span
                        style={{
                            color: dependencyIsMissing
                                    ? theme.palette.error.main
                                    : dateType === EXACT
                                            ? ""
                                            : theme.palette.info.main,
                            fontStyle: "italic",
                        }}
                >
                    {text}
                </span>
                    {dependencyIsMissing && (
                            <img alt={''} style={{position: "absolute", right: 0, bottom: 2}} width="20px"
                                 src={warningIcon}/>
                    )}
                </Box>
        );
    };
}

export const ProgramTemplateStepRendererViewMode = (props) => {
    const {step, dependencyIsMissing, dependsOnPosition} = props;
    const {t} = useTranslation();
    const theme = useTheme();

    const getDateLabelValueTemplate = pipe(getTemplateDateLabelText(t), getTemplateDateLabelElement(theme));

    const dateLabelValueTemplate = getDateLabelValueTemplate({
        dateType: step.dateType,
        dateTimeSpan: step.dateTimeSpan,
        dateTimeSpanValue: step.dateTimeSpanValue,
        position: dependsOnPosition,
        dependencyIsMissing,
    });

    const onChange = ()=>{};

    return <Box display="flex" style={{opacity: step.paused ? .6 : 1}} position={'relative'} flexDirection={"column"}>
        {step.paused && <div style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            pointerEvents: 'none',
            transform: 'scale(5) translateX(-25%)',
            color: 'gray'
        }}><PauseCircleOutlineIcon/></div>}
        <Box position={'absolute'} right={theme.spacing(1)} top={theme.spacing(1)}>
            {
                step.reminderIsOn &&
                <Box position={'relative'}>
                    <NotificationsNoneIcon style={{color: theme.palette.warning.main}}/>
                </Box>
            }
            {
                step.specialRequestIsOn &&
                <Box position={'relative'}>
                    <MailOutlineIcon style={{color: theme.palette.warning.main}}/>
                </Box>
            }
        </Box>
        <Box display="flex" position={"relative"} padding={"10px"}>
            <Box width="78%">
                <Box width={"100%"} mb={1}>
                    <LabeledValue label={t("global.date")} value={dateLabelValueTemplate}/>
                </Box>

                <Box display="flex">
                    <Box width="60%" marginRight="20px">
                        <Field
                                {...{
                                    isEditable: false,
                                    value: step?.description,
                                    label: t("global.description"),
                                    name: "description",
                                    onChange,
                                    boldValue: true,
                                }}
                        />
                    </Box>
                    <Box width="40%">
                        <Field
                                {...{
                                    isEditable: false,
                                    value: step.place,
                                    label: t("global.wo"),
                                    name: "place",
                                    onChange,
                                }}
                        />
                    </Box>
                </Box>
                <Box>
                    <Field
                            {...{
                                isEditable: false,
                                value: step.comment,
                                label: t("global.comment"),
                                name: "comment",
                                onChange,
                            }}
                    />
                </Box>
            </Box>
        </Box>
    </Box>;
};
