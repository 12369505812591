import { useIsCaseManager } from "./useIsCaseManager";
import { useEffect, useState } from "react";

export function useIsDebugView() {
  const [isDebugView, setIsDebugView] = useState(false);
  const isCaseManager = useIsCaseManager();

  useEffect(() => {
    const debugView = localStorage.getItem('debugView');
    if (debugView === 'true' && isCaseManager) {
      setIsDebugView(true);
    }
  }, [isCaseManager]);

  return isDebugView;
}
