import {makeStyles, useTheme} from "@material-ui/core/styles";
import Flex from 'components/grid/Flex';
import {Button, Dialog, IconButton, MenuItem, Tooltip, Typography} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import DefaultTextField from "components/hmo-textfield/DefaultTextField";
import React, {useEffect, useState} from "react";
import QuillEditor from "components/quill-editor/QuillEditor";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import {
    useGetEmailTemplateQuery,
    useGenerateEmailPreviewQuery,
    useCreateEmailTemplateMutation,
    useUpdateEmailTemplateMutation,
    useDeleteEmailTemplateMutation
} from 'scenes/EmailTemplateApi';
import {useGetCareProviderPatientAccessesQuery} from "../patient/CareProviderApi";
import {useEnqueueError, useEnqueueSuccess} from "components/alert/SnackbarHooks";
import {debounce} from "lodash";
import {useSelector} from "react-redux";
import caseReducer from "../case/Case.reducer";
import _ from 'lodash';
import utils from 'utils/Utils';
import Warning from "@material-ui/icons/Warning";
import {CloseOutlined as CloseIcon} from "@material-ui/icons";
import {rainForest} from "../../components/colors/Colors";

const useStyles = makeStyles((theme) => ({
    wysiwygContainer: {
        paddingRight: 7,
        "& .ql-tooltip": {
            zIndex: 999999,
        },
    },
    dialogPaper: {
        minWidth: '80vw',
        width: '80vw'
    }
}));

export const EmailTemplateManagerWithButton = (props) => {
    const {emailTemplateEntityType, entityId, additionalFilters, emailTypeFilter = () => true, warning, buttonStyle} = props;
    const classes = useStyles();
    const theme = useTheme();
    const {t: translate} = useTranslation();
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen(!open);
    return <>
        <Flex item container grow={0} alignItems={'center'}>
            <Button variant={'outlined'} onClick={toggleOpen}
                    style={{color: warning ? "orange" : theme.palette.primary.main, ...buttonStyle}}>
                {translate('email-manager.email-template')}
            </Button>
            {
                    warning &&
                    <Tooltip title={warning}>
                        <Warning style={{fill: 'orange', marginLeft: 5}}/>
                    </Tooltip>
            }
        </Flex>
        <Dialog maxWidth={'xl'} open={open} onClose={toggleOpen} classes={{paper: classes.dialogPaper}}>
            <Flex item container column>
                <Flex item container grow={0} justifyContent={'flex-end'}>
                    <IconButton
                            disableRipple={true}
                            onClick={toggleOpen}
                    >
                        <CloseIcon style={{color: rainForest}}/>
                    </IconButton>

                </Flex>

                <EmailTemplateManager
                        {...{
                            emailTemplateEntityType,
                            entityId,
                            emailTypeFilter,
                            additionalFilters
                        }}
                />

            </Flex>
        </Dialog>
    </>
}

const Preview = ({togglePreview, fetchProps, translate}) => {
    const {data} = useGenerateEmailPreviewQuery(fetchProps);
    const emailAsHtmlString = data?.emailContent || data?.emailTemplate?.content;
    return (
            <Dialog open={true} maxWidth={emailAsHtmlString ? 'md' : 'sm'} fullWidth onClose={togglePreview}>
                <Typography style={{paddingLeft: 10, marginTop: 10, fontWeight: 'bold'}}>
                    {translate('global.preview')}:
                </Typography>
                <Flex item container padding={10} style={{border: '1px solid grey', borderRadius: 6, margin: 10}}>
                    {/*LM: At this point we trust the Case managers ain't hackin' since they see and can change everything anyway*/}
                    {
                            emailAsHtmlString &&
                            <div dangerouslySetInnerHTML={{__html: emailAsHtmlString}}/>
                    }
                    {
                            !emailAsHtmlString &&
                            <Typography>{translate('email-manager.no-preview')}</Typography>
                    }
                </Flex>
                {
                    emailAsHtmlString && !data?.emailSendingDto?.emailSignature &&
                        <Typography style={{padding: 10, color: 'orange'}}>
                            {translate('email-manager.no-signature')}
                        </Typography>
                }

            </Dialog>
    );
};

const EmailTemplateManager = ({emailTemplateEntityType, entityId, additionalFilters, emailTypeFilter = () => true}) => {
    const classes = useStyles();
    const {t: translate} = useTranslation();
    const configuration = useSelector(state => state.caseReducer.configuration);
    const emailTypes = configuration?.emailTemplateTypes ? Object.keys(configuration?.emailTemplateTypes)
                    .filter(emailTypeFilter)
                    .sort()
                    .reduce((acc, key) => {
                        acc[key] = configuration?.emailTemplateTypes[key];
                        return acc;
                    }, {})
            : {};
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const toggleDeleteDialog = () => setIsDeleteDialogOpen(!isDeleteDialogOpen);

    const [content, setContent] = useState('');
    const onContentChange = value => {
        setContent(value);
    }
    const [subject, setSubject] = useState('');
    const onSubjectChange = event => {
        setSubject(event.target.value);
    }
    const [quillJsForceUpdateId, setQuillJsForceUpdateId] = useState('');

    const [language, setLanguage] = useState('de');
    const [emailTemplateType, setEmailTemplateType] = useState('');
    useEffect(() => {
        if (!emailTemplateType && !_.isEmpty(emailTypes)) {
            setEmailTemplateType(Object.keys(emailTypes)[0])
        }
    }, [emailTypes]);


    const {
        data: originalEmailTemplate,
        refetch: refreshEmailTemplate,
        isFetching: isTemplateFetching
    } = useGetEmailTemplateQuery({
        emailTemplateEntityType,
        entityId,
        language,
        emailTemplateType
    }, {refetchOnMountOrArgChange: true, skip: !emailTemplateType || !entityId});
    useEffect(() => {
        if (originalEmailTemplate !== null) {
            setContent(originalEmailTemplate?.content);
            setSubject(originalEmailTemplate?.subject);
            setQuillJsForceUpdateId(utils.uuid);
        }
    }, [originalEmailTemplate]);
    const isTemplateChanged = originalEmailTemplate?.id && ((originalEmailTemplate?.content + originalEmailTemplate?.subject) !== (content + subject));
    const isTemplateAvailable = originalEmailTemplate?.id;

    const [createEmailTemplate] = useCreateEmailTemplateMutation();
    const create = () => createEmailTemplate({
        emailTemplateEntityType,
        entityId,
        emailTemplateType,
        content,
        subject,
        language
    }).then(() => {
        enqueueSuccess();
        refreshEmailTemplate();
    }).catch(() => enqueueError());


    const [updateEmailTemplate] = useUpdateEmailTemplateMutation();
    const save = () => {
        updateEmailTemplate({
            id: originalEmailTemplate?.id, subject, content
        }).then(() => {
            enqueueSuccess();
            refreshEmailTemplate();
        }).catch(() => enqueueError());
    };
    const cancel = () => {
        setContent(originalEmailTemplate?.content);
        setSubject(originalEmailTemplate?.subject);
        setQuillJsForceUpdateId(utils.uuid);
    }

    const [deleteEmailTemplate] = useDeleteEmailTemplateMutation();
    const onDeleteEmailTemplate = () => {
        deleteEmailTemplate(originalEmailTemplate?.id).unwrap()
                .then(() => enqueueSuccess())
                .catch(() => enqueueError())
                .finally(() => setIsDeleteDialogOpen(false))
    }

    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const togglePreview = () => {
        setIsPreviewOpen(!isPreviewOpen);
    };

    return <Flex item container column padding={20}>
        <Flex item grow={0} container style={{marginBottom: 10}}>
            <Flex item container spacing={5}>
                <Button variant='contained' onClick={save} color={'secondary'}
                        disabled={!isTemplateChanged}>{translate('global.save')}</Button>
                <Button variant='outlined' onClick={cancel}
                        disabled={!isTemplateChanged}>{translate('global.cancel')}</Button>
            </Flex>
            <Flex item container spacing={5} justifyContent={'flex-end'}>
                {
                        <Button disabled={!entityId} variant='contained' onClick={togglePreview}
                                color={'primary'}>{translate('global.preview')}</Button>
                }
                <Button variant='outlined' onClick={toggleDeleteDialog}>{translate('global.delete')}</Button>
            </Flex>

        </Flex>
        <Flex item container>
            {
                    additionalFilters && additionalFilters
            }
            <DefaultTextField
                    name={"type"}
                    onChange={event => setEmailTemplateType(event.target.value)}
                    value={emailTemplateType}
                    label={translate('global.type')}
                    select
            >
                {
                    Object.keys(emailTypes).map(_type =>
                            <MenuItem value={_type} key={_type}>
                                {translate(`email-manager.${_type}`)}
                            </MenuItem>)
                }
            </DefaultTextField>
            <DefaultTextField
                    name={"language"}
                    onChange={event => setLanguage(event.target.value)}
                    value={language}
                    label={translate('global.language')}
                    select
            >
                <MenuItem value={"de"}>{translate(`global.languages.de.label`)}</MenuItem>
            </DefaultTextField>
        </Flex>
        {
                isTemplateAvailable && !isTemplateFetching &&
                <Flex item container style={{marginBottom: 20}}>
                    <DefaultTextField
                            name={"subject"}
                            onChange={onSubjectChange}
                            value={subject}
                            fullWidth
                            label={translate('global.subject')}
                    />
                </Flex>
        }
        <Flex item container style={{minHeight: '60vh', width: '100%'}}>
            {
                    isTemplateAvailable && !isTemplateFetching &&
                    <Flex item container className={classes.wysiwygContainer}>
                        <QuillEditor
                                updateId={quillJsForceUpdateId}
                                containerStyle={{}}
                                toolbar={{
                                    container: [
                                        [{"placeholder": [...emailTypes[emailTemplateType]]}],
                                        [{header: [1, 2, 3, false]}],
                                        ["bold", "italic", "underline"],
                                        [{list: "ordered"}, {list: "bullet"}],
                                        [{align: []}],
                                        [{color: []}, "link"],
                                    ],
                                    handlers: {
                                        "placeholder": function (value) {
                                            if (value) {
                                                const cursorPosition = this.quill.getSelection().index;
                                                this.quill.insertText(cursorPosition, `[${value}]`);
                                                this.quill.setSelection(cursorPosition + value.length + 2);
                                                if (this.quill.root.innerHTML) {
                                                    setContent(this.quill.root.innerHTML);
                                                }
                                            }
                                        }
                                    }
                                }}
                                customInitializer={(editor, outerRef) => {
                                    const placeholderPickerItems = Array.prototype.slice
                                            .call(outerRef.current.querySelectorAll('.ql-placeholder .ql-picker-item'));
                                    placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);
                                    outerRef.current.querySelector('.ql-placeholder .ql-picker-label').innerHTML = 'Insert placeholder';
                                }}
                                handleTextChange={onContentChange}
                                text={content}
                        />
                    </Flex>
            }
            {
                    !isTemplateAvailable &&
                    <Flex item container column padding={20}>
                        <Typography style={{color: 'darkgrey'}}>
                            {translate("email-manager.missing-template")}
                        </Typography>
                        <Button color={"secondary"} onClick={create}>{translate('global.create-button')}</Button>
                    </Flex>
            }

        </Flex>
        <ConfirmationDialog {...{
            dialogOpen: isDeleteDialogOpen,
            onDialogClose: toggleDeleteDialog,
            onConfirm: onDeleteEmailTemplate,
            confirmationTextKey: "email-manager.delete-template",
            title: translate("email-manager.delete-template-title"),
        }}/>
        <ConfirmationDialog {...{
            dialogOpen: isDeleteDialogOpen,
            onDialogClose: toggleDeleteDialog,
            onConfirm: onDeleteEmailTemplate,
            confirmationTextKey: "email-manager.delete-template",
            title: translate("email-manager.delete-template-title"),
        }}/>
        {
                isPreviewOpen &&
                <Preview togglePreview={togglePreview} translate={translate} fetchProps={{
                    emailTemplateEntityType,
                    entityId,
                    language,
                    emailTemplateType
                }}/>
        }
    </Flex>;
};

export default EmailTemplateManager;
