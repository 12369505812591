import HMOApi, {UserDetails} from "store/api/HMOApi";

const BASE_URL = "/email-template";
const EmailTemplatesTag = 'EmailTemplatesTag'

export const EmailTemplateApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        getEmailTemplate: build.query({
            query: ({
                        emailTemplateEntityType,
                        entityId,
                        language,
                        emailTemplateType
                    }) =>
                ({url: BASE_URL + `/${emailTemplateEntityType}/${entityId}/${language}/${emailTemplateType}`}),
            providesTags: [EmailTemplatesTag],
        }),
        createEmailTemplate: build.mutation({
            query: ({
                        entityId,
                        emailTemplateEntityType,
                        emailTemplateType,
                        content,
                        subject,
                        language
                    }) => ({
                url: BASE_URL,
                method: "PUT",
                data: {
                    entityId,
                    emailTemplateEntityType,
                    emailTemplateType,
                    content,
                    subject,
                    language
                },
            }),
            invalidatesTags: [EmailTemplatesTag],
        }),
        updateEmailTemplate: build.mutation({
            query: ({id, subject, content}) => ({
                url: BASE_URL,
                method: "POST",
                data: {id, subject, content},
            }),
            invalidatesTags: [EmailTemplatesTag],
        }),
        deleteEmailTemplate: build.mutation({
            query: (id) => ({
                url: BASE_URL+`/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [EmailTemplatesTag],
        }),
        generateEmailPreview: build.query({
            query: ({
                        emailTemplateEntityType,
                        entityId,
                        language,
                        emailTemplateType
                    }) =>
                ({url: BASE_URL + `/preview/${emailTemplateEntityType}/${entityId}/${language}/${emailTemplateType}`}),
            providesTags: [EmailTemplatesTag],
        }),
    }),
});

export const {
    useGetEmailTemplateQuery,
    useCreateEmailTemplateMutation,
    useUpdateEmailTemplateMutation,
    useDeleteEmailTemplateMutation,
    useGenerateEmailPreviewQuery
} = EmailTemplateApi;
