import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";

export const useEnqueueError = () => {
    const {t: translate} = useTranslation();
    return (label) => enqueueSnackbar(label || translate('global.data.saved.error'), {
        variant: "error",
        anchorOrigin: {'horizontal': 'center', vertical: 'bottom'},
        autoHideDuration: 6000
    });
}
export const useEnqueueSuccess = () => {
    const {t: translate} = useTranslation();
    return (label) => enqueueSnackbar(label || translate('global.data.saved.success'), {
        variant: "success",
        anchorOrigin: {'horizontal': 'center', vertical: 'bottom'},
        autoHideDuration: 1500
    });
}
