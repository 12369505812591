import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import Tag from 'components/tags/Tag';
import {Typography} from "@material-ui/core";
import moment from 'moment';

const Cell = (props) => {
    const {text, bold, transparent, item, color, style, center = true, ...other} = props;
    return (
        <Flex item={item || '1 0 5%'} container center={center} style={style || {width: '5%'}} {...other} >
            <Typography style={{
                color: color || '#030303',
                opacity: transparent ? 0.6 : 1,
                bold: bold ? 'bold' : 'unset',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
            }}>{text}</Typography>
        </Flex>
    );
};

export class ProgramTemplateRow extends Component {
    render() {
        const {program, selectProgram} = this.props;
        const {name, steps, shortName, date} = program;
        return (
            <Flex item grow={0} container role="listitem" style={{
                border: '1px solid #E2E1E4',
                borderTop: 'unset',
                height: 40
            }}
                  onClick={selectProgram(program)}>
                <Cell {...{text: name}}/>
                <Flex item={'1 0 5%'} container style={{width: '5%'}} center>
                    <Tag key={program} {...{
                        isEditDisabled: true,
                        tag: shortName || '',
                    }}/>
                </Flex>
                <Cell {...{text: moment(date).format('DD MMMM YYYY')}}/>
                <Cell {...{text: steps?.length}}/>
            </Flex>
        );
    }
}

export default ProgramTemplateRow;
