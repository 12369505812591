import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { isCaseManager } from "domain/User.model";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AddNewStepButton from "./AddNewStepButton";
import { useSelector } from "react-redux";
import { useBlueButtonWithIconStyles } from "components/button/BlueButtonWithIcon";
import { Link } from "react-router-dom";
import { CASE_EDIT } from "routes/routes";
import Step from "scenes/patient/program-view/Step";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import moment from "moment/min/moment-with-locales";
import "moment/locale/de";
import { ProgramRequestToUnsubscribeControlPanel } from "./ProgramRequestToUnsubscribeControlPanel";

const useStyles = makeStyles((theme) => ({
    listOfProgramItems: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        padding: 0,
        marginBottom: "10px",
        "& > li:not(:last-child)": {
            marginBottom: theme.spacing(2),
        },
    },
    mainTitle: ({ suspendedParticipation }) => ({
        textTransform: "capitalize",
        color: suspendedParticipation ? theme.palette.error.main : theme.palette.primary.main,
    }),
    patientUnsubscribedMessage: {
        color: theme.palette.error.main,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: "16px",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const StepsContext = (props) => {
    const {
        stepsContainerRef,
        program,
        toggleDeleteDialog,
        reorderSteps,
        setIsDragged,
        sortedSteps,
        isLoading,
        isNewStepLoading,
        isPatientProgramsFetching,
        deleteStep,
        originalProgram,
        saveStep,
        editableId,
        setEditableId,
        addStep,
        addStepButtonShouldBeShown,
        isDragged,
        alreadySeenSteps,
        collapsedSteps,
        toggleCollapse,
        markStepAsSeen,
        cancelProgramTermination,
        reopenProgram,
        readonlyMode,
        refreshPrograms
    } = props;
    const blueButtonClasses = useBlueButtonWithIconStyles();
    const classes = useStyles({
        suspendedParticipation: program?.suspendedParticipation,
    });
    const { t: translate, i18n } = useTranslation();
    const user = useSelector((state) => state.mainReducer.user);

    return (
        <Box padding="0px 20px" flexDirection={"column"}>
            {program?.requestToUnsubscribe && program.suspendedParticipation && (
                <Box className={classes.patientUnsubscribedMessage}>
                    <span>
                        {translate("program.terminated-program-steps-content.patient-requested-to-leave", {
                            date: moment(program.patientTerminationDate).locale(i18n.language).format("LL"),
                            time: moment(program.patientTerminationDate).locale(i18n.language).format("LT"),
                        })}
                        <InfoOutlinedIcon
                            color={"info"}
                            style={{ marginLeft: "10px", fontSize: "1.4em", verticalAlign: "sub" }}
                        />
                    </span>
                </Box>
            )}
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-top"}
                marginTop={1}
            >
                <Box ref={stepsContainerRef} display="flex" flexDirection={"column"}>
                    <Typography data-testid="patient-program-title" component="h1" variant="h2" className={classes.mainTitle}>
                        {program?.name || ""}
                    </Typography>
                    <Typography component="p" color="textSecondary">
                        {translate("global.program-steps")}
                    </Typography>
                </Box>

                <Box
                    display="flex"
                    dir="row"
                    alignItems="center"
                    alignContent={"flex-start"}
                    style={{ marginTop: 2, alignItems: "baseline" }}
                >
                    <ProgramRequestToUnsubscribeControlPanel
                        program={program}
                        onCancelProgramTermination={cancelProgramTermination}
                        onReopenProgram={reopenProgram}
                        onToggleDeleteDialog={toggleDeleteDialog}
                    />
                    {program.caseBusinessId && isCaseManager(user) && (
                        <Button
                            component={Link}
                            size={"small"}
                            to={CASE_EDIT.pathWithId(program.caseBusinessId)}
                            className={blueButtonClasses.button}
                            style={{ marginLeft: 10, alignSelf: "flex-start", fontSize: "13px" }} // small and font-size 13 to be consistent with other buttons in the panel
                        >
                            {translate("global.go-to-case")}
                        </Button>
                    )}
                </Box>
            </Box>

            <div ref={stepsContainerRef}>
                <DragDropContext
                    on
                    onDragStart={() => {
                        setIsDragged(true);
                    }}
                    onDragEnd={(result) => {
                        reorderSteps(result);
                        setIsDragged(false);
                    }}
                >
                    <Droppable droppableId={"sortableActions"}>
                        {(provided) => (
                            <ul
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={classes.listOfProgramItems}
                            >
                                {sortedSteps.map((step, index) => {
                                    return (
                                        <Step
                                            key={step.id + "-" + index}
                                            {...{
                                                isLoading: isLoading || isNewStepLoading || isPatientProgramsFetching,
                                                isDragDisabled: readonlyMode,
                                                step,
                                                index,
                                                user,
                                                classes,
                                                deleteStep,
                                                program,
                                                originalProgram,
                                                saveStep,
                                                editableId,
                                                setEditableId,
                                                addStep,
                                                addStepButtonShouldBeShown,
                                                dragged: isDragged,
                                                position: index + 1,
                                                isNew: !alreadySeenSteps.includes(step.id),
                                                isStepCollapsed: collapsedSteps.includes(step.id),
                                                toggleCollapse,
                                                markStepAsSeen,
                                                otherStepsInProgram: sortedSteps
                                                    .filter((s) => s.id !== step.id)
                                                    .map((_step, index) => ({
                                                        ..._step,
                                                        position: index + 1,
                                                    })),
                                                refreshPrograms
                                            }}
                                        />
                                    );
                                })}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            {addStepButtonShouldBeShown && sortedSteps.length === 0 && <AddNewStepButton onClick={() => addStep()} />}
        </Box>
    );
};

export default StepsContext;
